export class MsGraphRequest implements MsGraphRequest {
    constructor(url: string) {
        this.url = url;
    }
}

export interface MsGraphRequest {
    url: string;
}


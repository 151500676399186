import { GridDataStateChangeEvent, GridRowDoubleClickEvent } from "@progress/kendo-react-grid";
import { DataResult, State } from "@progress/kendo-data-query";

export interface GridProps {
    lessons: DataResult | undefined;
    display(dataItem: any): void;
    edit(dataItem: any): void;
    delete(dataItemId: any): void;
    dataStateChange(e: GridDataStateChangeEvent): void;
    rowDoubleClick(e: GridRowDoubleClickEvent): void;
    dataState: State;
    isUncon: boolean;
}

export const KendoGridDefaultState: State = {
    take: 10,
    skip: 0,
};

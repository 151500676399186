import { GridSelectableSettings } from "@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings";
import { GridPagerSettings } from "@progress/kendo-react-grid";

export const pagerSettings: GridPagerSettings = {
    // buttonCount: 10,
    info: true,
    type: "numeric",
    pageSizes: [5, 10, 20, 40, 80, 100],
    previousNext: true,
};
export const selectableSettings: GridSelectableSettings = {
    enabled: true,
    mode: "single",
};

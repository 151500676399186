import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Grid, GridCellProps, GridColumn as Column, GridColumnMenuFilter, GridColumnMenuProps } from "@progress/kendo-react-grid";

import { IconButton } from "@mui/material";

import { MinimizeIcon } from "@exxonmobil/react-unity";

import { MyGrid2ButtonCell } from "./MyGrid2ButtonCell";
import { pagerSettings, selectableSettings } from "./KendoGridSettings";
import { columnInterface } from "../interfaces/ColumnInterface";
import { GridProps } from "./GridProps";

export default observer(function KendoGridAllMyDetailedLessons(props: GridProps) {
    const navigate = useNavigate();

    const mountedRef = React.useRef(true);
    const minGridWidth = React.useRef<number>(0);
    const grid = React.useRef<any>(null);
    //const [applyMinWidth, setApplyMinWidth] = React.useState(false);
    const [applyMinWidth, setApplyMinWidth] = React.useState(true);
    const [gridCurrent, setGridCurrent] = React.useState(0);

    const ColumnMenu = (props: GridColumnMenuProps) => {
        return (
            <div>
                <GridColumnMenuFilter {...props} expanded={true} />
            </div>
        );
    };

    const buttonColumn = (cellprops: GridCellProps) => (
        <MyGrid2ButtonCell {...cellprops} display={props.display} edit={props.edit} remove={props.delete} />
    );

    const buttonColumnHeader = () => (
        <IconButton
            sx={{
                float: "right",
                margin: 0,
                position: "absolute",
                top: "50%",
                msTransform: "translateY(-50%)",
                transform: "translateY(-50%)",
            }}
            onClick={selectHome}>
            <MinimizeIcon size="small" />
        </IconButton>
    );
    const selectHome = () => {
        navigate("/");
    };

    const setWidth = (minWidth: number | undefined) => {
        if (minWidth === undefined) {
            minWidth = 0;
        }

        let width = applyMinWidth ? minWidth : minWidth + (gridCurrent - minGridWidth.current) / columns.length;
        if (width >= COLUMN_MIN) {
            width -= ADJUST_PADDING;
        }

        return width;
    };

    const handleResize = useCallback(async () => {
        if (!mountedRef.current) return;

        if (grid.current.offsetWidth < minGridWidth && !applyMinWidth) {
            setApplyMinWidth(true);
        } else if (grid.current.offsetWidth > minGridWidth) {
            setGridCurrent(grid.current.offsetWidth);
            //setApplyMinWidth(false);
            setApplyMinWidth(true);
        }
    }, [applyMinWidth]);

    React.useEffect(() => {
        mountedRef.current = true;

        grid.current = document.querySelector(".k-grid");
        window.addEventListener("resize", handleResize);
        columns.map((item: columnInterface) =>
            item.minWidth !== undefined ? (minGridWidth.current += item.minWidth) : minGridWidth.current
        );
        setGridCurrent(grid.current.offsetWidth);
        //setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
        setApplyMinWidth(true);
        return () => {
            mountedRef.current = false;
        };
    }, [handleResize]);

    const cellRender = (cell: React.ReactElement<HTMLTableCellElement> | null, props: GridCellProps) => {
        //Add tooltip
        if (props.field && props.dataItem[props.field]) {  //doesn't work with Rig.Rigno, Well.Wellname, etc... 
            return (
                <td title={props.dataItem[props.field].toString()} >
                    {props.dataItem[props.field].toString()}
                </td>
            );
        }
        return cell;
    };

    return (
        <div>
            <Grid
                style={{ height: "calc(100vh - 65px)" }} //, width: "calc(100vw - 20vw)"
                sortable={true}
                resizable={true}
                pageable={pagerSettings}
                selectable={selectableSettings}
                {...props.dataState}
                data={props.lessons}
                onDataStateChange={props.dataStateChange}
                onRowDoubleClick={props.rowDoubleClick}
                cellRender={cellRender}>                
                {
                    props.isUncon && (
                        <Column
                            filter={"text"}
                            field={"Basin"}
                            title={"Basin"}
                            key={0}
                            width={setWidth(150)}
                            columnMenu={ColumnMenu}
                        />                        
                    )
                }
                {
                    props.isUncon && (
                        <Column
                            filter={"text"}
                            field={"Area.Name"}
                            title={"Area"}
                            key={1}
                            width={setWidth(150)}
                            columnMenu={ColumnMenu}
                        />                        
                    )
                }
                {columns.map((column, index) => {
                    return (
                        <Column
                            filter={column.filter ? column.filter : "text"}
                            field={column.field}
                            title={column.title}
                            key={index+2}
                            width={setWidth(column.minWidth)}
                            columnMenu={ColumnMenu}
                        />
                    );
                })}
                <Column cell={buttonColumn} headerCell={buttonColumnHeader} sortable={false} width={80} />
            </Grid>
        </div>
    );
});

const columns: Array<columnInterface> = [
    { field: "Company.Name", title: "Company", minWidth: 150 },
    { field: "Rig.Contractor", title: "Rig Contractor", minWidth: 160 },
    { field: "Rig.Rigno", title: "Rig", minWidth: 120 },
    { field: "Well.Wellname", title: "Well", minWidth: 150 },    
    { field: "Status", title: "Status", minWidth: 190 },
    { field: "Observations", title: "Observations", minWidth: 400 },
    { field: "Actions", title: "Actions", minWidth: 400 },
    { field: "Closure", title: "Closure Comments", minWidth: 400 },
    { field: "CreatedDate", title: "Created", minWidth: 120, filter: "date" },
    { field: "UpdatedDate", title: "Updated", minWidth: 120, filter: "date" },
    { field: "Phase.Code1", title: "Phase", minWidth: 120 },
    { field: "Phase.Code2", title: "Sub-Phase", minWidth: 140 },
    { field: "Category.CategoryType", title: "Category", minWidth: 150 },
    { field: "ResourceDisplayName", title: "Assigned Resource", minWidth: 260 },
    { field: "OwnersDisplay", title: "Owner(s)", minWidth: 260 },
];
const ADJUST_PADDING: number = 2;
const COLUMN_MIN: number = 4;

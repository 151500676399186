//import * as React from "react";
import { EditIcon, TrashcanIcon } from "@exxonmobil/react-unity";
import { Button } from "@progress/kendo-react-buttons";

export const MyGrid2ButtonCell = (props: any) => {
    const { dataItem } = props;

    const remove = (): void => {
        if (dataItem.Status === "Closed") {
            window.alert("Closed lessons cannot be deleted");
        } else if (window.confirm("Confirm delete")) {
            props.remove(dataItem.Id);
        }
    };

    return (
        <td className="k-command-cell">
            <Button look="clear" onClick={() => props.edit(dataItem)}>
                <EditIcon size="small" />
            </Button>
            <Button look="clear" onClick={() => remove()}>
                <TrashcanIcon size="small" />
            </Button>
        </td>
    );
};

export interface ServerError {
    statusCode: number;
    message: string;
    details: string | undefined;
}

export class ServerError implements ServerError
{
    statusCode!: number;
    message: string = "";
    details: string | undefined;
}
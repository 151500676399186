import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CircularProgress, Grid, Paper, Container, Button } from "@mui/material";
import {  useMsal } from "@azure/msal-react";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";

import { toastDefaults } from "../../../app/models/toastDefaults";
import { useStore } from "../../../app/stores/store";
import { LessonFormValues } from "../../../app/models/lesson";
import apiAgent from "../../../app/api/apiAgent";
import MyTextInput from "../../../app/common/form/MyTextInput";
import ReassignResourceModal from "./ReassignResourceModal";

const StyledButton = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: "20px",
    marginTop: "20px",
    "&.MuiButtonBase-root": {
        backgroundColor: "#1976d2",
    },
    "&.MuiButtonBase-root:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
}));

const StyledProgressDiv = styled("div")(() => ({
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}));

const StyledLabel = styled("label")(() => ({
    fontWeight: 1000,
    paddingTop: "10px",
    paddingBottom: "10px",
}));

export default observer(function ReviewLessonForm() {
    const navigate = useNavigate();
    const { id, type } = useParams();
    const { lessonStore, commonStore, groupStore } = useStore();
    const { loadLesson, saveUpdate, submitForReview, reassignResource } = lessonStore;
    const { instance } = useMsal();
    const [isValid, setIsValid] = useState("");

    const [lesson, setLesson] = useState<LessonFormValues>(new LessonFormValues());
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);

    const loadLessonRecord = async () => {
        setLoading(true);
        if (id) {

            let IsUncon = false;            
            if (type === "Conv"|| type === "Conv -OBO") {
                IsUncon = false;
            } else if (type === "Uncon" || type === "Uncon -OBO") {
                IsUncon = true;
            } else {
                IsUncon = groupStore.isUnconUser;
            }
            
            try{
                const response = await loadLesson(id, IsUncon, groupStore.isB2BUser);
                let lessonRecord = new LessonFormValues(await response);
                setLesson(lessonRecord);
                checkValidity(lessonRecord);
                setLoading(false);
            }
            catch(error){
                commonStore.setValidationErrors(error);
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        loadLessonRecord();
        return () => {
            apiAgent.Cancel.cancelRequest();
        };
    }, [id, loadLesson, commonStore, groupStore.isUnconUser, type, groupStore.isB2BUser]);

    function checkValidity(lesson: LessonFormValues){
        if(lesson.status === "WorkInProgress" && lesson.resource?.mail === instance.getActiveAccount()?.username){
            setIsValid("true")
        }
        else{
            setIsValid("false")
        }
    }

    function handleFormSubmit(lesson: LessonFormValues, operation: string) {
        if (operation === "save") {
            save(lesson);
        } else if (operation === "submit") {
            submittedForReview(lesson);
        } else if (operation === "reassign") {
            setOpenDialog(true);
        }
    }

    const save = (lesson: LessonFormValues) => {
        saveUpdate(lesson)
            .then((response) => {
                if (response) {
                    let len = Object.keys(response).length;
                    if (len === 0) {
                        toast.success("Lesson successfully saved", toastDefaults);
                    } else {
                        toast.error(`Lesson save failed. Error = ${response}`, toastDefaults);
                    }
                } else {
                    toast.error("Lesson save failed", toastDefaults);
                }
            })
            .catch((errors: any) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
    };

    const submittedForReview = (lesson: LessonFormValues) => {
        submitForReview(lesson)
            .then((response) => {
                if (response) {
                    let len = Object.keys(response).length;
                    if (len === 0) {
                        toast.success("Lesson successfully Submitted For Review", toastDefaults);
                        navigate("/");
                    } else {
                        toast.error(`Lesson Submitted For Review failed. Error = ${response}`, toastDefaults);
                    }
                } else {
                    toast.error("Lesson Submitted For Review failed", toastDefaults);
                }
            })
            .catch((errors: any) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
    };

    const reassign = (lesson?: LessonFormValues) => {
        setOpenDialog(false);

        if (lesson) {
            reassignResource(lesson)
                .then((response) => {
                    if (response) {
                        let len = Object.keys(response).length;
                        if (len === 0) {
                            toast.success("Lesson successfully reassigned", toastDefaults);
                            navigate("/");
                        } else {
                            toast.error(`Lesson reassignment failed. Error = ${response}`, toastDefaults);
                        }
                    } else {
                        toast.error("Lesson reassignment failed", toastDefaults);
                    }
                })
                .catch((errors: any) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
        }
    };

    const validationSchema = Yup.object({
        updates: Yup.string().required("Lesson updates are required"),
    });

    if (loading) {
        return (
            <StyledProgressDiv>
                <CircularProgress />
            </StyledProgressDiv>
        );
    } 
    else {
        if(isValid === "true"){
            return (
                <>
                    <ReassignResourceModal open={openDialog} lesson={lesson} onClose={reassign} />
                    <Formik
                        validationSchema={validationSchema}
                        enableReinitialize
                        initialValues={lesson}
                        onSubmit={(values) => handleFormSubmit(values, "submit")}>
                        {({ handleSubmit, values, isValid, isSubmitting, dirty }) => (
                            <Form onSubmit={handleSubmit} autoComplete="off">
                                <Container maxWidth="lg">
                                    <Paper sx={{ padding: "50px", margin: "40px" }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <StyledLabel>Is this lesson linked to a Well?</StyledLabel>
                                                <div>{lesson.isLessonLinkedToWell}</div>
                                            </Grid>
                                         { lesson.isLessonLinkedToWell === "yes" && (
                                            <Grid item xs={12}>
                                                <StyledLabel>Well</StyledLabel>
                                                <div>{lesson.well?.Name}</div>
                                            </Grid>
                                         )}
                                            {groupStore.isUnconUser && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <StyledLabel>Basin</StyledLabel>
                                                        <div>{lesson.basin}</div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <StyledLabel>Area</StyledLabel>
                                                        <div>{lesson.area?.Name}</div>
                                                    </Grid>                                           
                                                </>
                                            )}
                                             <Grid item xs={12}>
                                                <StyledLabel>Stewarding Team</StyledLabel>
                                                <div>{lesson.stewardingTeam?.Name}</div>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                <StyledLabel>Company</StyledLabel>
                                                <div>{lesson.company?.Name}</div>
                                            </Grid> 
                                            { lesson.isLessonLinkedToWell === "yes" && ( 
                                                <Grid item xs={12}>
                                                    <StyledLabel>Phase</StyledLabel>
                                                    <div>{lesson.phase?.Name}</div>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <StyledLabel>Category</StyledLabel>
                                                <div>{lesson.category?.Name}</div>
                                            </Grid>
                                            {
                                             !groupStore.isUnconUser && (
                                                <Grid item xs={12}>
                                                    <StyledLabel>Sub-Category</StyledLabel>
                                                    <div>{lesson.subCategory?.Name}</div>
                                                </Grid>
                                            )}
                                            {
                                                lesson.isOBO && (
                                                <>
                                                <Grid item xs={12}>
                                                    <StyledLabel>WJI Contact</StyledLabel>
                                                    <div>{lesson.wjiContact?.displayName}</div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <StyledLabel>Asset</StyledLabel>
                                                    <div>{lesson.asset?.Name}</div>
                                                </Grid>
                                            </>
                                                )
                                           }
                                            <Grid item xs={12}>
                                                <StyledLabel>Owner(s)</StyledLabel>
                                                <div>{lesson.ownersDisplay}</div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StyledLabel>Assigned Resource</StyledLabel>
                                                <div>{lesson.resource?.displayName}</div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StyledLabel>Observations</StyledLabel>
                                                <div>{lesson.observations}</div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StyledLabel>Recommended Actions</StyledLabel>
                                                <div>{lesson.actions}</div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StyledLabel>Attachment</StyledLabel>
                                                { lesson.attachments!== null && lesson.attachments!== undefined && lesson.attachments.map((attachment,i) =>{
                                                if(attachment.attachmentScanPassed === true && attachment.attachmentUri !== null) {
                                                        return (
                                                    <>
                                                    <a href={attachment.attachmentUri}  className="em-c-text-link ">{attachment.attachmentFileName}</a>
                                                    <br/>
                                                    </>
                                                    )
                                                }
                                                if(attachment.attachmentScanPassed === false && attachment.attachmentFileName !== null){
                                                    return (                                                    
                                                        <div className="em-c-alert em-c-alert--warning" role="alert">                                                   
                                                            <div className="em-c-alert__body">
                                                                File ({attachment.attachmentFileName }) upload failed. Malicious file detected.                                                        
                                                            </div>                                                  
                                                        </div>
                                                    )
                                                }
                                            })}
                                            </Grid>
                                            <Grid item xs={12}>
                                            <Grid container sx={{ paddingTop: "10px" }}>
                                                <div className="row">                                            
                                                    <label style={{ paddingRight: "15px" }}>Send Lesson to WellView</label>
                                                    <div role="group" className="row">
                                                        <label style={{ paddingRight: "10px" }}>
                                                        {(() => {     
                                                            if(lesson.isB2B !== true && lesson.sendLessonToWellView === true && lesson.status?.toLowerCase() === "closed"){
                                                                return (
                                                                        <Field type="checkbox" name="sendLessonToWellView" checked="checked" disabled />
                                                                    )                   
                                                            }
                                                            else if(lesson.isB2B !== true && lesson.sendLessonToWellView === true && lesson.status?.toLowerCase() !== "closed")    {
                                                                return (
                                                                        <Field type="checkbox" name="sendLessonToWellView" disabled={false} />
                                                                    ) 
                                                            }
                                                            else if(lesson.isB2B !== true && lesson.sendLessonToWellView !== true && lesson.status?.toLowerCase() !== "closed")    {
                                                                return (
                                                                        <Field type="checkbox" name="sendLessonToWellView" disabled={false} />
                                                                    ) 
                                                            }
                                                        })()}
                                                        </label>
                                                    </div>
                                                </div>                                    
                                            </Grid>
                                    </Grid>
                                            {lesson.rejection && (
                                                <Grid item xs={12}>
                                                    <StyledLabel>Rejection Comments</StyledLabel>
                                                    <div>{lesson.rejection}</div>
                                                </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <MyTextInput
                                                    placeholder={"Enter your updates"}
                                                    name="updates"
                                                    label="Lesson Updates"
                                                    component={"textarea"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sx={{}}>
                                                <div className="rowRight">
                                                    <StyledButton
                                                        variant="contained"
                                                        onClick={() => handleFormSubmit(values, "reassign")}>
                                                        Reassign Resource
                                                    </StyledButton>
                                                    <StyledButton
                                                        variant="contained"
                                                        type="submit"
                                                        disabled={isSubmitting || !dirty || !isValid}>
                                                        Submit for Closure
                                                    </StyledButton>
                                                    <StyledButton
                                                        variant="contained"
                                                        disabled={isSubmitting || !dirty || !isValid}
                                                        onClick={() => handleFormSubmit(values, "save")}>
                                                        Save
                                                    </StyledButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Container>
                            </Form>
                        )}
                    </Formik>
                </>
            )
        }
        else if(isValid === "false"){
            return(
                <Navigate to={`/lesson/${id}/${type}`}></Navigate>
            );
        }
        else{
            return(
                <div>App is stil loading ...</div>
            );
        }
    }
});

// eslint-disable-next-line
/* <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Job" />
   </Grid> */
// eslint-disable-next-line
/* <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Rig" />
   </Grid> */
// eslint-disable-next-line
/* <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Type" />
   </Grid>
   <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Category" />
   </Grid>
   <Grid container item xs={16}>
       <TextField disabled fullWidth  label="SubCategory" />
   </Grid> */
// eslint-disable-next-line
/* <TextField disabled fullWidth  label="Approver"  /> */
// eslint-disable-next-line
/* <TextField disabled fullWidth  label="Owner"  /> */
// eslint-disable-next-line
/* <Grid container item xs={16}>
    <TextField disabled fullWidth  label="Title" />
  </Grid> */

import * as React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastDefaults } from "../../models/toastDefaults";
import { debounce } from "lodash";

import { GridDataStateChangeEvent, GridRowDoubleClickEvent } from "@progress/kendo-react-grid";
import { State } from "@progress/kendo-data-query";

import { CircularProgress } from "@material-ui/core";
import { styled } from "@mui/material/styles";

import { useStore } from "../../stores/store";
import { LoadLessonProps } from "../../stores/lessonStore";
import KendoGridAll from "./KendoGridAll";
import KendoGridAllMyDetailedLessons from "./KendoGridAllMyDetailedLessons";
import KendoGridInbox from "./KendoGridInbox";
import { msalInstance } from "../../..";
import { KendoGridDefaultState } from "./GridProps";
import measuresAgent from "../../api/measuresAgent";

const StyledProgressDiv = styled("div")(() => ({
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}));

interface Props {
    loadInbox?: () => void;
    refresh?: (reset: boolean) => void;
    lessonFilter?: string;
    isAllMyDetailedLessons: boolean;
    isAll: boolean;
}

export default observer(function KendoGrid(tempProps: Props) {
    const navigate = useNavigate();

    const { lessonStore, groupStore } = useStore();
    const { setForceReloadLessons } = lessonStore; //updateLesson

    const savedProps = React.useRef<Props>(tempProps);

    const gridDataState = React.useRef<State>(KendoGridDefaultState);

    const resetDataState = React.useCallback((): State => {
        gridDataState.current = KendoGridDefaultState;
        return gridDataState.current;
    }, []);

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        gridDataState.current = e.dataState;
        myDebounce();
    };

    const myRequestStarted = React.useRef(false);
    const requestEnded = () => {
        myRequestStarted.current = false;
    };
    const requestStarted = () => {
        myRequestStarted.current = true;
    };

    const fetchMyAPI = React.useCallback(
        async (props: Props) => {
            if (myRequestStarted.current) {
                return;
            }
            requestStarted();
            let lessonprops: LoadLessonProps;

            const account = msalInstance.getActiveAccount();

            if (props.isAll) {
                lessonprops = {
                    dataState: gridDataState.current,
                    myLessons: false,
                    allMyDetailedLessons: false,
                    userB2BGroups: groupStore.userB2BGroups,
                    resetDataState: resetDataState,
                    requestEnded: requestEnded,
                };
                setForceReloadLessons(true);
                lessonStore.loadCombinedLessons(lessonprops);

                let body = [
                    {
                        ...measuresAgent.MeasuresEvent,
                        UserId: account?.username,
                        StartTime: new Date().toISOString(),
                        Feature: "Global Lessons",
                        BaseUrl: "AllLessons",
                    },
                ];

                measuresAgent.Send.post(body);

                return;
            }

            if (props.isAllMyDetailedLessons) {
                lessonprops = {
                    dataState: gridDataState.current,
                    lessonFilter: props.lessonFilter,
                    isUnconUser: groupStore.isUnconUser,
                    userB2BGroups: groupStore.userB2BGroups,
                    myLessons: false,
                    allMyDetailedLessons: true,
                    longUserId: account?.homeAccountId,
                    shortUserId: account?.localAccountId,
                    resetDataState: resetDataState,
                    requestEnded: requestEnded,
                };
            } else {
                lessonprops = {
                    dataState: gridDataState.current,
                    lessonFilter: props.lessonFilter,
                    isUnconUser: groupStore.isUnconUser,
                    userB2BGroups: groupStore.userB2BGroups,
                    myLessons: true,
                    allMyDetailedLessons: false,
                    longUserId: account?.homeAccountId,
                    shortUserId: account?.localAccountId,
                    resetDataState: resetDataState,
                    requestEnded: requestEnded,
                };
            }
            setForceReloadLessons(true);
            lessonStore.loadLessons(lessonprops);

            let body = [
                {
                    ...measuresAgent.MeasuresEvent,
                    UserId: account?.username,
                    StartTime: new Date().toISOString(),
                    Feature: "Inbox Lessons",
                    BaseUrl: "MyLessons",
                },
            ];

            measuresAgent.Send.post(body);
        },
        [groupStore.isUnconUser, groupStore.userB2BGroups, lessonStore, resetDataState, setForceReloadLessons]
    );

    React.useEffect(() => {
        if (groupStore.gettingUnconUserGroups) {
            return;
        }
        savedProps.current = tempProps;
        if (tempProps.loadInbox) {
            tempProps.loadInbox();
        }
        myDebounce();

        // return () => { //this causes 2 request when searching
        //     odataAgent.Cancel.cancelRequest();
        // };

    // eslint-disable-next-line
}, [tempProps.lessonFilter, lessonStore.globalFilter, groupStore.gettingUnconUserGroups]);

const TIMEOUT: number = 1000;
// eslint-disable-next-line
const myDebounce = debounce(() => {
    fetchMyAPI(savedProps.current);
}, TIMEOUT);
// , { 'maxWait': 1000 }

// modify the data in the store, db etc
    const remove = (dataItemId: any) => {
        lessonStore.deleteLesson(dataItemId, groupStore.isUnconUser).then((response) => {
            if (response) {
                let len = Object.keys(response).length;
                if (len === 0) {
                    toast.success("Lesson successfully deleted", toastDefaults);
                } else {
                    toast.error(`Lesson delete failed. Error = ${response}`, toastDefaults);
                }
            } else {
                toast.error("Lesson delete failed", toastDefaults);
            }
            myDebounce();
            if (tempProps.refresh) tempProps.refresh(true);
        });
    };
    const enterEdit = (dataItem: any) => {
        const account = msalInstance.getActiveAccount();

        if (dataItem.Status === "WorkInProgress" && dataItem.ResourceUserId === account?.localAccountId) {
            navigate(`/reviewLesson/${dataItem.Id}/${dataItem.Type}`);
        } else if (
            dataItem.Status === "SubmittedForReview" &&
            dataItem.Owners.find((x: any) => x.UserId === account?.localAccountId)
        ) {
            navigate(`/closeLesson/${dataItem.Id}/${dataItem.Type}`);
        } else if (dataItem.Status === "Closed") {
            enterdisplay(dataItem);
        } else {
            enterdisplay(dataItem);
        }
    };
    const enterdisplay = (dataItem: any) => {
        navigate(`/lesson/${dataItem.Id}/${dataItem.Type}`);
    };

    const rowDoubleClickEdit = (e: GridRowDoubleClickEvent) => {
        enterEdit(e.dataItem);
    };

    const rowDoubleClickOpen = (e: GridRowDoubleClickEvent) => {
        enterdisplay(e.dataItem);
    };

    if (savedProps.current.isAllMyDetailedLessons) {
        return !lessonStore.lessons ? (
            <StyledProgressDiv>
                <CircularProgress />
            </StyledProgressDiv>
        ) : (
            <KendoGridAllMyDetailedLessons
                lessons={lessonStore.lessons}
                display={enterdisplay}
                edit={enterEdit}
                delete={remove}
                dataState={gridDataState.current}
                dataStateChange={dataStateChange}
                rowDoubleClick={rowDoubleClickEdit}
                isUncon={groupStore.isUnconUser}
            />
        );
    } else if (savedProps.current.isAll) {
        return !lessonStore.allLessons ? (
            <StyledProgressDiv>
                <CircularProgress />
            </StyledProgressDiv>
        ) : (
            <KendoGridAll
                lessons={lessonStore.allLessons}
                display={enterdisplay}
                edit={enterEdit}
                delete={remove}
                dataState={gridDataState.current}
                dataStateChange={dataStateChange}
                rowDoubleClick={rowDoubleClickOpen}
                isUncon={groupStore.isUnconUser}
            />
        );
    } else {
        return !lessonStore.lessons ? (
            <StyledProgressDiv>
                <CircularProgress />
            </StyledProgressDiv>
        ) : (
            <KendoGridInbox
                lessons={lessonStore.lessons}
                display={enterdisplay}
                edit={enterEdit}
                delete={remove}
                dataState={gridDataState.current}
                dataStateChange={dataStateChange}
                rowDoubleClick={rowDoubleClickEdit}
                isUncon={groupStore.isUnconUser}
            />
        );
    }
});

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link as RouterLink, useLocation, useMatch, useNavigate } from "react-router-dom";
import { breadcrumbNameMap } from "./breadcrumbNameMap";
import { Page } from "@exxonmobil/react-unity"; //ThemeProvider
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link, { LinkProps } from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Box } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";

import { useStore } from "../stores/store";
import { ExcelExport, ExcelExportColumnProps } from "@progress/kendo-react-excel-export";
import HelpDropdown from "./HelpDropdown";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: 10,
    marginLeft: 0,
    width: "auto",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1, 0, 0.5),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(0.5, 0, 0.5, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(2)})`,
        width: "100%",
    },
}));

const StyledButton = styled(Button)(() => ({
    height: 34.35,
    marginTop: 3,
    marginBottom: 3,
    textTransform: "none",
    "&.MuiButtonBase-root": {
        backgroundColor: "white",
        color: "#3190d9",
    },
    "&.MuiButtonBase-root:disabled": {
        backgroundColor: "white",
        color: "#3190d9",
    },
}));
interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} />;

export default observer(function Layout({ children }: React.PropsWithChildren<{}>) {
    const muiTheme = useTheme();
    const { lessonStore, groupStore } = useStore();
    const { setGlobalFilter } = lessonStore; //updateLesson
    const [myGlobalFilter, setMyGlobalFilter] = React.useState("");

    useEffect(() => {
        setMyGlobalFilter(lessonStore.globalFilter);
    }, [lessonStore.globalFilter]);

    const mySearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setGlobalFilter(event.currentTarget.value);
    };

    const clearSearch = () => {
        setGlobalFilter("");
    };

    const myRequestStarted = React.useRef(false);
    const requestEnded = () => {
        myRequestStarted.current = false;
    };

    let lessonpropsExport = {
        userB2BGroups: groupStore.userB2BGroups,
        requestEnded: requestEnded,
    };

    const _exporter = React.createRef<ExcelExport>();
    const exportToExcel = () => {
        if (_exporter.current) {
            _exporter.current.save(exportData, columns);
        }
    };
    const exportData = lessonStore.exportCombinedLessons(lessonpropsExport);
    var columns: Array<ExcelExportColumnProps> = [];
    columns.push({ field: "Type", title: "Type" });
    columns.push({ field: "Country", title: "Country" });
    if(groupStore.isUnconUser){
        columns.push({ field: "Basin", title: "Basin" });
        columns.push({ field: "Areaname", title: "Area" });
    }
    columns.push({ field: "Company", title: "Company" });
    columns.push({ field: "Contractor", title: "Rig Contractor" });
    columns.push({ field: "Rigno", title: "Rig" });
    columns.push({ field: "Wellname", title: "Well" });
    columns.push({ field: "Status", title: "Status" });
    columns.push({ field: "Observations", title: "Observations" });
    columns.push({ field: "Actions", title: "Actions" });
    columns.push({ field: "Closure", title: "Closure Comments" });
    columns.push({ field: "CreatedDate", title: "Created" });
    columns.push({ field: "UpdatedDate", title: "Updated" });
    columns.push({ field: "PhaseCode1", title: "Phase" });
    columns.push({ field: "PhaseCode2", title: "Sub-Phase" });
    columns.push({ field: "Category", title: "Category" });
    columns.push({ field: "ResourceDisplayName", title: "Assigned Resource" });
    columns.push({ field: "Owners", title: "Owner(s)" });
    columns.push({ field: "FullUserName", title: "Submitted By" });
   
    const [lastLocation, setLastLocation] = React.useState<string>("");
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    const about = useMatch("/about");
    const viewLesson = useMatch("/lesson/:id");
    const editLesson = useMatch("/editLesson/:id");
    const reviewLesson = useMatch("/reviewLesson/:id");
    const closeLesson = useMatch("/closeLesson/:id");
    const createLesson = useMatch("/createLesson");
    const isAllLessons = useMatch("/AllLessons");
    let searchDisabled = false;
    if (about || viewLesson || editLesson || createLesson || reviewLesson || closeLesson) {
        searchDisabled = true;
        if (lessonStore.globalFilter !== "") {
            setGlobalFilter("");
        }
    } else {
        const last = pathnames.length - 1;
        const to = `/${pathnames.slice(0, last + 1).join("/")}`;
        if (lastLocation !== to) {
            if (lessonStore.globalFilter !== "") {
                setGlobalFilter("");
            }
            setLastLocation(to);
        }
    }

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        navigate("/about");
        setAnchorEl(null);
    };
    // const ProfileMenuClose = () => {
    //     navigate("/profile");
    //     setAnchorEl(null);
    // };
    // const AboutMenuClose = () => {
    //     navigate("/about");
    //     setAnchorEl(null);
    // };
    // const AdminMenuClose = () => {
    //     navigate("/admin");
    //     setAnchorEl(null);
    // };

    const gotoHome = () => {
        navigate("/");
    };

    const isMenuOpen = Boolean(anchorEl);
    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={isMenuOpen}
            onClick={handleMenuClose}
            // onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={ProfileMenuClose}>Profile</MenuItem> */}
            {/* <Divider /> */}
            {/* <MenuItem onClick={AdminMenuClose}>Load WellView Picklists</MenuItem> */}
            {/* <Divider /> */}
            <MenuItem onClick={handleMenuClose}>About</MenuItem>
        </Menu>
    );

    return (
        <MuiThemeProvider theme={muiTheme}>
            <Page>
                <Page.Header>
                    <AppBar
                        position="static"
                        sx={{
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 1,
                            backgroundImage: `linear-gradient(45deg, #3a397b 0%, #3190d9 100%)`,
                        }}>
                        <Toolbar disableGutters variant="dense">
                            <svg
                                onClick={gotoHome}
                                version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                width="110px"
                                // height="30px"
                                viewBox="0 0 110 30"
                                enableBackground="new 0 0 110 30"
                                xmlSpace="preserve">
                                <path
                                    fill="currentColor"
                                    d="M44.7,9.5h2.6v0.9c0.3-0.2,1.2-1.1,2.9-1.1c2,0,3.6,1.5,3.6,3.8v6H51v-5.2c0-1.3-0.6-2-1.8-2
	c-1.1,0-1.8,0.7-1.8,2V19h-2.8V9.5z M73.9,14.2c0-1.3,1-2.4,2.4-2.4c1.4,0,2.4,1.1,2.4,2.4c0,1.3-1,2.4-2.4,2.4
	C74.9,16.6,73.9,15.6,73.9,14.2z M71.2,14.2c0,2.7,2.2,5,5.1,5c2.9,0,5.1-2.2,5.1-5c0-2.7-2.2-5-5.1-5C73.4,9.3,71.2,11.5,71.2,14.2
	z M35.7,14.2c0-1.3,1-2.4,2.4-2.4c1.4,0,2.4,1.1,2.4,2.4c0,1.3-1,2.4-2.4,2.4C36.7,16.6,35.7,15.6,35.7,14.2z M32.9,14.2
	c0,2.7,2.2,5,5.1,5c2.9,0,5.1-2.2,5.1-5c0-2.7-2.2-5-5.1-5C35.1,9.3,32.9,11.5,32.9,14.2z M94.9,9.5h2.8v8.9V19h-2.8V9.5z M94.9,6
	h2.8v2.4h-2.8V6z M99.9,6h2.8v12.4V19h-2.8V6z M90.4,14.2c0,1.3-1,2.4-2.4,2.4c-1.4,0-2.4-1-2.4-2.4c0-1.4,1-2.4,2.4-2.4
	C89.4,11.9,90.4,12.9,90.4,14.2z M85.7,19l0-0.7c0.2,0.1,1.1,1,2.9,1c2.4,0,4.6-2.2,4.6-5c0-2.8-2.1-5-4.6-5c-1.9,0-2.8,0.9-2.9,1V6
	H83v13H85.7z M19.8,19l2.9-3.8l1.8,1.9l-4.2,5.2h3.3l2.7-3.5l5,5.1h4l-7.3-7.3l5.7-7.2h-3.3l-4.2,5.4l-1.8-1.8l4.7-5.9h-3.3
	l-3.2,4.1l-1.7-1.8H17l3.9,4L16.5,19H19.8z M7.3,6h8.6v2.4h-5.7v2.7h5.4v2.4h-5.4v3.1h5.7V19H7.3V6z M56,6h4.6l2.2,9.5h0.1L65.1,6
	h4.5v13h-2.7V9.2h-0.1L64.3,19h-3.1l-2.4-9.8h-0.1V19H56V6z"
                                />
                            </svg>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingLeft: 40,
                                    paddingRight: 0,
                                    margin: 0,
                                }}>
                                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{ color: "white" }} />}>
                                    <LinkRouter underline="hover" color="white" to="/">
                                        Wells Lessons Learned - Inbox
                                    </LinkRouter>
                                    {pathnames.map((_value, index) => {
                                        const last = index === pathnames.length - 1;
                                        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                                        let loc = breadcrumbNameMap[to];
                                        if (!loc) {
                                            return null;
                                        }

                                        return last ? (
                                            <Typography color="white" key={to}>
                                                {loc}
                                            </Typography>
                                        ) : (
                                            <LinkRouter underline="hover" color="white" to={to} key={to}>
                                                {loc}
                                            </LinkRouter>
                                        );
                                    })}
                                </Breadcrumbs>
                            </Box>

                            {!searchDisabled && (
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{ "aria-label": "search" }}
                                        onChange={mySearch}
                                        value={myGlobalFilter}
                                        disabled={searchDisabled}
                                    />
                                    <IconButton aria-label="clear" size="small" onClick={clearSearch}>
                                        <ClearIcon sx={{ color: "white" }} />
                                    </IconButton>
                                </Search>
                            )}
                           <HelpDropdown>
                            </HelpDropdown>

                            {isAllLessons && (
                                <StyledButton
                                    variant="contained"
                                    title="Export Excel"
                                    endIcon={<DownloadIcon />}
                                    onClick={exportToExcel}>
                                    Export
                                </StyledButton>
                            )}
                            <ExcelExport data={exportData} ref={_exporter} fileName="Lessons Learned"></ExcelExport>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit">
                                <AccountCircle />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {renderMenu}
                </Page.Header>
                <Page.Body className="main">{children}</Page.Body>
            </Page>
        </MuiThemeProvider>
    );
});

import { ErrorMessage, useField, Field } from "formik";
//import React from "react";

interface Props {
    placeholder?: string;
    name: string;
    label: string;
    component: string;
}

export default function MyTextInput(props: Props) {
    // eslint-disable-next-line
    const [field, _meta] = useField(props.name);
    return (
        <>
            <label style={{ fontWeight: 1000, paddingTop: "10px" }}>{props.label}</label>
            <Field
                {...field}
                {...props}
                rows="9"
                
                //error={true}
                // errorPrompt={{
                //     prompt: false,
                //     basic: true,
                //     color: "red",
                //     pointing: "above",
                // }}
            />
            <ErrorMessage name={props.name}>
                {(msg) => <label style={{ color: "red", fontSize: "12px", padding: "4px" }}>{msg}</label>}
            </ErrorMessage>
        </>
    );
}

import { useState, useEffect } from "react";
import { RouteProps } from "react-router-dom"; //useParams
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { loginRequest } from "../../authConfig";
import { useStore } from "../stores/store";

interface Props extends RouteProps {
    children: JSX.Element;
    groups?: string[] | null;
}

export const RouteGuard = ({ children, groups }: Props) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { groupStore } = useStore();

    useEffect(() => {
        if (!groups) {
            setIsAuthorized(true);
            return;
        }

        let intersection = groups.filter((group: string) => groupStore.findUserGroup(group));
        if (intersection.length > 0) {
            setIsAuthorized(true);
        }

        // return () => {
        // };
    }, [groupStore, groups]);

    return (
        <>
            {isAuthorized ? (
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                    {children}
                </MsalAuthenticationTemplate>
            ) : (
                <div className="data-area-div">
                    <h3>You are unauthorized to view this content.</h3>
                </div>
            )}
        </>
    );
};

import axios, { AxiosError, AxiosResponse, Canceler } from "axios";
import { toast } from "react-toastify";
import { protectedResources } from "../../authConfig";
import { msalInstance } from "../../index";
import { LessonFormValues } from "../models/lesson";
import { toastDefaults } from "../models/toastDefaults";
import { SaveWellViewResponse } from "../models/SaveWellViewResponse";
import { DiagnosticsResponse } from "../models/DiagnosticsResponse";
import { DiagnosticsPingResponse } from "../models/DiagnosticsPingResponse";
import { MsGraphRequest } from "../models/MsGraph";

//DEV only
// const sleep = (delay: number) => {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// };

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
var bToken = ""; // TODO: 
//Add a Bearer token to every request
instance.interceptors.request.use(
    async (config) => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in.");
        }

        var silentRequest = {
            account: account,
            scopes: protectedResources.apiLessons.scopes,
        };

        if (config.headers) {
            const response = await msalInstance.acquireTokenSilent(silentRequest).catch((error) => {
                console.log(error);
                throw new axios.Cancel(`Operation canceled because of ${error}`);
            });

            if (response?.accessToken) {
                config.headers.Authorization = `Bearer ${response?.accessToken}`;
                bToken = config.headers.Authorization;               
            }
        } else {
            throw Error("No config.headers");
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

//handle errors
instance.interceptors.response.use(
    async (response) => {
        return response;
    },
    (error: AxiosError) => {
        if (axios.isCancel(error)) {
            console.error(error);
            toast.error(error.message, toastDefaults);
        } else if (!error.response) {
            console.error(error);
            toast.error(error.message, toastDefaults);
        } else {
            // eslint-disable-next-line
            const { data, status, config, headers } = error.response!;
            switch (status) {
                case 400:
                    console.log(error);
                    console.log(data);
                    toast.error(error.message, toastDefaults);
                    break;
                case 401:
                    console.log(error);
                    console.log(data);
                    if (headers["www-authenticate"]?.startsWith('Bearer error="invalid_token"')) {
                        toast.error('Bearer error="invalid_token"', toastDefaults);
                    } else {
                        toast.error(error.message, toastDefaults);
                    }
                    break;
                case 403:
                    console.log(error);
                    console.log(data);
                    if (headers["www-authenticate"]) {
                        toast.error('Bearer error="invalid_token"', toastDefaults);
                    } else {
                        toast.error(error.message, toastDefaults);
                    }
                    break;
                case 404:
                    console.log(error);
                    console.log(data);
                    toast.error(error.message, toastDefaults);
                    break;
                case 500:                    
                    console.log(Object.keys(headers));
                    var auth = headers["www-authenticate"];
                    if (auth) {
                        var url = auth.substr(auth.indexOf("=") + 1);
                        window.open(url);
                        toast.error('Bearer error="invalid_token"', toastDefaults);
                    } else {
                        toast.error(error.message, toastDefaults);
                    }
                    console.log(error);
                    console.log(data);
                    break;
                default:
                    console.log(error);
                    toast.error(error.message, toastDefaults);
            }
        }

        return Promise.reject(error);
    }
);
//Add a cancel to every request
let CancelRequest: Canceler;
const Cancel = { cancelRequest: () => CancelRequest() };

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

//generic requests
const requests = {
    get: <T>(url: string) =>
        instance
            .get<T>(url, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    getParamIsUncon: <T>(url: string, isUncon: boolean) =>
        instance
            .get<T>(url, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
                params: {
                    isUncon: isUncon,
                },
            })
            .then(responseBody),
    post: <T>(url: string, body: {}) =>
        
        instance
            .post<T>(url, body, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    postLessonAttachment: (url: string, body: LessonFormValues) => {
        if(body.attachmentsFiles !== null){
            const data = new FormData();        
            data.append("lessonId", body.id!);        
            data.append("isUncon", body.isUncon === true? "true":"false");
            for (let i = 0; i < body.attachmentsFiles.length; i++) {                                
                data.append("attachments", body.attachmentsFiles[i])                
            }
           
    
            const fullUrl = process.env.REACT_APP_API_URL + url;        
            
            fetch(fullUrl,{
                method:'POST',
                headers:{'Authorization': bToken},
                body:data
            });
        }
       
        // instance
        //         .post(url, data, {                   
        //             transformRequest:()=>data,
        //             cancelToken: new axios.CancelToken(function executor(c) {
        //                 CancelRequest = c;
        //             }
        //             ),
        //         })                
                
        //         .then(responseBody)
        //         .catch(error => {
        //             console.log(error);
        //             console.log(error.response.data.error)
        //          });
    },
    
    put: <T>(url: string, body: {}) =>
        instance
            .put<T>(url, body, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    del: <T>(url: string, isUncon: boolean) =>
        instance
            .delete<T>(url, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
                params: {
                    isUncon: isUncon,
                },
            })
            .then(responseBody),
};

//lesson requests
const Lessons = {
    create: (lesson: LessonFormValues) => requests.post<LessonFormValues>("OperationLesson", lesson),
    uploadAttachment:(lesson: LessonFormValues) => requests.postLessonAttachment("OperationLessonAttachment/AddAttachment", lesson),
    edit: (lesson: LessonFormValues) => requests.put<any>(`OperationLesson/${lesson.id}`, lesson),
    saveUpdate: (lesson: LessonFormValues) => requests.put<any>(`OperationLesson/SaveUpdate/${lesson.id}`, lesson),
    submitForReview: (lesson: LessonFormValues) => requests.put<any>(`OperationLesson/submitForReview/${lesson.id}`, lesson),
    reassignResource: (lesson: LessonFormValues) => requests.put<any>(`OperationLesson/reassignResource/${lesson.id}`, lesson),
    reject: (lesson: LessonFormValues) => requests.put<any>(`OperationLesson/reject/${lesson.id}`, lesson),
    close: (lesson: LessonFormValues) => requests.put<any>(`OperationLesson/close/${lesson.id}`, lesson),
    details: (id: string, isUncon: boolean) => requests.getParamIsUncon<any>(`OperationLesson/${id}`, isUncon),
    delete: (id: string, isUncon: boolean) => requests.del<any>(`OperationLesson/${id}`, isUncon),
};

//wellview requests
const WellviewPicks = {
    saveLessonTyp: () => requests.get<SaveWellViewResponse>("WvPickList/SaveLessonTyp"),
    saveJobIntervalLessonTyp: () => requests.get<SaveWellViewResponse>("WvPickList/SaveJobIntervalLessonTyp"),

    // saveWellHeaders: () => requests.get<SaveWellViewResponse>("WvPickList/SaveWellheaders"),
    // saveJobContacts: () => requests.get<SaveWellViewResponse>("WvPickList/SaveJobContacts"),
    // saveJobs: () => requests.get<SaveWellViewResponse>("WvPickList/SaveJobs"),
    // saveJobRigs: () => requests.get<SaveWellViewResponse>("WvPickList/SaveJobRigs"),
    // saveJobProgramPhases: () => requests.get<SaveWellViewResponse>("WvPickList/SaveJobProgramPhases"),
};

const WellEstPicks = {
    list: (route: string) => requests.get<any>(route)
};




//diagnostics requests
const Diagnostics = {
    ping: () => requests.get<DiagnosticsPingResponse>("Diagnostics/Ping"),
    diag: () => requests.get<DiagnosticsResponse>("Diagnostics/diag"),
    //
};

const Users = {
    get: (request: MsGraphRequest) => requests.post<any>(`MsGraph`, request),
};

//for export
const apiAgent = {
    Lessons,
    WellviewPicks,
    Diagnostics,
    WellEstPicks,
    Cancel,
    Users,
};

export default apiAgent;

import { ErrorMessage, useField } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
    ComboBox,
    ComboBoxChangeEvent,
    ComboBoxFilterChangeEvent,
    DropDownsPopupSettings,
} from "@progress/kendo-react-dropdowns";
import { WvComboBoxItem } from "../../models/WvComboBoxItem";
import { toast } from "react-toastify";
import { toastDefaults } from "../../models/toastDefaults";
import { ServerError } from "../../models/serverError";
import { useStore } from "../../stores/store";
import apiAgent from "../../api/apiAgent";



const loadingData: WvComboBoxItem[] = [];


interface Props {
    placeholder: string;
    name: string;
    label: string;
    textField: string;
    baseUrl: string;
    selectColumns?: string;
    distinct?: string;
    setValue?(value: WvComboBoxItem): void;
    dataCaching: React.RefObject<any>;
    useInputfilterColumnName?: boolean;
    inputfilterColumn?: string | null;
    inputfilter?: WvComboBoxItem | null;
    orderbyColumnName?: string | null;
    disabled: boolean;
    additionalAttribute?: string | null;
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
    resetFields?: string[] | null;
}

export default function PickListKendoComboBox(props: Props) {
    // eslint-disable-next-line
    const [field, _meta, helpers] = useField(props.name);
    const { commonStore, groupStore } = useStore();

    const navigate = useNavigate();

    const requestStarted = useRef(false);
    const mountedRef = useRef(true);
    const filterRef = useRef("");

    const [data, setData] = useState<WvComboBoxItem[]>([]);
    const [filteredData, setFilteredData] = useState<WvComboBoxItem[]>([]);
    const [loading, setLoading] = useState(false);

    const requestData = useCallback(
        async (skip: number, filterLocal: string, inputLocal: WvComboBoxItem | null | undefined) => {
            if (props.disabled) {
                setLoading(false);
                return;
            }

            var url: string;
            if(groupStore.isUnconUser) {
                url = "Uncon" + props.baseUrl;
            } else {
                url = props.baseUrl;
            }
           
           
            setLoading(true);

            apiAgent.WellEstPicks.list(url)
                .then((json) => {
                    if (!mountedRef.current) return null;

                    if (!json) {
                        requestStarted.current = false;
                        setLoading(false);
                        return;
                    }

                    if (json.message && json.message.error) {
                        toast.error(json.message.error, toastDefaults);
                        let serverError: ServerError = new ServerError();
                        serverError.message = json.message.error;
                        serverError.details = json.message.stacktrace;
                        commonStore.setServerError(serverError);
                        navigate("/server-error");
                        return;
                    }

                   
                    const count = json.length;
                    if (count === 0 ) {
                        setData([]); //no data
                        setFilteredData([]);

                    } else {
                        const items: WvComboBoxItem[] = [];
                        json.forEach((element: any, index: number) => {
                            const id = element["Id"];
                            const idwell = element["Idwell"];
                            const idrec = element["Idrec"];
                            const value = element[props.textField];
                            const addAtt = props.additionalAttribute? element[props.additionalAttribute] : "";
                            const item: WvComboBoxItem = {
                                Id: id ?? null,
                                Idwell: idwell ?? null,
                                Idrec: idrec ?? null,
                                Name: value,
                                AdditionalAttribute: addAtt
                            };
                            items.push(item);
                            setData(items);
                            setFilteredData(items);
                        });
                    }
                    setLoading(false);
                })
                .catch((error: any) => {
                    setLoading(false);
                    console.log(error);
                });
        },
        // eslint-disable-next-line
        [props.disabled]
    );

    useEffect(() => {
        mountedRef.current = true;

        setFilteredData(loadingData); //show loading... message for each item
        requestData(0, filterRef.current, props.inputfilter);
        return () => {
            mountedRef.current = false;
        };
        // eslint-disable-next-line
    }, [props.disabled]);


    const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
        if(event.filter.value === '' || event.filter.value === null){
            setFilteredData(data);
            return;
        }
        const filtered = data.filter(item => item.Name.toLowerCase().includes(event.filter.value.toLowerCase()));
        setFilteredData(filtered)
    };


    const onChange = (event: ComboBoxChangeEvent) => {
        const value = event.value;
        helpers.setValue(value);
        if (props.setValue) {
            props.setValue(value);
        }

        if(props.resetFields && props.setFieldValue){
            props.resetFields.forEach(field => {
             if(props.setFieldValue) {
                props.setFieldValue(field, null);
             }
            })
        }
    };

    const popupSettings: DropDownsPopupSettings = {
        height: "425px",
    };
    return (
        <>
            <label style={{ fontWeight: 1000, paddingTop: "10px" }}>{props.label}</label>
            <ComboBox
                // clearButton={false}
                loading={loading}
                popupSettings={popupSettings}
                disabled={props.disabled}
                placeholder={props.placeholder}
                name={props.name}
                data={filteredData}
                value={field.value}
                onChange={onChange}
                onBlur={() => helpers.setTouched(true)}
                dataItemKey={"Name"}
                textField={"Name"}
                filterable={true}
                onFilterChange={onFilterChange}
            />
            <ErrorMessage name={props.name}>
                {(msg) => <label style={{ color: "red", fontSize: "12px", padding: "4px" }}>{msg}</label>}
            </ErrorMessage>
        </>
    );
}

//import React from "react";
import { Button, Container } from "@exxonmobil/react-unity";
import { useNavigate } from "react-router-dom";

//show not found
export default function NotFound() {
    let navigate = useNavigate();

    const selectInbox = () =>
    {
        navigate("/");
    }

    return (
        <Container >
            <h1>
                Oops - we've looked everywhere and could not find this.
            </h1>
            <Button onClick={selectInbox}>
                    Return to home page
            </Button>
        </Container>
    );
}

//import * as React from "react";
import { VisibilityOnIcon } from "@exxonmobil/react-unity";
import { Button } from "@progress/kendo-react-buttons";

export const MyGrid1ButtonCell = (props: any) => {
    const { dataItem } = props;

    return (
        <td className="k-command-cell">
            <Button look="clear" onClick={() => props.display(dataItem)}>
                <VisibilityOnIcon size="small" />
            </Button>
        </td>
    );
};

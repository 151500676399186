import axios, { AxiosResponse, Canceler } from "axios";
import { msalInstance } from "../../index";
// import { toast } from "react-toastify";
// import { toastDefaults } from "../models/toastDefaults";

//DEV only
// const sleep = (delay: number) => {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// };

const instance = axios.create({
    baseURL: process.env.REACT_APP_MEASURES_URL,
});
//instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

//Add a Bearer token to every request
instance.interceptors.request.use(
    async (config) => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in.");
        }

        var silentRequest = {
            account: account,
            scopes: [process.env.REACT_APP_MEASURES_SCOPES as string],
        };

        if (config.headers) {
            const response = await msalInstance.acquireTokenSilent(silentRequest).catch((error) => {
                console.log(error);
                throw new axios.Cancel(`Operation canceled because of ${error}`);
            });

            if (response?.accessToken) {
                config.headers.Authorization = `Bearer ${response?.accessToken}`;
                config.headers.ConsistencyLevel = "eventual";
            }
        } else {
            throw Error("No config.headers");
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

//handle errors
// instance.interceptors.response.use(
//     async (response) => {
//         return response;
//     },
//     (error: AxiosError) => {
//         console.log(error);

//         return Promise.reject(error);
//     }
// );

//Add a cancel to every request
let CancelRequest: Canceler;
const Cancel = { cancelRequest: () => CancelRequest() };

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

//generic requests
const requests = {
    get: <T>(url: string) =>
        instance
            .get<T>(url, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    post: <T>(url: string, body: {}) =>
        instance
            .post<T>(url, body, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    put: <T>(url: string, body: {}) =>
        instance
            .put<T>(url, body, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    del: <T>(url: string) =>
        instance
            .delete<T>(url, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
};

const MeasuresEvent = {
    ApplicationName: "Wells Lessons Learned",
    AppRegistrationId: process.env.REACT_APP_MEASURES_CLIENT_ID,
    Duration: 0,
    TimeDiff: 0,
    AdditionalProperties: {},
};

const Send = {
    post: (body: any) => {
        try {
            const result = requests.post<any>("api/web-app/", body).catch((error) => {
                console.log(error);
            });
            return result;
        } catch (error) {
            console.log(error);
            return;
        }
    },
};

//for export
const measuresAgent = {
    Send,
    MeasuresEvent,
    Cancel,
};

export default measuresAgent;

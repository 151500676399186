import { GraphComboBoxItem } from "./GraphComboBoxItem";
import { WvComboBoxItem } from "./WvComboBoxItem";

export interface Lesson {
    id: string;
    createdByUserId: string;
    createdByUserName: string;
    fullUserName: string;
    isLessonLinkedToWell: string;
    well: WvComboBoxItem;
    stewardingTeam: WvComboBoxItem;
    area?: WvComboBoxItem;
    basin?:string;
    job: WvComboBoxItem;
    rig: WvComboBoxItem;
    phase: WvComboBoxItem;
    category: WvComboBoxItem;
    subCategory?: WvComboBoxItem | null;
    wjiContact?: GraphComboBoxItem | null;
    asset?: WvComboBoxItem;
    company: WvComboBoxItem;
    observations: string;
    actions: string;
    updates: string;
    rejection: string;
    closure: string;

    status: string;
    createdDate: Date;
    updatedDate: Date;
    actionRequired: string;
    resource: GraphComboBoxItem | null;
    owners: GraphComboBoxItem[];
    ownersDisplay: string;
    isUncon: boolean;
    attachments: LessonAttachment[];
    attachmentsFiles: FileList; // for upload
    sendLessonToWellView : boolean //send data to willview
    isB2B: boolean;
    isOBO: boolean;
}

export class Lesson implements Lesson {
    constructor(init?: LessonFormValues) {
        Object.assign(this, init);
    }
}

export class LessonFormValues {
    id?: string = undefined;
    createdByUserId?: string = "";
    createdByUserName?: string = "";
    fullUserName?: string = undefined;
    isLessonLinkedToWell: string = 'yes';
    well: WvComboBoxItem | null = null;
    stewardingTeam?: WvComboBoxItem | null = null;
    area?: WvComboBoxItem | null = null;
    basin?:string = "";
    job: WvComboBoxItem | null = null;
    rig: WvComboBoxItem | null = null;
    phase: WvComboBoxItem | null = null;
    category: WvComboBoxItem | null = null;
    subCategory?: WvComboBoxItem | null = null;
    wjiContact?: GraphComboBoxItem | null = null;
    asset?: WvComboBoxItem | null = null;
    company: WvComboBoxItem | null = null;
    observations: string = "";
    actions: string = "";
    updates: string = "";
    rejection?: string = undefined;
    closure?: string = undefined;
    status?: string = undefined;
    createdDate?: Date = undefined;
    updatedDate?: Date = undefined;
    actionRequired: string = "no";
    resource: GraphComboBoxItem | null = null;
    owners: GraphComboBoxItem[] | null = null;
    ownersDisplay: string = "";
    isUncon: boolean = false;
    attachments: LessonAttachment[] | null= null;
    attachmentsFiles: FileList | null= null;
    sendLessonToWellView : boolean = false;
    isB2B: boolean = false;
    isOBO: boolean = false;
    constructor(lesson?: LessonFormValues) {
        if (lesson) {
            this.id = lesson.id;
            this.isLessonLinkedToWell = lesson.isLessonLinkedToWell;
            this.well = lesson.well;
            this.stewardingTeam = lesson.stewardingTeam;
            this.job = lesson.job;
            this.rig = lesson.rig;
            this.phase = lesson.phase;
            this.category = lesson.category;
            this.subCategory = lesson.subCategory;
            this.wjiContact = lesson.wjiContact;
            this.asset = lesson.asset;
            this.company = lesson.company;
            this.observations = lesson.observations;
            this.actions = lesson.actions;
            this.updates = lesson.updates;
            this.rejection = lesson.rejection;
            this.closure = lesson.closure;

            this.createdByUserId = lesson.createdByUserId;
            this.createdByUserName = lesson.createdByUserName;
            this.fullUserName = lesson.fullUserName;
            this.status = lesson.status;
            this.createdDate = lesson.createdDate;
            this.updatedDate = lesson.updatedDate;

            this.actionRequired = lesson.actionRequired;
            this.resource = lesson.resource;
            this.owners = lesson.owners;
            this.ownersDisplay = lesson.ownersDisplay;
            this.isUncon = lesson.isUncon;
            this.area = lesson.area;
            this.basin = lesson.basin;

            this.attachments = lesson.attachments;
            this.attachmentsFiles = lesson.attachmentsFiles;
            this.sendLessonToWellView = lesson.sendLessonToWellView;
            this.isB2B = lesson.isB2B;
            this.isOBO = lesson.isOBO;
        }
    }
}

export class LessonAttachment {
    lessonId: string | null = null;
    attachmentId: string | null = null;
    attachmentUri: string | null = null;
    attachmentFileName: string | null = null;
    attachmentScanPassed: boolean | null = null;
}

import { makeAutoObservable } from "mobx";
import { AdGroup } from "../models/AdGroup";

export default class GroupStore {

    private usersGroupRegistry: Set<string> = new Set<string>();
    
    gettingUnconUserGroups: boolean = false;
  
    userUnconGroups: AdGroup[] = [];
    isUnconUser: boolean = false;

    b2bGroups: AdGroup[] = [];
    userB2BGroups: AdGroup[] = [];
    isB2BUser: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    findUserGroup = (group: string) => {
        return this.usersGroupRegistry.has(group);
    };

    addUserGroup = (group: string) => {
        this.usersGroupRegistry.add(group);
    };

    userGroupsLength = () => {
        return this.usersGroupRegistry.size;
    };

    
}

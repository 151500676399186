//import * as React from "react";
import { makeObservable, observable, runInAction } from "mobx"; //reaction,
import { DataResult, SortDescriptor, State, toODataString } from "@progress/kendo-data-query";

import apiAgent from "../api/apiAgent";
import odataAgent from "../api/odataAgent";

import { Lesson, LessonAttachment, LessonFormValues } from "../models/lesson";
//import { format } from "date-fns";

import { WvComboBoxItem } from "../models/WvComboBoxItem";
import { GraphComboBoxItem } from "../models/GraphComboBoxItem";
import { getAllMyLessonsFilter } from "../../features/lessons/dashboards/MyInbox";
import { AdGroup } from "../models/AdGroup";

export interface LoadLessonProps {
    dataState: State;
    lessonFilter?: string;
    isUnconUser?: boolean;
    userB2BGroups: AdGroup[];
    myLessons: boolean;
    allMyDetailedLessons: boolean;
    longUserId?: string;
    shortUserId?: string;
    resetDataState(): State;
    requestEnded(): void;
    isB2BUser?: boolean;
}

//define custom interface here for B2BGroups
export interface LoadLessonPropsExport {
    userB2BGroups: AdGroup[];
    requestEnded(): void;
}

export default class LessonStore {
    private currentLessonFilter: string | undefined = "";
    private currentOdataString: string = "";
    private currentDataState: State | undefined;
    private dataCache = new Map<number, any>();
    private dataDetailedCache = new Map<number, any>();
    private allDataCache = new Map<number, any>();
    private totalOdataCount: number = 0;
    private lessonRegistry = new Map<string, LessonFormValues>();
    private lessonFilter: string = "";
    private lessonFilterIndex: number = 3;

    globalFilter: string = "";

    forceReloadLessons: boolean = false;
    allLessons: undefined | DataResult = {
        data: [],
        total: 0,
    };
    lessons: undefined | DataResult = {
        data: [],
        total: 0,
    };

    constructor() {
        makeObservable(this, {
            globalFilter: observable,
            lessons: observable,
            allLessons: observable,
        });
    }

    setLessonFilterIndex = (value: number) => {
        if (value === this.lessonFilterIndex) {
            return;
        }
        runInAction(() => {
            this.lessonFilterIndex = value;
        });
    };
    getLessonFilterIndex = () => {
        return this.lessonFilterIndex;
    };

    setGlobalFilter = (value: string) => {
        runInAction(() => {
            this.globalFilter = value;
        });
    };
    setLessonFilter = (value: string) => {
        runInAction(() => {
            this.lessonFilter = value;
        });
    };
    getLessonFilter = () => {
        return this.lessonFilter;
    };

    setForceReloadLessons = (value: boolean) => {
        runInAction(() => {
            this.forceReloadLessons = value;
        });
    };

    private resetCache = (props: LoadLessonProps) => {
        runInAction(() => {
            if (props.myLessons) {
                this.dataCache.clear();
            } else if (props.allMyDetailedLessons) {
                this.dataDetailedCache.clear();
            } else {
                this.allDataCache.clear();
            }
        });
    };

    loadLessons = (props: LoadLessonProps) => {
        let newDataState: State = props.dataState;
        let sortChanged = false;
        let sortDirectionChanged = false;
        newDataState.sort?.forEach((element: SortDescriptor) => {
            const found = this.currentDataState?.sort?.find((x) => x.field === element.field);
            if (!found) {
                sortChanged = true;
            } else {
                if (found.dir !== element.dir) {
                    sortDirectionChanged = true;
                }
            }
        });

        if (this.forceReloadLessons) {
            this.resetCache(props);
            this.setForceReloadLessons(false);
        }

        if (props.lessonFilter !== this.currentLessonFilter) {
            this.currentLessonFilter = props.lessonFilter;
            //newDataState = props.resetDataState();
            this.resetCache(props);
        } else if (newDataState.filter !== this.currentDataState?.filter) {
            this.resetCache(props);
        } else if (newDataState.group !== this.currentDataState?.group) {
            this.resetCache(props);
        } else if (sortChanged || sortDirectionChanged) {
            this.resetCache(props);
        }
        // else if (toODataString(newDataState) === this.currentOdataString) {
        //     props.requestEnded();
        //     return; //already sent request
        // }

        if (newDataState.take === this.currentDataState?.take) {
            const data: Array<any> = [];
            let found = false;
            for (let i = 0; i < newDataState.take!; i++) {
                let item = undefined;
                let index = newDataState.skip! + i;
                if (props.myLessons) {
                    item = this.dataCache.get(index);
                } else if (props.allMyDetailedLessons) {
                    item = this.dataDetailedCache.get(index);
                } else {
                    item = this.allDataCache.get(index);
                }
                if (item) {
                    data.push(item);
                    found = true; //data already loaded
                }
            }
            if (found) {
                if (props.myLessons) {
                    this.lessons = {
                        data: data,
                        total: this.totalOdataCount,
                    };
                } else if (props.allMyDetailedLessons) {
                    this.lessons = {
                        data: data,
                        total: this.totalOdataCount,
                    };
                } else {
                    this.allLessons = {
                        data: data,
                        total: this.totalOdataCount,
                    };
                }
                return; //data already loaded
            }
        }

        this.lessonRegistry.clear();

        this.currentDataState = newDataState;
        this.currentOdataString = toODataString(newDataState);

        //FILTER
        let currentOdataStringMinusFilter = this.currentOdataString.split("$filter=");

        let companyContainsString = "";
        let companyContainsStringParens = "";
        if (props.userB2BGroups && props.userB2BGroups.length > 0) {
            let companyContains: string[] = [];
            props.userB2BGroups.forEach((group, index) => {
                companyContains.push(`contains(Company/Name, '${group.shortName}')`);
            });

            companyContains.forEach((contains, index) => {
                if (index === 0) {
                    companyContainsString = contains;
                } else {
                    companyContainsString = companyContainsString + ` OR ` + contains;
                }
            });
            companyContainsStringParens = `(${companyContainsString})`;
        }

        let globalFilterString = "";
        let globalFilterStringParens = "";
        if (this.globalFilter) {
            if (companyContainsString.length > 0) {
                //No Company
                globalFilterString =
                    `contains(FullUserName, '${this.globalFilter}')` +
                    ` OR Owners/any(d:contains(d/DisplayName, '${this.globalFilter}'))` +
                    ` OR contains(ResourceDisplayName, '${this.globalFilter}')` +
                    ` OR contains(Actions, '${this.globalFilter}')` +
                    ` OR contains(Observations, '${this.globalFilter}')` +
                    ` OR contains(Country, '${this.globalFilter}')` +
                    ` OR contains(Well/Wellname, '${this.globalFilter}')` +
                    ` OR contains(Rig/Contractor, '${this.globalFilter}')` +
                    ` OR contains(Rig/Rigno, '${this.globalFilter}')` +
                    ` OR contains(Category/CategoryType, '${this.globalFilter}')` +
                    ` OR contains(Phase/Code1, '${this.globalFilter}')` +
                    ` OR contains(Phase/Code2, '${this.globalFilter}')`;
            } else {
                globalFilterString =
                    `contains(FullUserName, '${this.globalFilter}')` +
                    ` OR Owners/any(d:contains(d/DisplayName, '${this.globalFilter}'))` +
                    ` OR contains(ResourceDisplayName, '${this.globalFilter}')` +
                    ` OR contains(Actions, '${this.globalFilter}')` +
                    ` OR contains(Observations, '${this.globalFilter}')` +
                    ` OR contains(Country, '${this.globalFilter}')` +
                    ` OR contains(Well/Wellname, '${this.globalFilter}')` +
                    ` OR contains(Rig/Contractor, '${this.globalFilter}')` +
                    ` OR contains(Rig/Rigno, '${this.globalFilter}')` +
                    ` OR contains(Category/CategoryType, '${this.globalFilter}')` +
                    ` OR contains(Company/Name, '${this.globalFilter}')` +
                    ` OR contains(Phase/Code1, '${this.globalFilter}')` +
                    ` OR contains(Phase/Code2, '${this.globalFilter}')`;
            }

            // +` OR contains(Status, '${this.globalFilter}')`  //TODO enums don't work
            // OR contains(CreatedDate, '${this.globalFilter}') //TODO dates as strings don't work
            // OR contains(UpdatedDate, '${this.globalFilter}') //TODO dates as strings don't work
            globalFilterStringParens = `(${globalFilterString})`;
        }

        let filter = "";
        if (props.myLessons || props.allMyDetailedLessons) {
            if (this.currentLessonFilter) {
                filter = `$filter=(` + this.currentLessonFilter + `) `;
            } else {
                filter = `$filter=(` + getAllMyLessonsFilter(props.longUserId, props.shortUserId) + `) `;
            }
        }

        if (this.globalFilter) {
            if (filter) {
                filter = filter + ` AND ${globalFilterStringParens}`;
            } else {
                filter = `$filter=${globalFilterString}`;
            }
        }

        if (companyContainsString.length > 0) {
            if (filter) {
                filter = filter + ` AND ${companyContainsStringParens}`;
            } else {
                filter = `$filter=${companyContainsString}`;
            }
        }

        if (this.currentDataState.filter) {
            if (filter) {
                filter = filter + " AND " + currentOdataStringMinusFilter[1]; //combine filters if currentOdataString has filter
            } else {
                filter = this.currentOdataString;
            }
        } else {
            if (filter) {
                filter = filter + "&" + currentOdataStringMinusFilter[0]; //combine filters if currentOdataString has filter
            } else {
                filter = this.currentOdataString;
            }
        }

        let expand =
            "&$expand=" +
            "Owners($select=DisplayName,UserId)," +
            "Well($select=Idwell,Wellname)," +
            "Rig($select=Idwell,Idrec,Rigno,Contractor)," +
            "Category($select=Id,CategoryType)," +
            "Company($select=Id,Name)," +
            "Phase($select=Idwell,Idrec,Code1,Code2),";            
        
        let selectFields = "&$select=Status,Observations,Actions,Closure,CreatedDate,UpdatedDate,FullUserName,CreatedByUserId,Id,Country,ActionRequired,ResourceDisplayName,ResourceMail,ResourceUserId"; 

        var baseUrl: string;
        if (props.isUnconUser && (props.myLessons || props.allMyDetailedLessons)) {
            baseUrl = "UnconLesson?";
            expand = expand +="Area($select=Id,Name)";
            selectFields = selectFields + ",Basin";
        } else {
            baseUrl = "Lesson?";
        }        
        
        selectFields = selectFields + "&$count=true";
        let url =
            baseUrl +
            filter +
            selectFields +
            expand;

        runInAction(() => {
            if (props.myLessons) {
                this.lessons = undefined;
            } else if (props.allMyDetailedLessons) {
                this.lessons = undefined;
            } else {
                this.allLessons = undefined;
            }
        });

        odataAgent.Lessons.list(url)
            .then((json) => {
                if (!json) {
                    console.log("odataAgent.Lessons.list returned nothing");
                    props.requestEnded();
                    return;
                }
                if (json.message && json.message.error) {
                    console.log(json.message.error);
                    props.requestEnded();
                    return;
                }
                if (!json.value) {
                    console.log(json);
                    props.requestEnded();
                    return;
                }

                const number = json["@odata.count"];
                this.totalOdataCount = number;

                const data: Array<any> = [];
                json.value.forEach((item: any, index: number) => {
                    let element = item;

                    const createdDate = element["CreatedDate"];
                    const created = new Date(createdDate);
                    element["CreatedDate"] = created.toLocaleDateString();

                    const updatedDate = element["UpdatedDate"];
                    if (updatedDate) {
                        const updated = new Date(updatedDate);
                        element["UpdatedDate"] = updated.toLocaleDateString();
                    }

                    const owners = element["Owners"];
                    if (owners) {
                        owners.forEach((item2: any, index: number) => {
                            const name = item2.DisplayName;
                            if (index === 0) {
                                element["OwnersDisplay"] = name;
                            } else {
                                element["OwnersDisplay"] = element["OwnersDisplay"] + ", " + name;
                            }
                        });
                    }

                    data.push(element);

                    let i = index + newDataState.skip!;
                    if (props.myLessons) {
                        this.dataCache.set(i, element);
                    } else if (props.allMyDetailedLessons) {
                        this.dataDetailedCache.set(i, element);
                    } else {
                        this.allDataCache.set(i, element);
                    }
                });

                runInAction(() => {
                    if (props.myLessons) {
                        this.lessons = {
                            data: data,
                            total: this.totalOdataCount,
                        };
                    } else if (props.allMyDetailedLessons) {
                        this.lessons = {
                            data: data,
                            total: this.totalOdataCount,
                        };
                    } else {
                        this.allLessons = {
                            data: data,
                            total: this.totalOdataCount,
                        };
                    }
                    props.requestEnded();
                });
            })
            .catch((error: any) => {
                runInAction(() => {
                    if (props.myLessons) {
                        this.lessons = {
                            data: [],
                            total: 0,
                        };
                    } else if (props.allMyDetailedLessons) {
                        this.lessons = {
                            data: [],
                            total: 0,
                        };
                    } else {
                        this.allLessons = {
                            data: [],
                            total: 0,
                        };
                    }
                });

                props.requestEnded();
                console.log(error);
            });
    };

    loadCombinedLessons = (props: LoadLessonProps) => {
        let newDataState: State = props.dataState;
        let sortChanged = false;
        let sortDirectionChanged = false;
        newDataState.sort?.forEach((element: SortDescriptor) => {
            const found = this.currentDataState?.sort?.find((x) => x.field === element.field);
            if (!found) {
                sortChanged = true;
            } else {
                if (found.dir !== element.dir) {
                    sortDirectionChanged = true;
                }
            }
        });

        if (this.forceReloadLessons) {
            this.resetCache(props);
            this.setForceReloadLessons(false);
        }

        if (newDataState.filter !== this.currentDataState?.filter) {
            this.resetCache(props);
        } else if (newDataState.group !== this.currentDataState?.group) {
            this.resetCache(props);
        } else if (sortChanged || sortDirectionChanged) {
            this.resetCache(props);
        }

        if (newDataState.take === this.currentDataState?.take) {
            const data: Array<any> = [];
            let found = false;
            for (let i = 0; i < newDataState.take!; i++) {
                let item = undefined;
                let index = newDataState.skip! + i;
                if (props.myLessons) {
                    item = this.dataCache.get(index);
                } else if (props.allMyDetailedLessons) {
                    item = this.dataDetailedCache.get(index);
                } else {
                    item = this.allDataCache.get(index);
                }
                if (item) {
                    data.push(item);
                    found = true; //data already loaded
                }
            }
            if (found) {
                if (props.myLessons) {
                    this.lessons = {
                        data: data,
                        total: this.totalOdataCount,
                    };
                } else if (props.allMyDetailedLessons) {
                    this.lessons = {
                        data: data,
                        total: this.totalOdataCount,
                    };
                } else {
                    this.allLessons = {
                        data: data,
                        total: this.totalOdataCount,
                    };
                }
                return; //data already loaded
            }
        }

        this.lessonRegistry.clear();

        this.currentDataState = newDataState;
        this.currentOdataString = toODataString(newDataState);

        //FILTER
        let currentOdataStringMinusFilter = this.currentOdataString.split("$filter=");

        let companyContainsString = "";
        let companyContainsStringParens = "";
        if (props.userB2BGroups && props.userB2BGroups.length > 0) {
            let companyContains: string[] = [];
            props.userB2BGroups.forEach((group, index) => {
                companyContains.push(`contains(tolower(Company), tolower('${group.shortName}'))`);
            });

            companyContains.forEach((contains, index) => {
                if (index === 0) {
                    companyContainsString = contains;
                } else {
                    companyContainsString = companyContainsString + ` OR ` + contains;
                }
            });
            companyContainsStringParens = `(${companyContainsString})`;
        }

        let globalFilterString = "";
        let globalFilterStringParens = "";
        if (this.globalFilter) {
            if (companyContainsString.length > 0) {
                //No Company
                globalFilterString =
                    `contains(tolower(FullUserName), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Actions), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Category), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Closure), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Country), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Observations), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Owners), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(PhaseCode1), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(PhaseCode2), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(ResourceDisplayName), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Contractor), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Rigno), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Type), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Wellname), tolower('${this.globalFilter}'))`;
            } else {
                globalFilterString =
                    `contains(tolower(FullUserName), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Actions), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Category), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Closure), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Country), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Observations), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Owners), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(PhaseCode1), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(PhaseCode2), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(ResourceDisplayName), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Contractor), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Company), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Rigno), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Type), tolower('${this.globalFilter}'))` +
                    ` OR contains(tolower(Wellname), tolower('${this.globalFilter}'))`;
            }

            // +` OR contains(Status, '${this.globalFilter}')`  //TODO enums don't work
            // OR contains(CreatedDate, '${this.globalFilter}') //TODO dates as strings don't work
            // OR contains(UpdatedDate, '${this.globalFilter}') //TODO dates as strings don't work
            globalFilterStringParens = `(${globalFilterString})`;
        }

        let filter = "";
        if (this.globalFilter) {
            filter = `$filter=${globalFilterStringParens}`;
        }

        if (companyContainsString.length > 0) {
            if (filter) {
                filter = filter + ` AND ${companyContainsStringParens}`;
            } else {
                filter = `$filter=${companyContainsString}`;
            }
        }

        if (this.currentDataState.filter) {
            if (filter) {
                filter = filter + " AND " + currentOdataStringMinusFilter[1]; //combine filters if currentOdataString has filter
            } else {
                filter = this.currentOdataString;
            }
        } else {
            if (filter) {
                filter = filter + "&" + currentOdataStringMinusFilter[0]; //combine filters if currentOdataString has filter
            } else {
                filter = this.currentOdataString;
            }
        }

        let url = "CombinedLesson?" + filter + "&$count=true";

        runInAction(() => {
            if (props.myLessons) {
                this.lessons = undefined;
            } else if (props.allMyDetailedLessons) {
                this.lessons = undefined;
            } else {
                this.allLessons = undefined;
            }
        });

        odataAgent.Lessons.list(url)
            .then((json) => {
                if (!json) {
                    console.log("odataAgent.Lessons.Combined.list returned nothing");
                    props.requestEnded();
                    return;
                }
                if (json.message && json.message.error) {
                    console.log(json.message.error);
                    props.requestEnded();
                    return;
                }
                if (!json.value) {
                    console.log(json);
                    props.requestEnded();
                    return;
                }

                const number = json["@odata.count"];
                this.totalOdataCount = number;

                const data: Array<any> = [];
                json.value.forEach((item: any, index: number) => {
                    let element = item;

                    const createdDate = element["CreatedDate"];
                    const created = new Date(createdDate);
                    element["CreatedDate"] = created.toLocaleDateString();

                    const updatedDate = element["UpdatedDate"];
                    if (updatedDate) {
                        const updated = new Date(updatedDate);
                        element["UpdatedDate"] = updated.toLocaleDateString();
                    }

                    data.push(element);

                    let i = index + newDataState.skip!;
                    if (props.myLessons) {
                        this.dataCache.set(i, element);
                    } else if (props.allMyDetailedLessons) {
                        this.dataDetailedCache.set(i, element);
                    } else {
                        this.allDataCache.set(i, element);
                    }
                });

                runInAction(() => {
                    if (props.myLessons) {
                        this.lessons = {
                            data: data,
                            total: this.totalOdataCount,
                        };
                    } else if (props.allMyDetailedLessons) {
                        this.lessons = {
                            data: data,
                            total: this.totalOdataCount,
                        };
                    } else {
                        this.allLessons = {
                            data: data,
                            total: this.totalOdataCount,
                        };
                    }
                    props.requestEnded();
                });
            })
            .catch((error: any) => {
                runInAction(() => {
                    if (props.myLessons) {
                        this.lessons = {
                            data: [],
                            total: 0,
                        };
                    } else if (props.allMyDetailedLessons) {
                        this.lessons = {
                            data: [],
                            total: 0,
                        };
                    } else {
                        this.allLessons = {
                            data: [],
                            total: 0,
                        };
                    }
                });

                props.requestEnded();
                console.log(error);
            });
    };

    exportCombinedLessons = (props: LoadLessonPropsExport) => {

        let companyContainsString = "";
        if (props.userB2BGroups && props.userB2BGroups.length > 0) {
            let companyContains: string[] = [];
            props.userB2BGroups.forEach((group, index) => {
                companyContains.push(`contains(tolower(Company), tolower('${group.shortName}'))`);
            });

            companyContains.forEach((contains, index) => {
                if (index === 0) {                          // Can reverse if-else (if index != 0 then the 'OR' thing), will improve performance by not checking if and then else everytime - AM
                    companyContainsString = contains;
                } else {
                    companyContainsString = companyContainsString + ` OR ` + contains;
                }
            });
        }


        let url="";
        if(companyContainsString)
        {
            let filter = `$filter=${companyContainsString}`;
            url = "CombinedLesson?" + filter;
        }
        else{
            url = "CombinedLesson";
        }

        const data: Array<any> = [];

        odataAgent.Lessons.list(url) //check with breakpoint
            .then((json) => {
                if (!json) {
                    console.log("odataAgent.Lessons.Combined.list returned nothing");
                    props.requestEnded();
                    return;
                }
                if (json.message && json.message.error) {
                    console.log(json.message.error);
                    props.requestEnded();
                    return;
                }
                if (!json.value) {
                    console.log(json);
                    props.requestEnded();
                    return;
                }

                json.value.forEach((item: any, index: number) => {
                    let element = item;

                    const createdDate = element["CreatedDate"];
                    const created = new Date(createdDate);
                    element["CreatedDate"] = created.toLocaleDateString();

                    const updatedDate = element["UpdatedDate"];
                    if (updatedDate) {
                        const updated = new Date(updatedDate);
                        element["UpdatedDate"] = updated.toLocaleDateString();
                    }

                    data.push(element);
                 
                });
                
                props.requestEnded();

                return data;
            })
            .catch((error: any) => {
                runInAction(() => {
                    return data;                    
                });

                props.requestEnded();
                console.log(error);
            });
        return data;
    };


    loadLesson = async (id: string, isUncon: boolean, isB2B: boolean) => {
        let lesson = this.getLesson(id);
        if (lesson) {
            return lesson;
        } else {
            try {
                let lesson = await apiAgent.Lessons.details(`${id}`, isUncon);
                const loadedLesson = new Lesson();
                loadedLesson.isUncon = isUncon;
                loadedLesson.isB2B = isB2B;
                
                if (lesson.hasOwnProperty("id")) {
                    //console.log("loadLesson json id = " + lesson.id);
                    loadedLesson.id = lesson.id;
                }
                if (lesson.hasOwnProperty("status")) {
                    //console.log("loadLesson json status = " + lesson.status);
                    loadedLesson.status = lesson.status;
                }
                if (lesson.hasOwnProperty("createdDate")) {
                    //console.log("loadLesson json createdDate = " + lesson.createdDate);
                    loadedLesson.createdDate = lesson.createdDate;
                }
                if (lesson.hasOwnProperty("updatedDate")) {
                    //console.log("loadLesson json updatedDate = " + lesson.updatedDate);
                    loadedLesson.updatedDate = lesson.updatedDate;
                }
                if (lesson.hasOwnProperty("createdByUserId")) {
                    //console.log("loadLesson json createdByUserId = " + lesson.createdByUserId);
                    loadedLesson.createdByUserId = lesson.createdByUserId;
                }
                if (lesson.hasOwnProperty("createdByUserName")) {
                    //console.log("loadLesson json createdByUserName = " + lesson.createdByUserName);
                    loadedLesson.createdByUserName = lesson.createdByUserName;
                }
                if (lesson.hasOwnProperty("fullUserName")) {
                    //console.log("loadLesson json fullUserName = " + lesson.fullUserName);
                    loadedLesson.fullUserName = lesson.fullUserName;
                }
                if (lesson.hasOwnProperty("observations")) {
                    //console.log("loadLesson json observations = " + lesson.observations);
                    loadedLesson.observations = lesson.observations;
                }
                if (lesson.hasOwnProperty("actions")) {
                    //console.log("loadLesson json actions = " + lesson.actions);
                    loadedLesson.actions = lesson.actions;
                }
                loadedLesson.updates = "";
                if (lesson.hasOwnProperty("updates")) {
                    //console.log("loadLesson json updates = " + lesson.updates);
                    if (lesson.updates) loadedLesson.updates = lesson.updates;
                }
                loadedLesson.rejection = "";
                if (lesson.hasOwnProperty("rejection")) {
                    //console.log("loadLesson json rejection = " + lesson.rejection);
                    if (lesson.rejection) loadedLesson.rejection = lesson.rejection;
                }
                loadedLesson.closure = "";
                if (lesson.hasOwnProperty("closure")) {
                    //console.log("loadLesson json closure = " + lesson.closure);
                    if (lesson.closure) loadedLesson.closure = lesson.closure;
                }
                if (lesson.hasOwnProperty("isLessonLinkedToWell")) {
                    loadedLesson.isLessonLinkedToWell = lesson.isLessonLinkedToWell;
                }
                if (lesson.hasOwnProperty("well") && lesson.well!== null) {
                    //console.log("loadLesson json well = " + JSON.stringify(lesson.well));
                    const idwell = lesson.well.idwell;
                    const value = lesson.well.name;
                    const item: WvComboBoxItem = {
                        Id: 1,
                        Idwell: idwell,
                        Name: value,
                    };
                    loadedLesson.well = item;
                }
                if (lesson.hasOwnProperty("stewardingTeam") && lesson.stewardingTeam!== null) {
                    const value = lesson.stewardingTeam.name;
                    const item: WvComboBoxItem = {
                        Name: value,
                    };
                    loadedLesson.stewardingTeam = item;
                }
                if (isUncon && lesson.hasOwnProperty("area") && lesson.area !== null) {
                    //console.log("loadLesson json well = " + JSON.stringify(lesson.well));
                    const id = lesson.area.id;
                    const value = lesson.area.name;
                    const item: WvComboBoxItem = {
                        Id: id,                        
                        Name: value
                    };
                    loadedLesson.area = item;
                }
                if (isUncon && lesson.hasOwnProperty("basin")) {
                    //console.log("loadLesson json well = " + JSON.stringify(lesson.well));
                    
                    loadedLesson.basin = lesson.basin;
                }
                if (lesson.hasOwnProperty("attachments") && lesson.attachments) {                    
                    const items: LessonAttachment[] = [];
                    lesson.attachments.forEach((element: any, index: number) => {
                        const lessonId = element.lessonId;
                        const attachmentId = element.attachmentId;
                        const attachmentUri = element.attachmentUri;
                        let attachmentFileName = "";
                        if(element.attachmentFileName){
                              let i = element.attachmentFileName.indexOf("_"); 
                              attachmentFileName = element.attachmentFileName.substring(i + 1);
                        }
                        const attachmentScanPassed = element.attachmentScanPassed;
                        const item: LessonAttachment = {
                            lessonId:lessonId,
                            attachmentId: attachmentId,
                            attachmentUri: attachmentUri,
                            attachmentFileName: attachmentFileName,
                            attachmentScanPassed:attachmentScanPassed
                        };
                        items.push(item);
                        
                    });
                    loadedLesson.attachments = items;
                }
                if (lesson.hasOwnProperty("sendLessonToWellView")) {
                    loadedLesson.sendLessonToWellView = lesson.sendLessonToWellView;
                }
                if (lesson.hasOwnProperty("phase") && lesson.phase !== null) {
                    //console.log("loadLesson json phase = " + JSON.stringify(lesson.phase));
                    const idwell = lesson.phase.idwell;
                    const idrec = lesson.phase.idrec;
                    const value = lesson.phase.name;
                    const item: WvComboBoxItem = {
                        Id: 1,
                        Idwell: idwell,
                        Idrec: idrec,
                        Name: value,
                    };
                    loadedLesson.phase = item;
                }
                if (lesson.hasOwnProperty("job")  && lesson.job !== null) {
                    //console.log("loadLesson json job = " + JSON.stringify(lesson.job));
                    const idwell = lesson.job.idwell;
                    const idrec = lesson.job.idrec;
                    const value = lesson.job.name;
                    const item: WvComboBoxItem = {
                        Id: 1,
                        Idwell: idwell,
                        Idrec: idrec,
                        Name: value,
                    };
                    loadedLesson.job = item;
                }
                if (lesson.hasOwnProperty("rig")  && lesson.rig !== null) {
                    //console.log("loadLesson json rig = " + JSON.stringify(lesson.rig));
                    const idwell = lesson.rig.idwell;
                    const idrec = lesson.rig.idrec;
                    const value = lesson.rig.name;
                    const item: WvComboBoxItem = {
                        Id: 1,
                        Idwell: idwell,
                        Idrec: idrec,
                        Name: value,
                    };
                    loadedLesson.rig = item;
                }
                if (lesson.hasOwnProperty("category")) {
                    //console.log("loadLesson json category = " + JSON.stringify(lesson.category));
                    const id = lesson.category.id;
                    const idwell = lesson.category.idwell;
                    const idrec = lesson.category.idrec;
                    const value = lesson.category.name;
                    const item: WvComboBoxItem = {
                        Id: id,
                        IdLib: id,
                        Idwell: idwell,
                        Idrec: idrec,
                        Name: value,
                    };
                    loadedLesson.category = item;
                }
                if (lesson.hasOwnProperty("subCategory") && lesson.subCategory) {
                    const id = lesson.subCategory.id;
                    const idwell = lesson.subCategory.idwell;
                    const idrec = lesson.subCategory.idrec;
                    const value = lesson.subCategory.name;
                    const item: WvComboBoxItem = {
                        Id: id,
                        IdLib: id,
                        Idwell: idwell,
                        Idrec: idrec,
                        Name: value,
                    };
                    loadedLesson.subCategory = item;
                }

                if (lesson.hasOwnProperty("isOBO") && lesson.isOBO) {
                    loadedLesson.isOBO = lesson.isOBO;
                    loadedLesson.wjiContact = null;
                    if (lesson.hasOwnProperty("wjiContact") && lesson.wjiContact) {
                        const id = lesson.wjiContact.id;
                        const name = lesson.wjiContact.displayName;
                        const mail = lesson.wjiContact.mail;
                        const item: GraphComboBoxItem = {
                            id: id,
                            displayName: name,
                            mail: mail,
                            userPrincipalName: "",
                        };
                        loadedLesson.wjiContact = item;
                    }

                    if (lesson.hasOwnProperty("asset") && lesson.asset) {
                        const id = lesson.asset.id;
                        const value = lesson.asset.name;
                        const item: WvComboBoxItem = {
                            Id: id,
                            IdLib: id,
                            Name: value,
                        };
                        loadedLesson.asset = item;
                    }

                }

                if (lesson.hasOwnProperty("company") && lesson.company) {
                    //console.log("loadLesson json company = " + JSON.stringify(lesson.company));
                    const id = lesson.company.id;
                    const idwell = lesson.company.idwell;
                    const idrec = lesson.company.idrec;
                    const value = lesson.company.name;
                    const item: WvComboBoxItem = {
                        Id: id,
                        IdLib: id,
                        Idwell: idwell,
                        Idrec: idrec,
                        Name: value,
                    };
                    loadedLesson.company = item;
                }
                loadedLesson.actionRequired = "no";
                if (lesson.hasOwnProperty("actionRequired") && lesson.actionRequired) {
                    loadedLesson.actionRequired = lesson.actionRequired;
                }
                loadedLesson.resource = null;
                if (lesson.hasOwnProperty("resource") && lesson.resource) {
                    const id = lesson.resource.id;
                    const name = lesson.resource.displayName;
                    const mail = lesson.resource.mail;
                    const item: GraphComboBoxItem = {
                        id: id,
                        displayName: name,
                        mail: mail,
                        userPrincipalName: "",
                    };
                    loadedLesson.resource = item;
                }
                loadedLesson.ownersDisplay = "";
                if (lesson.hasOwnProperty("owners") && lesson.owners) {
                    const items: GraphComboBoxItem[] = [];
                    lesson.owners.forEach((element: any, index: number) => {
                        const id = element.id;
                        const name = element.displayName;
                        const mail = element.mail;
                        const item: GraphComboBoxItem = {
                            id: id,
                            displayName: name,
                            mail: mail,
                            userPrincipalName: "",
                        };
                        items.push(item);
                        if (index === 0) {
                            loadedLesson.ownersDisplay = name;
                        } else {
                            loadedLesson.ownersDisplay += ", " + name;
                        }
                    });
                    loadedLesson.owners = items;
                }

                this.lessonRegistry.set(loadedLesson.id, loadedLesson);
                let result = new LessonFormValues(loadedLesson);
                return result;
            } catch (error: any) {
                console.log(error);
                return error;
            }
        }
    };

    private getLesson = (id: string) => {
        return this.lessonRegistry.get(id);
    };

    createLesson = async (lesson: LessonFormValues) => {
        try {
            await apiAgent.Lessons.create(lesson);
            const newLesson = new Lesson(lesson);
            this.lessonRegistry.set(newLesson.id, newLesson);
            await apiAgent.Lessons.uploadAttachment(lesson);
            return true;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    editLesson = async (lesson: LessonFormValues) => {
        try {
            const response = await apiAgent.Lessons.edit(lesson);
            if (lesson.id) {
                let updatedLesson = { ...this.getLesson(lesson.id), ...lesson };
                this.lessonRegistry.set(lesson.id, updatedLesson as Lesson);
                return true;
            }
            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    saveUpdate = async (lesson: LessonFormValues) => {
        try {
            const response = await apiAgent.Lessons.saveUpdate(lesson);
            if (lesson.id) {
                let updatedLesson = { ...this.getLesson(lesson.id), ...lesson };
                this.lessonRegistry.set(lesson.id, updatedLesson as Lesson);
                return true;
            }
            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    submitForReview = async (lesson: LessonFormValues) => {
        try {
            const response = await apiAgent.Lessons.submitForReview(lesson);
            if (lesson.id) {
                let updatedLesson = { ...this.getLesson(lesson.id), ...lesson };
                this.lessonRegistry.set(lesson.id, updatedLesson as Lesson);
                return true;
            }
            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    reassignResource = async (lesson: LessonFormValues) => {
        try {
            const response = await apiAgent.Lessons.reassignResource(lesson);
            if (lesson.id) {
                let updatedLesson = { ...this.getLesson(lesson.id), ...lesson };
                this.lessonRegistry.set(lesson.id, updatedLesson as Lesson);
                return true;
            }
            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    closeLesson = async (lesson: LessonFormValues) => {
        try {
            const response = await apiAgent.Lessons.close(lesson);
            if (lesson.id) {
                let updatedLesson = { ...this.getLesson(lesson.id), ...lesson };
                this.lessonRegistry.set(lesson.id, updatedLesson as Lesson);
                return true;
            }
            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    rejectLesson = async (lesson: LessonFormValues) => {
        try {
            const response = await apiAgent.Lessons.reject(lesson);
            if (lesson.id) {
                let updatedLesson = { ...this.getLesson(lesson.id), ...lesson };
                this.lessonRegistry.set(lesson.id, updatedLesson as Lesson);
                return true;
            }
            return response;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    deleteLesson = async (id: string, isUncon: boolean) => {
        try {
            await apiAgent.Lessons.delete(id, isUncon);
            this.lessonRegistry.delete(id);
            return true;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    };

    getCount = async (url: string) => {
        try {
            const json = await odataAgent.Lessons.list(url);
            if (!json) {
                console.log(`getCount(${url}) odataAgent.Lessons.list returned nothing`);
                return 0;
            }
            if (json.message && json.message.error) {
                console.log(`getCount(${url}) odataAgent.Lessons.list returned error`);
                console.log(json.message.error);
                return 0;
            }
            if (!json.value) {
                console.log(`getCount(${url}) odataAgent.Lessons.list returned unknown`);
                console.log(json);
                return 0;
            }
            const count = json["@odata.count"];
            if (count) {
                return count;
            } else {
                return 0;
            }
        } catch (error: any) {
            console.log(`getCount(${url}) odataAgent.Lessons.list returned exception`);
            console.log(error);
            return 0;
        }
    };
}

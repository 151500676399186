import { HorizontalHeader } from "@exxonmobil/react-unity";
import { observer } from "mobx-react-lite";

export default observer(function HelpDropdown(){
    return (
        <HorizontalHeader.Nav style={{listStyleType: 'none',
        display: "flex",
        flexDirection: "column",
        paddingRight: 25, 
        marginTop:-40}}>

        <HorizontalHeader.Nav.Dropdown text="Help" style={{
            fontWeight:'bold'
        }}>
         
        <HorizontalHeader.Nav.Dropdown.Item >
        <a href=' https://wiki.na.xom.com/index.php/Wells_Lessons_Learned_Tool' target="_blank" rel="noopener noreferrer">User Guide</a>
        </HorizontalHeader.Nav.Dropdown.Item>
        <HorizontalHeader.Nav.Dropdown.Item>
        <a href=' https://emprod.service-now.com/itsp?id=sc_cat_item&sys_id=ae9e63181bf1b0901cf976ae034bcbab' target="_blank" rel="noopener noreferrer">Contact Us</a>
        </HorizontalHeader.Nav.Dropdown.Item>    
        </HorizontalHeader.Nav.Dropdown>
        </HorizontalHeader.Nav>
    );
});


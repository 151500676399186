import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { runInAction } from "mobx";

import { AccountInfo } from "@azure/msal-browser";
import { CircularProgress, Container, Grid, Paper, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import { OilTowerIcon } from "@exxonmobil/react-unity";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";

import { msalInstance } from "../../..";
import { useStore } from "../../../app/stores/store";
import apiAgent from "../../../app/api/apiAgent";
import { DiagnosticsResponse } from "../../../app/models/DiagnosticsResponse";

const StyledLabel = styled("label")(() => ({
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingBottom: "10px",
    paddingLeft: "15px",
    paddingRight: "10px",
}));
const StyledSwitch = styled(Switch)(() => ({
    paddingBottom: "10px",
    paddingLeft: "10px",
}));

const StyledTextField = styled(TextField)(() => ({
    ".MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000",
        color: "#000",
    },
}));

export default observer(function AboutLessonsLearned() {
    const { diagnosticsStore, groupStore } = useStore();
    const { getDiagnostics } = diagnosticsStore;

    const [ping, setPing] = useState<DiagnosticsResponse>(new DiagnosticsResponse());
    const [account, setAccount] = useState<AccountInfo | null>();
    const [loading, setLoading] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);

    const getInfo = async () => {
        const response = await getDiagnostics();
        let ping = new DiagnosticsResponse(response);
        setPing(ping);
        const account = msalInstance.getActiveAccount();
        setAccount(account);
        setLoading(false);
    };

    useEffect(() => {
        if (groupStore.gettingUnconUserGroups) {
            return;
        }

        setLoading(true);

        setChecked(groupStore.isUnconUser);
        getInfo();

        return () => {
            apiAgent.Cancel.cancelRequest();
        };
        // eslint-disable-next-line
    }, [groupStore.gettingUnconUserGroups]);

    const handleChange = (event: SwitchChangeEvent) => {
        setChecked(event.target.value);
        runInAction(() => {
            groupStore.isUnconUser = event.target.value;
        });
    };

    if (loading) {
        return (
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <CircularProgress />
            </div>
        );
    } else {
        return (
            <Container maxWidth="sm">
                <Paper>
                    {/* sx={{ padding: "10px", margin: "20px", minwidth: "25%", maxwidth: "50%", width: "50%" }} */}
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className="row">
                                <h3 style={{paddingRight: "15px"}}>About Wellview Lessons Learned</h3> <OilTowerIcon />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField disabled fullWidth size="small" label="appVersion" value={ping?.appVersion} />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField disabled fullWidth size="small" label="timeUTC" value={ping?.timeUTC} />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                disabled
                                fullWidth
                                size="small"
                                label="backend environmentName"
                                value={ping?.environmentName}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <StyledTextField disabled fullWidth size="small" label="backend serverName" value={ping.serverName} />
                        </Grid> */}
                        {/* <Grid item xs={12}>
                            <StyledTextField
                                disabled
                                fullWidth
                                size="small"
                                label="backend authenticatedUserName"
                                value={ping.authenticatedUserName}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <StyledTextField
                                disabled
                                fullWidth
                                size="small"
                                label="backend authenticationType"
                                value={ping?.authenticationType}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                disabled
                                fullWidth
                                size="small"
                                label="UI environment"
                                value={account?.environment}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField disabled fullWidth size="small" label="UI userName" value={account?.name} />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField disabled fullWidth size="small" label="UI fullUserName" value={account?.username} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="row">
                                <StyledLabel>Uncon User</StyledLabel>
                                <StyledSwitch checked={checked} onChange={handleChange} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {groupStore.userUnconGroups &&
                                groupStore.userUnconGroups.length > 0 &&
                                groupStore.userUnconGroups.map((group, index) => {
                                    if (index === 0) {
                                        return (
                                            <StyledTextField
                                                disabled
                                                key={index}
                                                fullWidth
                                                size="small"
                                                label="User's Uncon Group"
                                                value={group.name}
                                            />
                                        );
                                    } else {
                                        return <StyledTextField disabled key={index} fullWidth size="small" value={group.name} />;
                                    }
                                })}
                        </Grid>
                        <Grid item xs={12}>
                            {groupStore.userB2BGroups &&
                                groupStore.userB2BGroups.length > 0 &&
                                groupStore.userB2BGroups.map((group, index) => {
                                    if (index === 0) {
                                        return (
                                            <StyledTextField
                                                disabled
                                                key={index}
                                                fullWidth
                                                size="small"
                                                label="User's B2B Group"
                                                value={group.name}
                                            />
                                        );
                                    } else {
                                        return <StyledTextField disabled key={index} fullWidth size="small" value={group.name} />;
                                    }
                                })}
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        );
    }
});

import { observer } from "mobx-react-lite";
//import React from 'react';
import { Container } from "@exxonmobil/react-unity";
import { useStore } from "../../app/stores/store";

//show server error
export default observer(function ServerError() {
    const { commonStore } = useStore();
    return (
        <Container>
            <h1>Server Error</h1>
            <h5>{commonStore.error?.message} </h5>
            {commonStore.error?.details && (
                <>
                    <h4>Stack trace</h4> {/* color="teal" */}
                    <code style={{ marginTop: "10px" }}>{commonStore.error.details}</code>
                </>
            )}
        </Container>
    );
});

import { makeAutoObservable } from "mobx";
import apiAgent from "../api/apiAgent";
import { SaveWellViewResponse } from "../models/SaveWellViewResponse";

//import { getLessonOrList } from "../api/fetch";

export default class WellviewPicksStore {
    loadingInitial = false;
    loadingMessage = "Saving...";
    saveWellViewResponse: SaveWellViewResponse | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    };

    cancelRequest = async () => {
        apiAgent.Cancel.cancelRequest();
    };

    saveLessonTyp = async () => {
        this.loadingMessage = "Save Lesson Types can take 2 minutes";
        this.loadingInitial = true;
        try {
            this.saveWellViewResponse = undefined;
            const result = await apiAgent.WellviewPicks.saveLessonTyp();
            this.saveWellViewResponse = result;
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };

    saveJobIntervalLessonTyp = async () => {
        this.loadingMessage = "Save Lesson Categories can take 3 minutes";
        this.loadingInitial = true;
        try {
            this.saveWellViewResponse = undefined;
            const result = await apiAgent.WellviewPicks.saveJobIntervalLessonTyp();
            this.saveWellViewResponse = result;
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    };
}

import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";

//import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ListItemText } from "@mui/material";

import { CirclePlusIcon, EnvelopeClosedIcon, GlobeIcon } from "@exxonmobil/react-unity";

import { msalInstance } from "../../..";
import KendoGrid from "../../../app/common/form/KendoGrid";
import { useStore } from "../../../app/stores/store";
import { breadcrumbNameMap } from "../../../app/layout/breadcrumbNameMap";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             width: "100%",
//         },
//         nested: {
//             marginLeft: "10px",
//             width: "calc(100% - 10px)",
//         },
//         badge: {
//             padding: theme.spacing(0),
//             fontFamily: "EMprintBold",
//             color: "#5b5b5b",
//         },
//     })
// );

const CreateListItem = styled(ListItem)(() => ({
    "&.MuiButtonBase-root": {
        color: "#0c69b0",
        background: "#fff",
    },
    "&.MuiListItem-button:hover": {
        color: "#0c69b0",
        backgroundColor: "#f6f5f5",
    },
    "&.MuiListItem-divider": {
        borderBottomWidth: "9px",
        borderBottomColor: "#e7e7e7",
    },
}));
const GlobalListItem = styled(ListItem)(() => ({
    "&.MuiButtonBase-root": {
        color: "black",
        background: "#fff",
    },
    "&.MuiListItem-button:hover": {
        backgroundColor: "#f6f5f5",
    },
}));
const MyLessonsListItem = styled(ListItem)(() => ({
    "&.MuiButtonBase-root": {
        color: "black",
        background: "#3477BE",
    },
    fontWeight: "bolder",
    "&.MuiListItem-button:hover": {
        backgroundColor: "#3477BE",
    },
}));

const getClosedLessonsFilter = (longUserId: string | undefined, shortUserId: string | undefined) => {
    //Closed createdby me OR Closed owner = me OR Closed resource = me
    const url =
        `CreatedByUserId Eq '${longUserId}' AND Status Eq 'Closed' OR ` +
        `Owners/any(d:d/UserId eq '${shortUserId}') AND Status Eq 'Closed' OR ` +
        `ResourceUserId Eq '${shortUserId}' AND Status Eq 'Closed' `;
    return url;
};

const getAssignedLessonsFilter = (shortUserId: string | undefined) => {
    //me owner assigned = SubmittedForReview OR me resource assigned = WorkInProgress
    const url =
        `Owners/any(d:d/UserId eq '${shortUserId}') AND Status Eq 'SubmittedForReview' OR ` +
        `ResourceUserId Eq '${shortUserId}' AND Status Eq 'WorkInProgress' `;
    return url;
};

const getOpenLessonsFilter = (longUserId: string | undefined, shortUserId: string | undefined) => {
    //me owner open = WorkInProgress OR me resource open = SubmittedForReview or
    //me createdby and not owner and WorkInProgress or me createdby and not resource and SubmittedForReview
    const url =
        `CreatedByUserId Eq '${longUserId}' AND Owners/any(d:d/UserId ne '${shortUserId}') AND ResourceUserId ne '${shortUserId}' AND Status Eq 'WorkInProgress' OR ` +
        `CreatedByUserId Eq '${longUserId}' AND Owners/any(d:d/UserId ne '${shortUserId}') AND ResourceUserId ne '${shortUserId}' AND Status Eq 'SubmittedForReview' OR ` +
        `Owners/any(d:d/UserId eq '${shortUserId}') AND Status Eq 'WorkInProgress' OR ` +
        `ResourceUserId Eq '${shortUserId}' AND Status Eq 'SubmittedForReview' `;
    return url;
};

export const getAllMyLessonsFilter = (longUserId: string | undefined, shortUserId: string | undefined) => {
    const url = `CreatedByUserId Eq '${longUserId}' OR Owners/any(d:d/UserId eq '${shortUserId}') OR ResourceUserId Eq '${shortUserId}' `;
    return url;
};

export default observer(function MyInbox() {
    //const classes = useStyles();

    const { lessonStore, groupStore } = useStore();
    const navigate = useNavigate();
    const [filter, setFilter] = useState<string>("");
    const [allCount, setallCount] = useState<number | undefined>(undefined);
    const [openCount, setopenCount] = useState<number | undefined>(undefined);
    const [assignedCount, setassignedCount] = useState<number | undefined>(undefined);
    const [closedCount, setclosedCount] = useState<number | undefined>(undefined);
    const [panes, setPanes] = useState<Array<any>>([{ size: "14%" }, {}]);

    const countsDoneRef = useRef(false);
    //const mountedRef = useRef(true);

    const onChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
    };

    const [selectedIndex, setSelectedIndex] = useState(3);

    function GetUserIDs() {
        const account = msalInstance.getActiveAccount();
        const longUserId = account?.homeAccountId;
        const shortUserId = account?.localAccountId;
        return { longUserId, shortUserId };
    }

    const GetCounts = async (reset: boolean) => {
        if (reset) {
            countsDoneRef.current = false;
        }
        if (countsDoneRef.current) {
            return;
        }

        const selecturl = `&$select=Status,CreatedByUserId,Owners,ResourceUserId&$count=true`;
        var filterurl = "";
        if (groupStore.isUnconUser) {
            filterurl = "UnconLesson?$filter=";
        } else {
            filterurl = "Lesson?$filter=";
        }

        const { longUserId, shortUserId } = GetUserIDs();

        const allurl = filterurl + getAllMyLessonsFilter(longUserId, shortUserId) + selecturl;
        const all = await lessonStore.getCount(allurl);
        setallCount(all);

        const openurl = filterurl + getOpenLessonsFilter(longUserId, shortUserId) + selecturl;
        const open = await lessonStore.getCount(openurl);
        setopenCount(open);

        const assignedurl = filterurl + getAssignedLessonsFilter(shortUserId) + selecturl;
        const assigned = await lessonStore.getCount(assignedurl);
        setassignedCount(assigned);

        const closedurl = filterurl + getClosedLessonsFilter(longUserId, shortUserId) + selecturl;
        const closed = await lessonStore.getCount(closedurl);
        setclosedCount(closed);

        countsDoneRef.current = true;
    };

    const GlobalListHandleClick = (index: number) => {
        setSelectedIndex(index);
        navigate("/AllLessons");
    };
    const CreateHandleClick = (index: number) => {
        setSelectedIndex(index);
        navigate("/createLesson");
    };

    const AllHandleClick = (index: number) => {
        const { longUserId, shortUserId } = GetUserIDs();

        lessonStore.setGlobalFilter("");
        lessonStore.setLessonFilterIndex(index);
        setSelectedIndex(index);
        const allurl = getAllMyLessonsFilter(longUserId, shortUserId);
        lessonStore.setLessonFilter(allurl);
        setFilter(allurl);
    };
    const OpenHandleClick = (index: number) => {
        const { longUserId, shortUserId } = GetUserIDs();

        lessonStore.setGlobalFilter("");
        lessonStore.setLessonFilterIndex(index);
        setSelectedIndex(index);
        const openurl = getOpenLessonsFilter(longUserId, shortUserId);
        lessonStore.setLessonFilter(openurl);
        setFilter(openurl);
    };
    const AssignedHandleClick = (index: number) => {
        const { shortUserId } = GetUserIDs();

        lessonStore.setGlobalFilter("");
        lessonStore.setLessonFilterIndex(index);
        setSelectedIndex(index);
        const assignedurl = getAssignedLessonsFilter(shortUserId);
        lessonStore.setLessonFilter(assignedurl);
        setFilter(assignedurl);
    };
    const ClosedHandleClick = (index: number) => {
        const { longUserId, shortUserId } = GetUserIDs();

        lessonStore.setGlobalFilter("");
        lessonStore.setLessonFilterIndex(index);
        setSelectedIndex(index);
        const closedurl = getClosedLessonsFilter(longUserId, shortUserId);
        lessonStore.setLessonFilter(closedurl);
        setFilter(closedurl);
    };

    const loadInbox = () => {
        //mountedRef.current = true;

        if (lessonStore.getLessonFilterIndex() === 3 && selectedIndex !== 3) {
            AllHandleClick(3);
        } else if (lessonStore.getLessonFilterIndex() === 4 && selectedIndex !== 4) {
            OpenHandleClick(4);
        } else if (lessonStore.getLessonFilterIndex() === 5 && selectedIndex !== 5) {
            AssignedHandleClick(5);
        } else if (lessonStore.getLessonFilterIndex() === 6 && selectedIndex !== 6) {
            ClosedHandleClick(6);
        }

        GetCounts(false);

        //mountedRef.current = false;
    };

    const filterText = (text: string, count: number | undefined) => (
        <span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>{text}</span> <span style={{ color: "#0c69b0", fontWeight: "bold" }}>{count}</span>
        </span>
    );

    return (
        <>
            <Splitter panes={panes} onChange={onChange}>
                <List component="nav" aria-label="main inbox">
                    <CreateListItem button divider={true} selected={selectedIndex === 0} onClick={() => CreateHandleClick(0)}>
                        <ListItemIcon>
                            <CirclePlusIcon style={{ color: "#0c69b0" }} />
                        </ListItemIcon>
                        <ListItemText primary={breadcrumbNameMap["/createLesson"]} />
                    </CreateListItem>
                    <GlobalListItem button selected={selectedIndex === 1} onClick={() => GlobalListHandleClick(1)}>
                        <ListItemIcon>
                            <GlobeIcon style={{ color: "#b7b7b7" }} />
                        </ListItemIcon>
                        <ListItemText primary={breadcrumbNameMap["/AllLessons"]} />
                    </GlobalListItem>
                    <MyLessonsListItem button selected={false}>
                        <ListItemIcon>
                            <EnvelopeClosedIcon style={{ color: "#b7b7b7" }} />
                        </ListItemIcon>
                        <ListItemText primary={"My Lessons"} />
                    </MyLessonsListItem>
                    <ListItem button selected={selectedIndex === 3} onClick={() => AllHandleClick(3)}>
                        <ListItemText primary={filterText("All", allCount)} />
                    </ListItem>
                    <ListItem button selected={selectedIndex === 4} onClick={() => OpenHandleClick(4)}>
                        <ListItemText primary={filterText(`Open`, openCount)} />
                    </ListItem>
                    <ListItem button selected={selectedIndex === 5} onClick={() => AssignedHandleClick(5)}>
                        <ListItemText primary={filterText(`Assigned to Me`, assignedCount)} />
                    </ListItem>
                    <ListItem button selected={selectedIndex === 6} onClick={() => ClosedHandleClick(6)}>
                        <ListItemText primary={filterText(`Closed`, closedCount)} />
                    </ListItem>
                </List>
                {/* split is here */}
                <KendoGrid
                    isAll={false}
                    isAllMyDetailedLessons={false}
                    refresh={GetCounts}
                    loadInbox={loadInbox}
                    lessonFilter={filter}
                />
            </Splitter>
        </>
    );
});

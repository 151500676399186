import { ErrorMessage, useField } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
    ComboBox,
    ComboBoxChangeEvent,
    ComboBoxFilterChangeEvent,
    ComboBoxPageChangeEvent,
    DropDownsPopupSettings,
} from "@progress/kendo-react-dropdowns";
import odataAgent from "../../api/odataAgent";
import { WvComboBoxItem } from "../../models/WvComboBoxItem";
import { toast } from "react-toastify";
import { toastDefaults } from "../../models/toastDefaults";
import { ServerError } from "../../models/serverError";
import { useStore } from "../../stores/store";

const PAGESIZE: number = 15;
const TIMEOUT: number = 400;

const emptyItem: WvComboBoxItem = { Name: "loading ...", Id: 0 };
const loadingData: WvComboBoxItem[] = [];
while (loadingData.length < PAGESIZE) {
    loadingData.push({ ...emptyItem });
}

interface Props {
    placeholder: string;
    name: string;
    label: string;
    textField: string;
    baseUrl: string;
    selectColumns?: string;
    distinct?: string;
    setValue?(value: WvComboBoxItem): void;
    dataCaching: React.RefObject<any>;
    useInputfilterColumnName?: boolean;
    inputfilterColumn?: string | null;
    inputfilter?: WvComboBoxItem | null;
    orderbyColumnName?: string | null;
    disabled: boolean;
    additionalAttribute?: string | null;
    appendQueryString?: string | null;
}

export default function WvKendoComboBox(props: Props) {
    // eslint-disable-next-line
    const [field, _meta, helpers] = useField(props.name);
    const { commonStore, groupStore } = useStore();

    const navigate = useNavigate();

    const pendingUrl = useRef("");
    const pendingRequest = useRef<any>();
    const requestStarted = useRef(false);
    const skipRef = useRef(0);
    const mountedRef = useRef(true);
    const filterRef = useRef("");

    const [data, setData] = useState<WvComboBoxItem[]>([]);
    const [total, setTotal] = useState(0);
    const [currentInput, setCurrentInput] = useState<WvComboBoxItem | null>();
    const [loading, setLoading] = useState(false);

    const requestData = useCallback(
        async (skip: number, filterLocal: string, inputLocal: WvComboBoxItem | null | undefined) => {
            if (props.disabled) {
                setLoading(false);
                return;
            }

            if (filterLocal !== filterRef.current || inputLocal !== currentInput) {
                props.dataCaching.current.length = 0;
                requestStarted.current = false;
                pendingRequest.current = undefined;
                setLoading(false);
            } else {
                if (skip === skipRef.current && props.dataCaching.current[skip * PAGESIZE]) {
                    let count: number = 0;
                    const items: WvComboBoxItem[] = [];
                    for (let i = 0; i < PAGESIZE; i++) {
                        let item = props.dataCaching.current[i + skip];
                        if (item) {
                            items.push(item);
                            count++;
                        }
                    }
                    setData(items);
                    setTotal(count);
                    return;
                }
            }

            setCurrentInput(inputLocal);

            if (requestStarted.current) {
                clearTimeout(pendingRequest.current);
                pendingRequest.current = setTimeout(() => {
                    requestData(skip, filterLocal, inputLocal);
                }, TIMEOUT);
                return;
            }

            //$filter=contains('${searchName}','${filter}')
            //&$orderby=columnName
            //&$skip=${skip}
            //&$top=${pageSize}
            //&$count=true
            var url: string;
            var baseUrl: string;
            if(groupStore.isUnconUser) {
                baseUrl = "Uncon" + props.baseUrl;
            } else {
                baseUrl = props.baseUrl;
            }
            var selColumns = props.selectColumns;

            if(props.additionalAttribute){
                selColumns = selColumns + ',' + props.additionalAttribute;
            }
            if (inputLocal && props.inputfilterColumn) {
                var pred: string;
                if (props.useInputfilterColumnName) {
                    pred = `Eq '${inputLocal.Name}'`;
                } else {
                    pred = `Eq '${inputLocal.Idwell}'`;
                }

                url =
                    baseUrl +
                    `?$filter=contains(${props.textField},'${filterLocal}') and ${props.inputfilterColumn} ` +
                    pred;
                url = url + `&$select=${selColumns}`;
            } else {
                url = baseUrl + `?$filter=contains(${props.textField},'${filterLocal}')`;
                url = url + `&$select=${selColumns}`;
            }

            if (props.orderbyColumnName) {
                url = url + `&$orderby=${props.orderbyColumnName}`;
            }

            url = url + `&$skip=${skip}&$top=${PAGESIZE}&$count=true`;

            if(props.appendQueryString) {
                url = url + `&${props.appendQueryString}`;
            }

            if (url === pendingUrl.current) {
                requestStarted.current = false;
                pendingRequest.current = undefined;
                setLoading(false);
                return;
            }
            pendingUrl.current = url;

            requestStarted.current = true;
            setLoading(true);

            odataAgent.Wellview.list(url)
                .then((json) => {
                    if (!mountedRef.current) return null;

                    if (!json) {
                        requestStarted.current = false;
                        setLoading(false);
                        return;
                    }

                    if (!json.value) {
                        toast.error(json, toastDefaults);
                        let serverError: ServerError = new ServerError();
                        serverError.message = json;
                        commonStore.setServerError(serverError);
                        navigate("/server-error");
                        return;
                    }
                    if (json.message && json.message.error) {
                        toast.error(json.message.error, toastDefaults);
                        let serverError: ServerError = new ServerError();
                        serverError.message = json.message.error;
                        serverError.details = json.message.stacktrace;
                        commonStore.setServerError(serverError);
                        navigate("/server-error");
                        return;
                    }

                    const count = json["@odata.count"];
                    if (count === 0 && skip === skipRef.current) {
                        setData([]); //no data
                        setTotal(count);
                    } else {
                        const items: WvComboBoxItem[] = [];
                        json.value.forEach((element: any, index: number) => {
                            const id = element["Id"];
                            const idwell = element["Idwell"];
                            const idrec = element["Idrec"];
                            const value = element[props.textField];
                            const addAtt = props.additionalAttribute?element [props.additionalAttribute] : "";
                            const item: WvComboBoxItem = {
                                Id: id,
                                Idwell: idwell,
                                Idrec: idrec,
                                Name: value,
                                AdditionalAttribute: addAtt
                            };
                            items.push(item);
                            props.dataCaching.current[index + skip] = item;
                        });

                        if (skip === skipRef.current) {
                            if (props.distinct) {
                                const distinctItems = items.filter((v, i, a) => a.findIndex((v2) => v2.Name === v.Name) === i);
                                setData(distinctItems);
                            } else {
                                setData(items);
                            }
                            setTotal(count);
                        }
                    }
                    filterRef.current = filterLocal;
                    requestStarted.current = false;
                    setLoading(false);
                })
                .catch((error: any) => {
                    setLoading(false);
                    console.log(error);
                });
        },
        // eslint-disable-next-line
        [props.disabled]
    );

    useEffect(() => {
        mountedRef.current = true;

        setData(loadingData); //show loading... message for each item
        requestData(0, filterRef.current, props.inputfilter);
        // return () => { //this breaks the well selection list during edit lesson
        //     odataAgent.Cancel.cancelRequest();
        // };
        return () => {
            mountedRef.current = false;
        };
        // eslint-disable-next-line
    }, [props.disabled]);

    const shouldRequestData = (skip: number) => {
        for (let i = 0; i < PAGESIZE; i++) {
            if (!props.dataCaching.current[skip + i]) {
                return true;
            }
        }
        return false;
    };

    const getCachedData = (skip: number) => {
        const data: Array<any> = [];
        for (let i = 0; i < PAGESIZE; i++) {
            data.push(props.dataCaching.current[i + skip] || { ...emptyItem });
        }
        return data;
    };

    // eslint-disable-next-line
    const debounce = useCallback(
        _.debounce((_searchVal: string) => {
            requestData(0, _searchVal, currentInput);
            setData(loadingData); //show loading... message for each item
            skipRef.current = 0;
        }, TIMEOUT),
        [props.disabled, currentInput]
    );
    const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
        debounce(event.filter.value);
    };

    const pageChange = (event: ComboBoxPageChangeEvent) => {
        const newSkip = event.page.skip;

        if (shouldRequestData(newSkip)) {
            requestData(newSkip, filterRef.current, currentInput);
        }

        const data = getCachedData(newSkip);

        setData(data);
        skipRef.current = newSkip;
    };

    const onChange = (event: ComboBoxChangeEvent) => {
        const value = event.value;
        helpers.setValue(value);
        if (props.setValue) {
            props.setValue(value);
        }
    };

    const popupSettings: DropDownsPopupSettings = {
        height: "425px",
    };
    return (
        <>
            <label style={{ fontWeight: 1000, paddingTop: "10px" }}>{props.label}</label>
            <ComboBox
                // clearButton={false}
                loading={loading}
                popupSettings={popupSettings}
                disabled={props.disabled}
                placeholder={props.placeholder}
                name={props.name}
                data={data}
                value={field.value}
                onChange={onChange}
                onBlur={() => helpers.setTouched(true)}
                dataItemKey={"Name"}
                textField={"Name"}
                filterable={true}
                onFilterChange={onFilterChange}
                virtual={{
                    pageSize: PAGESIZE,
                    skip: skipRef.current,
                    total: total,
                }}
                onPageChange={pageChange}
            />
            <ErrorMessage name={props.name}>
                {(msg) => <label style={{ color: "red", fontSize: "12px", padding: "4px" }}>{msg}</label>}
            </ErrorMessage>
        </>
    );
}

import "./app/layout/styles.css";

import ReactDOM from "react-dom";

import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, EventType, InteractionType } from "@azure/msal-browser";
import { ReactPlugin, AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import App from "./app/layout/App";
import { loginRequest, msalConfig } from "./authConfig";
import { indexTsxStore, StoreContext } from "./app/stores/store";

// makes sure this is configured correctly: babel-plugin-styled-components
if (
    !new (class {
        x: any;
    })().hasOwnProperty("x")
)
    throw new Error("Transpiler is not configured correctly");

// for appInsights
export const reactPlugin = new ReactPlugin();
const appInsightsConfig = {
    config: {
        connectionString: process.env.REACT_APP_INSIGHTS_ConnectionString,
        disableCookiesUsage: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { guid: "7C8BCE49-397E-41CD-B926-21A52430FF1C" },
        },
    },
};
export const appInsights = new ApplicationInsights(appInsightsConfig);
appInsights.loadAppInsights();

/**
 * for SSO
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload?.account as any)) {
        //const { commonStore } = useStore();
        //const { inProgress } = useMsal(); //for SSO

        // @ts-ignore
        const account = event.payload?.account;
        msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
        console.log(JSON.stringify(event));
    }
});

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    const account = accounts[0];
    msalInstance.setActiveAccount(account);
}

//It all begins here
ReactDOM.render(
    <StoreContext.Provider value={indexTsxStore}>
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
                    <AppInsightsContext.Provider value={reactPlugin}>
                        <App />
                    </AppInsightsContext.Provider>
                </AppInsightsErrorBoundary>
            </MsalAuthenticationTemplate>
        </MsalProvider>
    </StoreContext.Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//import reportWebVitals from './reportWebVitals';
//reportWebVitals();

import { styled } from "@mui/material/styles";
import { Formik, Form } from "formik";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { LessonFormValues } from "../../../app/models/lesson";
import MyTextInput from "../../../app/common/form/MyTextInput";

const StyledButton = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: "20px",
    marginTop: "20px",
    "&.MuiButtonBase-root": {
        backgroundColor: "#1976d2",
    },
    "&.MuiButtonBase-root:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
}));

interface Props {
    onClose: (lesson?: LessonFormValues) => void;
    lesson: LessonFormValues;
    open: boolean;
}

export default function CloseLessonModal(props: Props) {
    const { onClose, lesson, open, ...other } = props;

    const handleCancel = () => {
        onClose();
    };

    const handleSend = (values: LessonFormValues) => {
        onClose(values);
    };

    /*
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      sx={{ "& .MuiDialog-paper": { backgroundColor: "rgba(250, 250, 250, 0.7)" } }}
      maxWidth="sm"
      */
    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={open}
            {...other}>
            <Formik
                enableReinitialize
                initialValues={lesson}
                onSubmit={(values) => handleSend(values)}>
                {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <DialogTitle>Close Lesson</DialogTitle>
                        <DialogContent dividers>
                            <MyTextInput
                                placeholder={"Enter your comments"}
                                name="closure"
                                label="Optional Comments"
                                component={"textarea"}
                            />
                        </DialogContent>
                        <DialogActions>
                            <StyledButton autoFocus variant="contained" onClick={() => handleCancel()}>
                                Cancel
                            </StyledButton>
                            <StyledButton variant="contained" type="submit" >
                                Submit
                            </StyledButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

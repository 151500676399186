//this is all for SSO and accesing the Wellview Lessons API web app

import { LogLevel } from "@azure/msal-browser";
import { AdGroup } from "./app/models/AdGroup";
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID as string, // This is the ONLY mandatory field that you need to supply.
        authority: "https://login.microsoftonline.com/" + (process.env.REACT_APP_TENANT_ID as string), // Defaults to "https://login.microsoftonline.com/common"
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.debug(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                }
            },
        },
    },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 * TODO Change Scopes ENVS
 */
export const protectedResources = {
    apiLessons: {
        scopes: [process.env.REACT_APP_API_LESSONS_SCOPE as string],
    },
    apiGraph: {
        groupEndpoint: "https://graph.microsoft.com/v1.0/me/memberOf",
        loginScopes: ["User.Read", "GroupMember.Read.All"],
        tokenScopes: ["https://graph.microsoft.com/.default"],
    },
    SDKapiWellview: {
        scopes: [process.env.REACT_APP_SDK_APIWELLVIEW_SCOPE as string],
    },
    apiWellview: {
        scopes: [process.env.REACT_APP_API_WELLVIEW_SCOPE as string],
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 // extraScopesToConsent: [...protectedResources.SDKapiWellview.scopes, ...protectedResources.apiWellview.scopes],
export const loginRequest = {
    scopes: [...protectedResources.apiGraph.loginScopes, ...protectedResources.apiLessons.scopes],
};

// Add here scopes for access token to be used at the API endpoints.
// export const tokenRequest = {
//     scopes: [...protectedResources.apiLessons.scopes],
// };

export const securityGroups = {
    GroupAdmin: "d724a429-020e-4579-a0f9-8c19790241e5",
    GroupApprover: "fd5d291d-3aa2-46e2-9968-7bf25e296d46",
    GroupUser: "317dc06e-98ab-4a9a-aa7c-020215d280a1",
};

export const unconGroups = [
    new AdGroup("UPST.UIS.WELLS.CANADAWEST.EMP", "", "6d2c62a7-28f7-4f8a-a2fb-99b3fd87b70f"),
    new AdGroup("UPST.UIS.WELLS.CANADAWEST.NH.EMP", "", "62a38bd7-dbec-4341-8180-21cb8575f550"),
    new AdGroup("UPST.UIS.WELLS.CANADAWEST.NH.BP", "", "c160404c-0f54-4294-adfa-aa7d6907d855"),
    new AdGroup("UPST.UIS.WELLS.CANADAWEST.BP", "", "4e451bbd-4259-42d1-b9c4-4d2051a66d84"),
    new AdGroup("UPST.UIS.WELLS.PERMIAN.EMP", "", "00fff506-4107-4a46-8a3a-d55e3c0a8e44"),
    new AdGroup("UPST.UIS.WELLS.PERMIAN.BP", "", "9ae5a683-6a0e-40e3-87b6-7720434d40cd"),
    new AdGroup("UPST.UIS.WELLS.PERMIAN.NH.BP", "", "83e6823a-e589-438e-b1d0-0b126d8754f6"),
    new AdGroup("UPST.UIS.WELLS.PERMIAN.NH.EMP", "", "a27a9119-91b9-4d65-82cf-ccca86b6629c"),
    new AdGroup("UPST.UIS.WELLS.CENTRAL.BP", "", "20889fdf-a506-4e9a-b99b-6543335b8232"),
    new AdGroup("UPST.UIS.WELLS.CENTRAL.EMP", "", "26020d3f-af00-43bb-baea-c4e37946817e"),
    new AdGroup("UPST.UIS.WELLS.CENTRAL.HOU.EMP", "", "8842f7cb-d393-4d4b-893f-c7103f24c88c"),
    new AdGroup("UPST.UIS.WELLS.CENTRAL.NH.EMP", "", "4c79cdbe-d053-4154-80c7-2bcd85037de6"),
    //new UserGroup("UPST.UIS.WELLS.DIAIT.HOU.BP", "", "0ea9e379-7b78-414c-886a-f0b577f69279"),  //TODO Remove after testing
];

//WellsLessonsLearned_B2B_ExxonMobil_DEV   ExxonMobil   e685d1e6-6a88-49db-881c-e86eb947f327

import { makeAutoObservable } from "mobx";
import { ServerError } from "../models/serverError";

//store common items
export default class CommonStore {
    private validationErrors: any | null = null;
    error: ServerError | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setValidationErrors = (errors: any) => {
        this.validationErrors = errors;
    }

    setServerError = (error: ServerError) => {
        this.error = error;
    }


}
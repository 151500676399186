import { createContext, useContext } from "react";
import LessonStore from "./lessonStore";
import CommonStore from "./commonStore";
import WellviewPicksStore from "./wellviewPicksStore";
import DiagnosticsStore from "./DiagnosticsStore";
import GroupStore from "./groupStore";

//all stores

interface Store {
    lessonStore: LessonStore;
    commonStore: CommonStore;
    wellviewPicksStore: WellviewPicksStore;
    diagnosticsStore: DiagnosticsStore;
    groupStore: GroupStore;
}

//for index.tsx ONLY do NOT use anywhere else USE useStore 
export const indexTsxStore: Store = {
    lessonStore: new LessonStore(),
    commonStore: new CommonStore(),
    wellviewPicksStore: new WellviewPicksStore(),
    diagnosticsStore: new DiagnosticsStore(),
    groupStore: new GroupStore(),
}
export const StoreContext = createContext(indexTsxStore);

//for use when accessing a store
export function useStore() {
    return useContext(StoreContext);
}
export class AdGroup implements AdGroup {
    constructor(name: string, shortName: string, guid: string) {
        this.shortName = shortName;
        this.name = name;
        this.guid = guid;
    }
}

export interface AdGroup {
    shortName: string;
    name: string;
    guid: string;
}

import { ErrorMessage, useField } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
    ComboBox,
    ComboBoxChangeEvent,
    ComboBoxFilterChangeEvent,
    ComboBoxPageChangeEvent,
    DropDownsPopupSettings,
} from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { toastDefaults } from "../../models/toastDefaults";
import { ServerError } from "../../models/serverError";
import { useStore } from "../../stores/store";
import apiAgent from "../../api/apiAgent";
import { GraphComboBoxItem } from "../../models/GraphComboBoxItem";
import { MsGraphRequest } from "../../models/MsGraph";
//import { protectedResources } from "../../../authConfig";

const PAGESIZE: number = 15;
const TIMEOUT: number = 400;

const emptyItem: GraphComboBoxItem = { displayName: "loading ...", id: 0, mail: "", userPrincipalName: "" };
const loadingData: GraphComboBoxItem[] = [];
while (loadingData.length < PAGESIZE) {
    loadingData.push({ ...emptyItem });
}

interface Props {
    placeholder: string;
    name: string;
    label: string;
    dataCaching: React.RefObject<any>;
    enabled: string;
    preselection?: string;
}

export default function GraphKendoComboBox(props: Props) {
    // eslint-disable-next-line
    const [field, _meta, helpers] = useField(props.name);
    const { commonStore } = useStore();

    const navigate = useNavigate();

    const pendingUrl = useRef("");
    const nextUrl = useRef("");
    const pendingRequest = useRef<any>();
    const requestStarted = useRef(false);
    const skipRef = useRef(0);
    const countRef = useRef(0);
    const mountedRef = useRef(true);
    const filterRef = useRef("");
    const [data, setData] = useState<GraphComboBoxItem[]>([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const requestData = useCallback(
        async (skip: number, nextpage: string, filterLocal: string) => {
            if (props.enabled === "no") {
                setLoading(false);
                return;
            }

            if (filterLocal !== filterRef.current) {
                props.dataCaching.current.length = 0;
                requestStarted.current = false;
                pendingRequest.current = undefined;
                setLoading(false);
            } else {
                // get from dataCache
                if (skip === skipRef.current && props.dataCaching.current[skip * PAGESIZE]) {
                    let count: number = 0;
                    const items: GraphComboBoxItem[] = [];
                    for (let i = 0; i < PAGESIZE; i++) {
                        let item = props.dataCaching.current[i + skip];
                        if (item) {
                            items.push(item);
                            count++;
                        }
                    }
                    setData(items);
                    setTotal(count);
                    return;
                }
            }

            if (requestStarted.current) {
                clearTimeout(pendingRequest.current);
                pendingRequest.current = setTimeout(() => {
                    requestData(skip, nextpage, filterLocal);
                }, TIMEOUT);
                return;
            }

            //$filter=contains('${searchName}','${filter}')
            //&$orderby=columnName
            //&$skip=${skip}
            //&$top=${pageSize}
            //&$count=true
            const baseUrl = "https://graph.microsoft.com/v1.0/users";
            const selecturl = `&$select=Id,displayName,mail,userPrincipalName&$top=${PAGESIZE}&$count=true`;
            var url: string = "";
            if (nextUrl.current === undefined) {
                return;
            } else if (nextUrl.current !== "") {
                url = nextUrl.current;
            } else {
                url = baseUrl;
                if (filterLocal) {
                    url = url + `?$search="displayName:${filterLocal}"&$filter=mail ne null&$orderby=displayName`; //change ? to & when this is last line
                } else {
                    url = url + `?$filter=mail ne null&$orderby=displayName`; //change ? to & when this is last line
                }
                url = url + selecturl;
            }

            if (url === pendingUrl.current) {
                requestStarted.current = false;
                pendingRequest.current = undefined;
                setLoading(false);
                return;
            }
            pendingUrl.current = url;

            requestStarted.current = true;
            setLoading(true);

            var request = new MsGraphRequest(url);       
            apiAgent.Users.get(request)
                .then((json) => {
                    if (!mountedRef.current) {
                        return null;
                    }
                    if (!json) {
                        requestStarted.current = false;
                        pendingRequest.current = undefined;
                        setLoading(false);
                        return;
                    }

                    if (!json.value) {
                        toast.error(json, toastDefaults);
                        let serverError: ServerError = new ServerError();
                        serverError.message = json;
                        commonStore.setServerError(serverError);
                        navigate("/server-error");
                        return;
                    }
                    if (json.message && json.message.error) {
                        toast.error(json.message.error, toastDefaults);
                        let serverError: ServerError = new ServerError();
                        serverError.message = json.message.error;
                        serverError.details = json.message.stacktrace;
                        commonStore.setServerError(serverError);
                        navigate("/server-error");
                        return;
                    }

                    nextUrl.current = json["@odata.nextLink"];

                    const count = json["@odata.count"];
                    if (count) {
                        countRef.current = count;
                    }

                    if (countRef.current === 0) {
                        setData([]); //no data
                        setTotal(countRef.current);
                    } else {
                        const items: GraphComboBoxItem[] = [];
                        json.value.forEach((element: any) => {
                            const id = element["id"];
                            const name = element["displayName"];
                            const mail = element["mail"];
                            const upn = element["userPrincipalName"];
                            const item: GraphComboBoxItem = {
                                id: id,
                                displayName: name,
                                mail: mail,
                                userPrincipalName: upn,
                            };
                            items.push(item);
                        });

                        const start = props.dataCaching.current.length;
                        items.forEach((element: any, index: number) => {
                            props.dataCaching.current[start + index] = element;
                        });
                        const data = getCachedData(skip, items.length);
                        setData(data);
                        setTotal(countRef.current);

                        if (props.preselection && filterRef.current === "") {
                            if (items.length === 1) {
                                helpers.setValue(items[0]); //preselection
                            }
                        }
                    }
                    filterRef.current = filterLocal;
                    requestStarted.current = false;
                    pendingRequest.current = undefined;
                    setLoading(false);
                })
                .catch((error: any) => {
                    requestStarted.current = false;
                    pendingRequest.current = undefined;
                    setLoading(false);
                    console.log(error);
                });
        },
        // eslint-disable-next-line
        [props.enabled]
    );

    useEffect(() => {
        mountedRef.current = true;

        skipRef.current = 0;
        nextUrl.current = "";

        setData(loadingData); //show loading... message for each item
        if (props.preselection && field.value === null) {
            requestData(0, "", props.preselection);
        } else {
            requestData(0, "", filterRef.current);
        }
        // return () => { //this breaks the well selection list during edit lesson
        //     odataAgent.Cancel.cancelRequest();
        // };
        return () => {
            mountedRef.current = false;
        };
        // eslint-disable-next-line
    }, [props.enabled]);

    const shouldRequestData = (skip: number) => {
        for (let i = 0; i < PAGESIZE; i++) {
            if (!props.dataCaching.current[skip + i]) {
                return true;
            }
        }
        return false;
    };

    const getCachedData = (skip: number, size: number) => {
        const data: Array<any> = [];
        for (let i = 0; i < size; i++) {
            data.push(props.dataCaching.current[i + skip] || { ...emptyItem });
        }
        return data;
    };

    // eslint-disable-next-line
    const debounce = useCallback(
        _.debounce((_searchVal: string) => {
            skipRef.current = 0;
            nextUrl.current = "";
            requestData(0, "", _searchVal);
            setData(loadingData); //show loading... message for each item
        }, TIMEOUT),
        []
    );
    const onFilterChange = (event: ComboBoxFilterChangeEvent) => {
        debounce(event.filter.value);
    };

    const pageChange = (event: ComboBoxPageChangeEvent) => {
        const newSkip = event.page.skip;

        if (shouldRequestData(newSkip)) {
            requestData(newSkip, nextUrl.current, filterRef.current);
        }

        const data = getCachedData(newSkip, PAGESIZE);

        setData(data);
        skipRef.current = newSkip;
    };

    const onChange = (event: ComboBoxChangeEvent) => {
        const value = event.value;
        helpers.setValue(value);
    };

    const popupSettings: DropDownsPopupSettings = {
        height: "425px",
    };

    /*
        itemRender={itemRender}
        valueRender={itemRender}
    */
    return (
        <>
            <label style={{ fontWeight: 1000, paddingTop: "10px" }}>{props.label}</label>
            <ComboBox
                // clearButton={false}
                loading={loading}
                popupSettings={popupSettings}
                disabled={props.enabled === "no"}
                placeholder={props.placeholder}
                name={props.name}
                data={data}
                value={field.value}
                onChange={onChange}
                onBlur={() => helpers.setTouched(true)}
                dataItemKey={"id"}
                textField={"displayName"}
                filterable={true}
                onFilterChange={onFilterChange}
                virtual={{
                    pageSize: PAGESIZE,
                    skip: skipRef.current,
                    total: total,
                }}
                onPageChange={pageChange}
            />
            <ErrorMessage name={props.name}>
                {(msg) => <label style={{ color: "red", fontSize: "12px", padding: "4px" }}>{msg}</label>}
            </ErrorMessage>
        </>
    );
}

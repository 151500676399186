import { makeAutoObservable } from "mobx";
import apiAgent from "../api/apiAgent";
import { DiagnosticsResponse } from "../models/DiagnosticsResponse";

export default class DiagnosticsStore {

    constructor() {
        makeAutoObservable(this);
    }

    cancelRequest = async () => {
        apiAgent.Cancel.cancelRequest();
    };

    getDiagnostics = async () => {
        try {
            const result = await apiAgent.Diagnostics.diag();
            return result;
        } catch (error) {
            console.log(error);
            return new DiagnosticsResponse();
        }
    };
}

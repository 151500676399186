import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastDefaults } from "../../../app/models/toastDefaults";
import { AccountInfo } from "@azure/msal-browser";

import { CircularProgress, Grid, IconButton, Paper, Container } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { LoadingButton } from "@mui/lab";

import { v4 as uuid } from "uuid";
import { Formik, Form, useFormikContext, FormikProps, Field } from "formik";
import * as Yup from "yup";

import { useStore } from "../../../app/stores/store";
import WvKendoComboBox from "../../../app/common/form/WvKendoComboBox";
import { LessonFormValues } from "../../../app/models/lesson";
import { WvComboBoxItem } from "../../../app/models/WvComboBoxItem";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { msalInstance } from "../../..";
import apiAgent from "../../../app/api/apiAgent";
import GraphKendoComboBox from "../../../app/common/form/GraphKendoComboBox";
import GraphKendoMultiSelect from "../../../app/common/form/GraphKendoMultiSelect";
import measuresAgent from "../../../app/api/measuresAgent";
import PickListKendoComboBox from "../../../app/common/form/PickListKendoComboBox";

const placeholder = "Type a few characters to begin search...";

export default observer(function CreateLessonForm() {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [wellBasin, setWellBasin] = useState<string>(""); // selected well basin
    const { commonStore, lessonStore, groupStore } = useStore();
    const { createLesson, editLesson, loadLesson, setForceReloadLessons } = lessonStore;
    const formikRef = useRef<FormikProps<LessonFormValues>>(null);
    const [lesson, setLesson] = useState<LessonFormValues>(new LessonFormValues());
    const [loading, setLoading] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [comboboxDisabled, setComboboxDisabled] = useState<boolean>(true);
    const [account, setAccount] = useState<AccountInfo | null>();
    const [lessonAttachments,setLessonAttachments] = useState<FileList | null>(null);
    const [isOBO, setIsOBO] = useState<boolean>(false); 
    const [companyTypeDisabled, setCompanyTypeDisabled] = useState<boolean>(true);

    const disableDependentComboboxes = (value: boolean) => {
        if (comboboxDisabled !== value) {
            setComboboxDisabled(value);
        }
    };

    function SaveWell(item: WvComboBoxItem) {           
        disableDependentComboboxes(false);
        if(item.AdditionalAttribute){            
            setWellBasin(item.AdditionalAttribute);            
        }
    }

    function SaveStewardingTeam(item: WvComboBoxItem) {
      companyDataCache.current.length = 0;    
      setCompanyTypeDisabled(true);    
      if(item?.Name)
      {  
          setTimeout(()=>{
            setIsOBO(item.Name === "Operated by Others");      
            setCompanyTypeDisabled(false);     
          },500)
      }
      else {
        setCompanyTypeDisabled(true);      
        setIsOBO(false);
      }
  }


    const ResetValues = () => {
        const formik = useFormikContext<LessonFormValues>();
        if (!formik.dirty) {
            return null;
        }

        //if well cleared by x click
        if (formik.values.isLessonLinkedToWell === 'yes' && formik.values.well == null) {
            if (editing) {
                SaveObservationActionLessonForm(formikRef.current);
            } else {
                ResetLessonForm(formikRef.current);
            }
        }
        //if well changes then clear phase
        else if (formik.values.well !== null && formikRef.current && formik.values.well !== formikRef.current.values.well) {
            SaveWellLessonForm(formikRef.current, formik.values.well);
        }
        // else {
        //     disableDependentComboboxes(true);
        // }

        return null;
    };

    function SaveWellLessonForm(formikRef: FormikProps<LessonFormValues> | null, newWell: WvComboBoxItem) {
        if (!formikRef) {
            return;
        }

        const lesson = new LessonFormValues();
        lesson.well = newWell;
        lesson.observations = formikRef.values.observations;
        lesson.actions = formikRef.values.actions;
        phaseDataCache.current.length = 0;
        categoryDataCache.current.length = 0;
        areaDataCache.current.length = 0;
        lesson.area = null;
        setLesson(lesson);        
    }

    function ResetLessonForm(formikRef: FormikProps<LessonFormValues> | null) {
        if (
            formikRef &&
            formikRef.values.observations === "" &&
            formikRef.values.actions === "" &&
            formikRef.values.well === null &&
            formikRef.values.phase === null
        ) {
            return;
        }

        wellDataCache.current.length = 0;
        stewardingTeamDataCache.current.length = 0;
        phaseDataCache.current.length = 0;
        categoryDataCache.current.length = 0;
        areaDataCache.current.length = 0;    
        subCategoryDataCache.current.length = 0;    
        const lesson = new LessonFormValues();

        lesson.area = null;     

        setLesson(lesson);
        disableDependentComboboxes(true);
        formikRef?.resetForm();        
    }

    function SaveObservationActionLessonForm(formikRef: FormikProps<LessonFormValues> | null) {
        if (!formikRef) {
            return;
        }

        if (formikRef.values.well !== null && formikRef.values.phase !== null) {
            return;
        }

        const lesson = new LessonFormValues();
        lesson.observations = formikRef.values.observations;
        lesson.actions = formikRef.values.actions;
        setLesson(lesson);
        wellDataCache.current.length = 0;
        stewardingTeamDataCache.current.length =0;
        phaseDataCache.current.length = 0;
        categoryDataCache.current.length = 0;
        areaDataCache.current.length = 0;
        subCategoryDataCache.current.length =0;
        disableDependentComboboxes(false);
    }

    function SaveWellPhaseLessonForm(formikRef: FormikProps<LessonFormValues>) {
        const lesson = new LessonFormValues();
        lesson.well = formikRef.values.well;
        lesson.phase = formikRef.values.phase;
        lesson.category = formikRef.values.category;
        lesson.subCategory = formikRef.values.subCategory;
        lesson.company = formikRef.values.company;
        if(groupStore.isUnconUser){
            lesson.area = formikRef.values.area;
            lesson.basin = formikRef.values.basin;
        }

        setLesson(lesson);
        disableDependentComboboxes(false);
    }

    useEffect(() => {
        const account = msalInstance.getActiveAccount();
        setAccount(account);

        if (id) {
            setLoading(true);
            loadLesson(id, groupStore.isUnconUser, groupStore.isB2BUser)
                .then((response) => {
                    setLoading(false);
                    setEditing(true);
                    let lesson = new LessonFormValues(response);
                    setLesson(lesson);
                    disableDependentComboboxes(false);
                })
                .catch((errors) => {
                    setLoading(false);
                    commonStore.setValidationErrors(errors);
                    console.log(errors);
                    toast.error(errors, toastDefaults);
                });
        } else {
            const lesson = new LessonFormValues();
            console.log(lesson.isB2B);
            console.log(groupStore.isB2BUser);
            lesson.isB2B = groupStore.isB2BUser;
            setLesson(lesson);
        }
        return () => {
            apiAgent.Cancel.cancelRequest();
        };
        // eslint-disable-next-line
    }, [id]);

    function handleFormSubmit(lesson: LessonFormValues, goInbox: boolean) {        
        if (!goInbox) {
            formikRef.current?.setSubmitting(true);
        }       
        lesson.attachmentsFiles = lessonAttachments;
        if (!lesson.id) {
            let newLesson = {
                ...lesson,
                id: uuid(),
                createdByUserId: account?.homeAccountId,
                createdByUserName: account?.username,
                fullUserName: account?.name,
                isUncon: groupStore.isUnconUser,
                basin:wellBasin
            };
            createLesson(newLesson)
                .then((response) => {
                    if (response) {
                        let len = Object.keys(response).length;
                        if (len === 0) {
                            toast.success("Lesson successfully submitted", toastDefaults);

                            let body = [
                                {
                                    ...measuresAgent.MeasuresEvent,
                                    UserId: account?.username,
                                    StartTime: new Date().toISOString(),
                                    Feature: "Create Lesson",
                                    BaseUrl: "CreateLesson",
                                },
                            ];

                            measuresAgent.Send.post(body);
                        } else {
                            toast.error(`Lesson submission failed. Error = ${response}`, toastDefaults);
                        }
                    } else {
                        toast.error("Lesson submission failed", toastDefaults);
                    }
                    setEditing(false);
                    setSaving(false);
                    setForceReloadLessons(true);
                    formikRef.current?.setSubmitting(false);
                    if (goInbox) {
                        navigate(`/`);
                    } else if (formikRef.current) {
                        SaveWellPhaseLessonForm(formikRef.current);
                    }
                })
                .catch((errors) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
        } else {
            setSaving(true);
            editLesson(lesson)
                .then((response) => {
                    if (response) {
                        let len = Object.keys(response).length;
                        if (len === 0) {
                            toast.success("Lesson successfully updated", toastDefaults);
                        } else {
                            toast.error(`Lesson update failed. Error = ${response}`, toastDefaults);
                        }
                    } else {
                        toast.error("Lesson update failed", toastDefaults);
                    }
                    setEditing(false);
                    setSaving(false);
                    setForceReloadLessons(true);
                    formikRef.current?.setSubmitting(false);
                    if (goInbox) {
                        navigate(`/`);
                    } else if (formikRef.current) {
                        SaveWellPhaseLessonForm(formikRef.current);
                    }
                })
                .catch((errors: any) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
        }
    }
    function handleFileSelection(event:React.ChangeEvent<HTMLInputElement>){        
        if(event.target.files)
            setLessonAttachments(event.target.files);
    }
    const chooseAnotherWellmessage = "If no data, try choosing another well.";
    const wvItemSchema = Yup.object({
        Name: Yup.string().min(1),
    });
    const graphItemSchema = Yup.object({
        displayName: Yup.string().min(1),
    });
    var areaRequired = wvItemSchema.nullable(true);
    if(groupStore.isUnconUser){
        areaRequired = areaRequired.required("Area is required. ");
    }
    const validationSchema = Yup.object({
        isLessonLinkedToWell: Yup.string().required("Is this lesson linked to a Wel is required"),
        well: wvItemSchema.nullable(true).when("isLessonLinkedToWell", {
                is: "yes",
                then: wvItemSchema.nullable(true).required("Well is required. " + chooseAnotherWellmessage),
            }),
        phase: wvItemSchema.nullable(true).when("isLessonLinkedToWell", {
            is: "yes",
            then: wvItemSchema.nullable(true).required("Phase is required. " + chooseAnotherWellmessage),
        }),
        stewardingTeam: wvItemSchema.nullable(true).required("Stewarding Team is required. "),
        company: wvItemSchema.nullable(true).required("Company is required."),
        wjiContact: graphItemSchema.nullable(true).when("stewardingTeam.Name", {
          is: "Operated by Others",
          then: graphItemSchema.nullable(true).required("WJI Contact is required"),
        }),
        asset: wvItemSchema.nullable(true).when("stewardingTeam.Name", {
          is: "Operated by Others",
          then: wvItemSchema.nullable(true).required("Asset is required. "),
        }),
        //phase: wvItemSchema.nullable(true).required("Phase is required. " + chooseAnotherWellmessage),
        area: areaRequired,
        category: wvItemSchema.nullable(true).required("Category is required. "),
        observations: Yup.string().required("Observations are required"),
        actions: Yup.string().required("Actions are required"),
        actionRequired: Yup.string(),
        resource: graphItemSchema.nullable(true).when("actionRequired", {
            is: "yes",
            then: graphItemSchema.nullable(true).required("Assigned resource is required"),
        }),
        owners: Yup.array()
            .nullable(true)
            .min(1, "Owner is required")
            .when("actionRequired", { is: "yes", then: Yup.array().min(1, "Owner is required").nullable(true) }),
    });
    function getFilesListContent(lessonAttachments:FileList) {
        let content = [];
        for (let idx in lessonAttachments) {        
          const item = lessonAttachments[idx];          
          if(item.name != undefined && item.name !== 'item'){
            content.push(<li key={item.name}>{item.name}</li>);
          }
        }
        return content;
      };
    // const MyPaper = styled(Paper)({
    //     background: "#fbfbfa",
    //     padding: "20px",
    // });

    const handleisLessonLinkedToWellChange = (value: string,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
        ) =>
    {
        if (value === "no") {
          setFieldValue("well", null);
          setFieldValue("phase", null);
          disableDependentComboboxes(false);
        }
        else {
            setFieldValue("company", null);
            setFieldValue("category", null);
            disableDependentComboboxes(true);
        }

    }


    const wellDataCache = useRef<any>([]);
    const areaDataCache = useRef<any>([]);
    const companyDataCache = useRef<any>([]);
    const assetDataCache = useRef<any>([]);
    const phaseDataCache = useRef<any>([]);
    const categoryDataCache = useRef<any>([]);
    const ownerDataCache = useRef<any>([]);
    const wjiContactDataCache = useRef<any>([]);
    const resourceDataCache = useRef<any>([]);
    const stewardingTeamDataCache = useRef<any>([]);
    const subCategoryDataCache = useRef<any>([]);


    if (saving || loading) {
        return (
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <CircularProgress />
            </div>
        );
    } else {
        return (
          <Formik
            innerRef={formikRef}
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={lesson}
            onSubmit={(values) => handleFormSubmit(values, true)}>
            {({ handleSubmit, values, isValid, isSubmitting, dirty, setFieldValue }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <ResetValues />
                <Container maxWidth="lg">
                  <Paper sx={{ padding: "50px", margin: "40px" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container sx={{ paddingTop: "10px" }}>
                          <div className="row">
                            <label style={{ paddingRight: "15px" }}>
                            Is this lesson linked to a Well?
                            </label>
                            <div role="group" className="row">
                              <label style={{ paddingRight: "10px" }}>
                                <Field
                                  type="radio"
                                  name="isLessonLinkedToWell"
                                  value="yes"
                                   onClick = {() => handleisLessonLinkedToWellChange("yes", setFieldValue)}
                                />
                                Yes
                              </label>
                              <label>
                                <Field
                                  type="radio"
                                  name="isLessonLinkedToWell"
                                  value="no"
                                   onClick = {() => handleisLessonLinkedToWellChange("no", setFieldValue)}
                                />
                                No
                              </label>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                     { values.isLessonLinkedToWell === 'yes' && (<Grid item xs={12}>
                        <WvKendoComboBox
                          placeholder={placeholder}
                          name={"well"}
                          label={"Well"}
                          textField={"Wellname"}
                          baseUrl={"Wellheader"}
                          selectColumns={"Idwell,Wellname"}
                          dataCaching={wellDataCache}
                          disabled={false}
                          setValue={SaveWell}
                          additionalAttribute={ groupStore.isUnconUser  ?  "Basin": null}
                        />
                      </Grid>
                     )}
                      {
                        groupStore.isUnconUser  &&
                        <React.Fragment>
                          <Grid item xs={12}>
                            <label style={{ fontWeight: 1000, paddingTop: "10px" }}>Basin</label>
                            <span>{wellBasin}
                              {groupStore.isUnconUser}
                            </span>
                          </Grid>
                          <Grid item xs={12}>
                            <WvKendoComboBox
                              placeholder={placeholder}
                              name={"area"}
                              label={"Area"}
                              textField={"Name"}
                              baseUrl={"WellArea"}
                              selectColumns={"Id,Name"}
                              dataCaching={areaDataCache}
                              disabled={false}
                            />
                          </Grid>
                        </React.Fragment>
                      }
                      <Grid item xs={12}>
                        <PickListKendoComboBox
                          placeholder={placeholder}
                          name={"stewardingTeam"}
                          label={"Stewarding Team"}
                          textField={"title"}
                          baseUrl={"WellEstBlobStorage/StewardingTeams"}
                          selectColumns={"title"}
                          dataCaching={stewardingTeamDataCache}
                          setValue={SaveStewardingTeam}
                          disabled={false}
                          setFieldValue={setFieldValue}
                          resetFields={['company','wjiContact','asset']}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <WvKendoComboBox
                          placeholder={placeholder}
                          name={"company"}
                          label={"Company"}
                          textField={"Name"}
                          baseUrl={"CompanyType"}
                          selectColumns={"Id,Name"}
                          dataCaching={companyDataCache}
                          disabled={companyTypeDisabled}
                          appendQueryString={"isOBO=" + isOBO}
                          orderbyColumnName={"Name"}
                        />
                      </Grid>

                { values.isLessonLinkedToWell === 'yes' && ( <Grid item xs={12}>
                        <WvKendoComboBox
                          placeholder={placeholder}
                          name={"phase"}
                          label={"Phase"}
                          textField={"Code1234calc"}
                          baseUrl={"JobProgramPhaseExtended"}
                          selectColumns={"Idwell,Idrec,Dttmstartactual,Code1234calc"}
                          dataCaching={phaseDataCache}
                          disabled={comboboxDisabled}
                          inputfilter={values.well}
                          inputfilterColumn={"Idwell"}
                          orderbyColumnName={"Dttmstartactual"}
                          distinct={"Code1234calc"}
                        />
                      </Grid>
                )}
                      <Grid item xs={12}>
                        <WvKendoComboBox
                          placeholder={placeholder}
                          name={"category"}
                          label={"Category"}
                          textField={"CategoryType"}
                          baseUrl={"CategoryType"}
                          selectColumns={"Id,CategoryType"}
                          dataCaching={categoryDataCache}
                          disabled={false}
                          orderbyColumnName={"CategoryType"}
                        />
                      </Grid>

                      { !groupStore.isUnconUser  && (
                        <Grid item xs={12}>
                        <WvKendoComboBox
                          placeholder={placeholder}
                          name={"subCategory"}
                          label={"Sub-Category"}
                          textField={"SubCategoryType"}
                          baseUrl={"SubCategoryType"}
                          selectColumns={"Id,SubCategoryType"}
                          dataCaching={subCategoryDataCache}
                          disabled={false}
                          orderbyColumnName={"SubCategoryType"}
                        />
                      </Grid>
                     )}

                      {values.stewardingTeam?.Name === "Operated by Others" && (
                        <>
                          <Grid item xs={12}>
                             <GraphKendoComboBox
                              placeholder={placeholder}
                              name={"wjiContact"}
                              label={"WJI Contact"}
                              dataCaching={wjiContactDataCache}
                              enabled={values.stewardingTeam.Name}
                            />
                          </Grid>
                           <Grid item xs={12}>
                          <WvKendoComboBox
                            placeholder={placeholder}
                            name={"asset"}
                            label={"Asset"}
                            textField={"Name"}
                            baseUrl={"Asset"}
                            selectColumns={"Id,Name"}
                            dataCaching={assetDataCache}
                            disabled={false}
                          />
                         </Grid>
                          </>
                      )}
                      <Grid item xs={12}>
                        <MyTextInput
                          placeholder={"Enter your observations"}
                          name="observations"
                          label="Lessons Learned Observation"
                          component={"textarea"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MyTextInput
                          placeholder={"Enter your recommended actions"}
                          name="actions"
                          label="Recommendations/Actions Required to Resolve"
                          component={"textarea"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label htmlFor="lessonAttachment" >Attachment(s)</label>
                        <input type="file" id="lessonAttachment" name="lessonAttachment" onChange={handleFileSelection} accept=".doc, .docx, .pdf, .jpg, .jpeg, .png" multiple />
                        <ul>
                          {lessonAttachments!==null && (getFilesListContent(lessonAttachments))}
                        </ul>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container sx={{ paddingTop: "10px" }}>
                          <div className="row">
                            <label style={{ paddingRight: "15px" }}>Is an Action Required?</label>
                            <div role="group" className="row">
                              <label style={{ paddingRight: "10px" }}>
                                <Field type="radio" name="actionRequired" value="yes" />
                                Yes
                              </label>
                              <label>
                                <Field type="radio" name="actionRequired" value="no" />
                                No
                              </label>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      {values.actionRequired === "yes" && (
                        <>
                          <Grid item xs={12}>
                            <GraphKendoMultiSelect
                              placeholder={placeholder}
                              name={"owners"}
                              label={"Owner(s)"}
                              dataCaching={ownerDataCache}
                              enabled={values.actionRequired}
                              preselection={account?.name}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <GraphKendoComboBox
                              placeholder={placeholder}
                              name={"resource"}
                              label={"Assigned Resource"}
                              dataCaching={resourceDataCache}
                              enabled={values.actionRequired}
                            />
                          </Grid>
                        </>
                      )}
                      {(() => {
                        if (values.isLessonLinkedToWell === 'yes' && lesson.isB2B === false) {
                          return (
                            <Grid item xs={12}>
                              <Grid container sx={{ paddingTop: "10px" }}>
                                <div className="row">
                                  <label style={{ paddingRight: "15px" }}>Send Lesson to WellView</label>
                                  <div role="group" className="row">
                                    <label style={{ paddingRight: "10px" }}>
                                      <Field type="checkbox" name="sendLessonToWellView" />
                                    </label>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          )
                        }
                      })()}
                      <Grid item>
                        <Grid container sx={{ paddingLeft: "0px", paddingTop: "20px", marginLeft: "0px" }}>
                          <Grid item>
                            <IconButton
                              sx={{
                                paddingLeft: "0px",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                                marginTop: "0px",
                                float: "left",
                                color: "#1976d2",
                              }}
                              disabled={isSubmitting || saving || !dirty || !isValid || editing}
                              onClick={() => handleFormSubmit(values, false)}>
                              <AddCircleOutlineOutlinedIcon sx={{ fontSize: 35 }} />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <label style={{ paddingTop: "8px" }}>Submit & Create Another Lesson</label>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          sx={{ textTransform: "none", float: "right", marginTop: "20px" }}
                          disabled={isSubmitting || saving || !dirty || !isValid}
                          loading={isSubmitting}>
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </Container>
              </Form>
            )}
          </Formik>
        );
    }
});

// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"job"}
                                            label={"Job"}
                                            textField={"JobName"}
                                            baseUrl={"JobExtended"}
                                            selectColumns={"Idwell,Idrec,JobName"}
                                            dataCaching={jobDataCache}
                                            inputfilter={values.well}
                                            inputfilterColumn={"Idwell"}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"rig"}
                                            label={"Rig"}
                                            textField={"Rigno"}
                                            baseUrl={"JobRig"}
                                            selectColumns={"Idwell,Idrec,Rigno"}
                                            dataCaching={rigDataCache}
                                            inputfilter={values.well}
                                            inputfilterColumn={"Idwell"}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"approver"}
                                            label={"Approver/Owner"}
                                            textField={"Contactname"}
                                            baseUrl={"JobContact"}
                                            selectColumns={"Idwell,Idrec,Contactname"}
                                            dataCaching={approverDataCache}
                                            inputfilter={values.well}
                                            inputfilterColumn={"Idwell"}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"type"}
                                            label={"Type"}
                                            textField={"LessonType"}
                                            baseUrl={"LessonType"}
                                            selectColumns={"Id,LessonType"}
                                            dataCaching={typeDataCache}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"category"}
                                            label={"Category"}
                                            textField={"LessonCategory"}
                                            baseUrl={"LessonCategoryType"}
                                            distinct={"LessonCategory"}
                                            setValue={SaveCategory}
                                            dataCaching={categoryDataCache}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"subCategory"}
                                            label={"SubCategory"}
                                            textField={"LessonSubCategory"}
                                            baseUrl={"LessonCategoryType"}
                                            selectColumns={"Id,LessonSubCategory"}
                                            dataCaching={subCategoryDataCache}
                                            inputfilter={values.category}
                                            inputfilterColumn={"LessonCategory"}
                                            useInputfilterColumnName={true}
                                            disabled={subCategoryDisabled}
                                        /> */
}

import { observer } from "mobx-react-lite";
import { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastDefaults } from "../../../app/models/toastDefaults";

import { CircularProgress, Grid, Paper, Container, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Formik, Form, useFormikContext, FormikProps, Field } from "formik";
import * as Yup from "yup";

import { useStore } from "../../../app/stores/store";
import WvKendoComboBox from "../../../app/common/form/WvKendoComboBox";
import { LessonFormValues } from "../../../app/models/lesson";
import { WvComboBoxItem } from "../../../app/models/WvComboBoxItem";
import MyTextInput from "../../../app/common/form/MyTextInput";
import apiAgent from "../../../app/api/apiAgent";
import RejectLessonModal from "./RejectLessonModal";
import CloseLessonModal from "./CloseLessonModal";
import React from "react";
import { useMsal } from "@azure/msal-react";
import GraphKendoComboBox from "../../../app/common/form/GraphKendoComboBox";
import PickListKendoComboBox from "../../../app/common/form/PickListKendoComboBox";

const placeholder = "Type a few characters to begin search...";

const StyledButton = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: "20px",
    marginTop: "20px",
    "&.MuiButtonBase-root": {
        backgroundColor: "#1976d2",
    },
    "&.MuiButtonBase-root:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
}));

const StyledProgressDiv = styled("div")(() => ({
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}));

const StyledLabel = styled("label")(() => ({
    fontWeight: 1000,
    paddingTop: "10px",
    paddingBottom: "10px",
}));

export default observer(function CloseLessonForm() {
    const navigate = useNavigate();
    const { id, type } = useParams();
    // const [wellBasin, setWellBasin] = useState<string>(""); // selected well basin
    const { commonStore, lessonStore, groupStore } = useStore();
    const { closeLesson, rejectLesson, loadLesson } = lessonStore;
    const formikRef = useRef<FormikProps<LessonFormValues>>(null);
    const [lesson, setLesson] = useState<LessonFormValues>(new LessonFormValues());
    const [loading, setLoading] = useState<boolean>(false);
    const { instance} = useMsal();
    const [isValid, setIsValid] = useState("");
    const [editing, setEditing] = useState<boolean>(false);
    const [comboboxDisabled, setComboboxDisabled] = useState<boolean>(true);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const [isOBO, setIsOBO] = useState<boolean>(false); 
    const [companyTypeDisabled, setCompanyTypeDisabled] = useState<boolean>(true);

    const disableDependentComboboxes = (value: boolean) => {
        if (comboboxDisabled !== value) {
            setComboboxDisabled(value);
        }
    };

    function SaveWell(item: WvComboBoxItem) {
        disableDependentComboboxes(false);
        // if(item.AdditionalAttribute){            
        //     setWellBasin(item.AdditionalAttribute);            
        // }
    }

    function SaveStewardingTeam(item: WvComboBoxItem) {  
        companyDataCache.current.length = 0;    
        setCompanyTypeDisabled(true);         
        if(item?.Name)
        {            
            setTimeout(()=>{
                setIsOBO(item.Name === "Operated by Others");      
                setCompanyTypeDisabled(false);     
              },500)    
        }
        else {
          setCompanyTypeDisabled(true);      
          setIsOBO(false);
        }
    }

    const ResetValues = () => {
        const formik = useFormikContext<LessonFormValues>();
        if (!formik.dirty) {
            return null;
        }

        //if well cleared by x click
        if (formik.values.isLessonLinkedToWell === 'yes' && formik.values.well == null) {
            if (editing) {
                SaveObservationActionLessonForm(formikRef.current);
            } else {
                ResetLessonForm(formikRef.current);
            }
        }
        //if well changes then clear phase
        else if (formik.values.well !== null && formikRef.current && formik.values.well !== formikRef.current.values.well) {
            SaveWellLessonForm(formikRef.current, formik.values.well);
        }

        // else {
        //     disableDependentComboboxes(true);
        // }

        return null;
    };

    function SaveWellLessonForm(formikRef: FormikProps<LessonFormValues> | null, newWell: WvComboBoxItem) {
        if (!formikRef) {
            return;
        }

        const lesson = new LessonFormValues();
        lesson.well = newWell;
        lesson.observations = formikRef.values.observations;
        lesson.actions = formikRef.values.actions;
        phaseDataCache.current.length = 0;
        categoryDataCache.current.length = 0;
        subCategoryDataCache.current.length = 0;
        areaDataCache.current.length = 0;
        lesson.area = null;
        setLesson(lesson);
        //disableDependentComboboxes(false);
    }
    
    function ResetLessonForm(formikRef: FormikProps<LessonFormValues> | null) {
        if (
            formikRef &&
            formikRef.values.observations === "" &&
            formikRef.values.actions === "" &&
            formikRef.values.well === null &&
            formikRef.values.phase === null
        ) {
            return;
        }

        wellDataCache.current.length = 0;
        phaseDataCache.current.length = 0;
        categoryDataCache.current.length = 0;
        subCategoryDataCache.current.length = 0;
        areaDataCache.current.length = 0;
        stewardingTeamDataCache.current.length = 0;
        assetDataCache.current.length = 0;
        wjiContactDataCache.current.length = 0;

        const lesson = new LessonFormValues();
        lesson.area = null;
        setLesson(lesson);
        disableDependentComboboxes(true);
        formikRef?.resetForm();
    }

    function SaveObservationActionLessonForm(formikRef: FormikProps<LessonFormValues> | null) {
        if (!formikRef) {
            return;
        }

        if (formikRef.values.well !== null && formikRef.values.phase !== null) {
            return;
        }

        const lesson = new LessonFormValues();
        lesson.observations = formikRef.values.observations;
        lesson.actions = formikRef.values.actions;
        setLesson(lesson);
        wellDataCache.current.length = 0;
        phaseDataCache.current.length = 0;
        categoryDataCache.current.length = 0;
        subCategoryDataCache.current.length = 0;
        areaDataCache.current.length = 0;
        stewardingTeamDataCache.current.length = 0;
        assetDataCache.current.length = 0;
        wjiContactDataCache.current.length = 0;
        disableDependentComboboxes(false);
    }
    
    const loadLessonRecord = async () => {
        setLoading(true);
        if (id) {

            let IsUncon = false;            
            if (type === "Conv" || type === "Conv -OBO") {
                IsUncon = false;
            } else if (type === "Uncon" || type ==="Uncon -OBO") {
                IsUncon = true;
            } else {
                IsUncon = groupStore.isUnconUser;
            }
            
            try{
                const response = await loadLesson(id, IsUncon, groupStore.isB2BUser);

                setEditing(true);
                let lessonRecord = new LessonFormValues(await response);
                setLesson(lessonRecord);
                disableDependentComboboxes(false);
                checkValidity(lessonRecord);  
                setCompanyTypeDisabled(false);
                setIsOBO(lessonRecord.isOBO);
                setLoading(false);
            }
            catch(errors){
                commonStore.setValidationErrors(errors);
                console.log(errors);
                //toast.error(errors, toastDefaults);
            }
            finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        loadLessonRecord();
        return () => {
            apiAgent.Cancel.cancelRequest();
        };
    }, [id, loadLesson, commonStore, groupStore.isUnconUser, type, groupStore.isB2BUser]);
    
    function checkValidity(lesson: LessonFormValues){
        if(lesson.status === "SubmittedForReview" && lesson.owners !== null && lesson.owners[0].mail === instance.getActiveAccount()?.username){
            setIsValid("true")
        }
        else{
            setIsValid("false")
        }
    }
    function handleFormSubmit(operation: string) {
        if (operation === "submit") {
            setOpenCloseDialog(true);
        } else if (operation === "reject") {
            setOpenRejectDialog(true);
        }
    }


    const submitCloseLesson = (lesson?: LessonFormValues) => {
        setOpenCloseDialog(false);

        if (lesson) {
            closeLesson(lesson)
                .then((response) => {
                    if (response) {
                        let len = Object.keys(response).length;
                        if (len === 0) {
                            toast.success("Lesson successfully closed", toastDefaults);
                            navigate("/");
                        } else {
                            toast.error(`Lesson close failed. Error = ${response}`, toastDefaults);
                        }
                    } else {
                        toast.error("Lesson close failed", toastDefaults);
                    }
                })
                .catch((errors: any) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
        }
    };

    const submitRejectLesson = (lesson?: LessonFormValues) => {
        setOpenRejectDialog(false);

        if (lesson) {
            rejectLesson(lesson)
                .then((response) => {
                    if (response) {
                        let len = Object.keys(response).length;
                        if (len === 0) {
                            toast.success("Lesson successfully rejected", toastDefaults);
                            navigate("/");
                        } else {
                            toast.error(`Lesson reject failed. Error = ${response}`, toastDefaults);
                        }
                    } else {
                        toast.error("Lesson reject failed", toastDefaults);
                    }
                })
                .catch((errors: any) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
        }
    };

    const chooseAnotherWellmessage = "If no data, try choosing another well.";
    const wvItemSchema = Yup.object({
        Name: Yup.string().min(1),
    });
    const graphItemSchema = Yup.object({
        displayName: Yup.string().min(1),
    });
    var areaRequired = wvItemSchema.nullable(true);
    if(groupStore.isUnconUser){
        areaRequired = areaRequired.required("Area is required. ");
    }
    const validationSchema = Yup.object({
        isLessonLinkedToWell: Yup.string().required("Is this lesson linked to a Wel is required"),
        well: wvItemSchema.nullable(true).when("isLessonLinkedToWell", {
            is: "yes",
            then: wvItemSchema.nullable(true).required("Well is required. " + chooseAnotherWellmessage),
        }),
        phase: wvItemSchema.nullable(true).when("isLessonLinkedToWell", {
           is: "yes",
           then: wvItemSchema.nullable(true).required("Phase is required. " + chooseAnotherWellmessage),
        }),
        stewardingTeam: wvItemSchema.nullable(true).required("Stewarding Team is required. "),
        company: wvItemSchema.nullable(true).required("Company is required."),
        wjiContact: graphItemSchema.nullable(true).when("stewardingTeam.Name", {
          is: "Operated by Others",
          then: graphItemSchema.nullable(true).required("WJI Contact is required"),
        }),
        asset: wvItemSchema.nullable(true).when("stewardingTeam.Name", {
          is: "Operated by Others",
          then: wvItemSchema.nullable(true).required("Asset is required. "),
        }),
        area: areaRequired,
        category: wvItemSchema.nullable(true).required("Category is required. "),
        observations: Yup.string().required("Observations are required"),
        actions: Yup.string().required("Actions are required"),
        actionRequired: Yup.string(),
    });

    const handleisLessonLinkedToWellChange = (value: string,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
        ) =>
    {
        if (value === "no") {
          setFieldValue("well", null);
          setFieldValue("phase", null);
          disableDependentComboboxes(false);
        }
        else {
            setFieldValue("company", null);
            setFieldValue("category", null);
            disableDependentComboboxes(true);
        }

    }

    const wellDataCache = useRef<any>([]);
    const companyDataCache = useRef<any>([]);
    const phaseDataCache = useRef<any>([]);
    const categoryDataCache = useRef<any>([]);
    const subCategoryDataCache = useRef<any>([]);
    const areaDataCache = useRef<any>([]);
    const wjiContactDataCache = useRef<any>([]);
    const assetDataCache = useRef<any>([]);
    const stewardingTeamDataCache = useRef<any>([]);

    if (loading) {
        return (
            <StyledProgressDiv>
                <CircularProgress />
            </StyledProgressDiv>
        );
    }
    else {
        if(isValid === "false"){
            return(
                <Navigate to={`/lesson/${id}/${type}`}></Navigate>
            )
        }
        else if(isValid === "true"){
            return (
                <>
                    <Formik
                        innerRef={formikRef}
                        validationSchema={validationSchema}
                        enableReinitialize
                        initialValues={lesson}
                        onSubmit={() => handleFormSubmit("submit")}>
                        {({ handleSubmit, values, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} autoComplete="off">
                                <RejectLessonModal open={openRejectDialog} lesson={values} onClose={submitRejectLesson} />
                                <CloseLessonModal open={openCloseDialog} lesson={values} onClose={submitCloseLesson} />
                                <ResetValues />
                                <Container maxWidth="lg">
                                    <Paper sx={{ padding: "50px", margin: "40px" }}>
                                        <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                                <Grid container sx={{ paddingTop: "10px" }}>
                                                <div className="row">
                                                    <label style={{ paddingRight: "15px" }}>
                                                    Is this lesson linked to a Well?
                                                    </label>
                                                    <div role="group" className="row">
                                                    <label style={{ paddingRight: "10px" }}>
                                                        <Field
                                                        type="radio"
                                                        name="isLessonLinkedToWell"
                                                        value="yes"
                                                        onClick = {() => handleisLessonLinkedToWellChange("yes", setFieldValue)}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label>
                                                        <Field
                                                        type="radio"
                                                        name="isLessonLinkedToWell"
                                                        value="no"
                                                        onClick = {() => handleisLessonLinkedToWellChange("no", setFieldValue)}
                                                        />
                                                        No
                                                    </label>
                                                    </div>
                                                </div>
                                                </Grid>
                                            </Grid>
                                            { values.isLessonLinkedToWell === 'yes' && ( <Grid item xs={12}>
                                                <WvKendoComboBox
                                                    placeholder={placeholder}
                                                    name={"well"}
                                                    label={"Well"}
                                                    textField={"Wellname"}
                                                    baseUrl={"Wellheader"}
                                                    selectColumns={"Idwell,Wellname"}
                                                    dataCaching={wellDataCache}
                                                    disabled={false}
                                                    setValue={SaveWell}
                                                    additionalAttribute={ groupStore.isUnconUser  ?  "Basin": null}
                                                />
                                            </Grid>
                                            )}
                                            {
                                                groupStore.isUnconUser  &&                                            
                                                    <React.Fragment>
                                                        <Grid item xs={12}>
                                                            <label style={{ fontWeight: 1000, paddingTop: "10px" }}>Basin</label>                                                
                                                            <span>{lesson.basin}                                             
                                                                
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <WvKendoComboBox
                                                                placeholder={placeholder}
                                                                name={"area"}
                                                                label={"Area"}
                                                                textField={"Name"}
                                                                baseUrl={"WellArea"}
                                                                selectColumns={"Id,Name"}
                                                                dataCaching={areaDataCache}
                                                                disabled={false}                                                    
                                                            />
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                            <Grid item xs={12}>
                                                <PickListKendoComboBox
                                                placeholder={placeholder}
                                                name={"stewardingTeam"}
                                                label={"Stewarding Team"}
                                                textField={"title"}
                                                baseUrl={"WellEstBlobStorage/StewardingTeams"}
                                                selectColumns={"title"}
                                                dataCaching={stewardingTeamDataCache}
                                                disabled={false}
                                                setValue={SaveStewardingTeam}
                                                setFieldValue={setFieldValue}
                                                resetFields={['company','wjiContact','asset']}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <WvKendoComboBox
                                                    placeholder={placeholder}
                                                    name={"company"}
                                                    label={"Company"}
                                                    textField={"Name"}
                                                    baseUrl={"CompanyType"}
                                                    selectColumns={"Id,Name"}
                                                    dataCaching={companyDataCache}
                                                    disabled={companyTypeDisabled}
                                                    appendQueryString={"isOBO=" + isOBO}
                                                    orderbyColumnName={"Name"}
                                                />
                                            </Grid> 
                                            
                                            { values.isLessonLinkedToWell === 'yes' && (   <Grid item xs={12}>
                                                <WvKendoComboBox
                                                    placeholder={placeholder}
                                                    name={"phase"}
                                                    label={"Phase"}
                                                    textField={"Code1234calc"}
                                                    baseUrl={"JobProgramPhaseExtended"}
                                                    selectColumns={"Idwell,Idrec,Dttmstartactual,Code1234calc"}
                                                    dataCaching={phaseDataCache}
                                                    disabled={comboboxDisabled}
                                                    inputfilter={values.well}
                                                    inputfilterColumn={"Idwell"}
                                                    orderbyColumnName={"Dttmstartactual"}
                                                />
                                            </Grid>
                                            )}
                                            <Grid item xs={12}>
                                                <WvKendoComboBox
                                                    placeholder={placeholder}
                                                    name={"category"}
                                                    label={"Category"}
                                                    textField={"CategoryType"}
                                                    baseUrl={"CategoryType"}
                                                    selectColumns={"Id,CategoryType"}
                                                    dataCaching={categoryDataCache}
                                                    disabled={false}
                                                    orderbyColumnName={"CategoryType"}
                                                />
                                            </Grid>
                                         { !groupStore.isUnconUser  && (
                                            <Grid item xs={12}>
                                                <WvKendoComboBox
                                                placeholder={placeholder}
                                                name={"subCategory"}
                                                label={"Sub-Category"}
                                                textField={"SubCategoryType"}
                                                baseUrl={"SubCategoryType"}
                                                selectColumns={"Id,SubCategoryType"}
                                                dataCaching={subCategoryDataCache}
                                                disabled={false}
                                                orderbyColumnName={"SubCategoryType"}
                                                />
                                            </Grid>
                                            )}
                                            { values.stewardingTeam?.Name === "Operated by Others" && (
                                                <>
                                                <Grid item xs={12}>
                                                    <GraphKendoComboBox
                                                    placeholder={placeholder}
                                                    name={"wjiContact"}
                                                    label={"WJI Contact"}
                                                    dataCaching={wjiContactDataCache}
                                                    enabled={values.stewardingTeam.Name}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                <WvKendoComboBox
                                                    placeholder={placeholder}
                                                    name={"asset"}
                                                    label={"Asset"}
                                                    textField={"Name"}
                                                    baseUrl={"Asset"}
                                                    selectColumns={"Id,Name"}
                                                    dataCaching={assetDataCache}
                                                    disabled={false}
                                                />
                                                </Grid>
                                                </>
                                            )}
                                            <Grid item xs={12}>
                                                <StyledLabel>Owner(s)</StyledLabel>
                                                <div>{lesson.ownersDisplay}</div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StyledLabel>Assigned Resource</StyledLabel>
                                                <div>{lesson.resource?.displayName}</div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MyTextInput
                                                    placeholder={"Enter your observations"}
                                                    name="observations"
                                                    label="Lessons Learned Observation"
                                                    component={"textarea"}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MyTextInput
                                                    placeholder={"Enter your recommended actions"}
                                                    name="actions"
                                                    label="Recommendations/Actions Required to Resolve"
                                                    component={"textarea"}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <StyledLabel>Attachment</StyledLabel>
                                                { lesson.attachments!== null && lesson.attachments!== undefined  && lesson.attachments.map((attachment,i) =>{
                                                    if(attachment.attachmentScanPassed === true && attachment.attachmentUri !== null) {
                                                            return (
                                                        <>
                                                        <a href={attachment.attachmentUri}  className="em-c-text-link ">{attachment.attachmentFileName}</a>
                                                        <br/>
                                                        </>
                                                        )
                                                    }
                                                    if(attachment.attachmentScanPassed === false && attachment.attachmentFileName !== null){
                                                        return (                                                    
                                                            <div className="em-c-alert em-c-alert--warning" role="alert">                                                   
                                                                <div className="em-c-alert__body">
                                                                    File ({attachment.attachmentFileName }) upload failed. Malicious file detected.                                                        
                                                                </div>                                                  
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </Grid>
                                            {lesson.rejection && (
                                                <Grid item xs={12}>
                                                    <StyledLabel>Rejection Comments</StyledLabel>
                                                    <div>{lesson.rejection}</div>
                                                </Grid>
                                            )}
                                            {lesson.updates && (
                                                <Grid item xs={12}>
                                                    <StyledLabel>Lesson Updates</StyledLabel>
                                                    <div>{lesson.updates}</div>
                                                </Grid>
                                            )}
                                        <Grid item xs={12}>
                                            <Grid container sx={{ paddingTop: "10px" }}>
                                                <div className="row">
                                                
                                                    <label style={{ paddingRight: "15px" }}>Send Lesson to WellView </label>
                                                    <div role="group" className="row">
                                                        <label style={{ paddingRight: "10px" }}>
                                                        {(() => {     
                                                            if(lesson.isB2B !== true && lesson.sendLessonToWellView === true && lesson.status?.toLowerCase() === "closed"){
                                                                return (
                                                                
                                                                        <Field type="checkbox" name="sendLessonToWellView" checked="checked" disabled />
                                                                    )                   
                                                            }
                                                            else if(lesson.isB2B !== true && lesson.sendLessonToWellView === true && lesson.status?.toLowerCase() !== "closed")    {
                                                                return (
                                                                        <Field type="checkbox" name="sendLessonToWellView" disabled={false} />
                                                                    ) 
                                                            }
                                                            else if(lesson.isB2B !== true && lesson.sendLessonToWellView !== true && lesson.status?.toLowerCase() !== "closed")    {
                                                                return (
                                                                        <Field type="checkbox" name="sendLessonToWellView" disabled={false} />
                                                                    ) 
                                                            }
                                                        })()}
                                                        </label>
                                                    </div>
                                                </div>                                    
                                            </Grid>
                                    </Grid>
                                            <Grid item xs={12} sx={{}}>
                                                <div className="rowRight">
                                                    <StyledButton variant="contained" type="submit">
                                                        Mark as Closed
                                                    </StyledButton>
                                                    <StyledButton variant="contained" onClick={() => handleFormSubmit("reject")}>
                                                        Reject
                                                    </StyledButton>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Container>
                            </Form>
                        )}
                    </Formik>
                </>
            );
        }
        else{
            return(
                <div>
                    App is still loading ...
                </div>
            );
        }
    }
});

// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"job"}
                                            label={"Job"}
                                            textField={"JobName"}
                                            baseUrl={"JobExtended"}
                                            selectColumns={"Idwell,Idrec,JobName"}
                                            dataCaching={jobDataCache}
                                            inputfilter={values.well}
                                            inputfilterColumn={"Idwell"}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"rig"}
                                            label={"Rig"}
                                            textField={"Rigno"}
                                            baseUrl={"JobRig"}
                                            selectColumns={"Idwell,Idrec,Rigno"}
                                            dataCaching={rigDataCache}
                                            inputfilter={values.well}
                                            inputfilterColumn={"Idwell"}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"approver"}
                                            label={"Approver/Owner"}
                                            textField={"Contactname"}
                                            baseUrl={"JobContact"}
                                            selectColumns={"Idwell,Idrec,Contactname"}
                                            dataCaching={approverDataCache}
                                            inputfilter={values.well}
                                            inputfilterColumn={"Idwell"}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"type"}
                                            label={"Type"}
                                            textField={"LessonType"}
                                            baseUrl={"LessonType"}
                                            selectColumns={"Id,LessonType"}
                                            dataCaching={typeDataCache}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"category"}
                                            label={"Category"}
                                            textField={"LessonCategory"}
                                            baseUrl={"LessonCategoryType"}
                                            distinct={"LessonCategory"}
                                            setValue={SaveCategory}
                                            dataCaching={categoryDataCache}
                                            disabled={disabled}
                                        /> */
}
// eslint-disable-next-line
{
    /* <KendoComboBox
                                            placeholder={placeholder}
                                            name={"subCategory"}
                                            label={"SubCategory"}
                                            textField={"LessonSubCategory"}
                                            baseUrl={"LessonCategoryType"}
                                            selectColumns={"Id,LessonSubCategory"}
                                            dataCaching={subCategoryDataCache}
                                            inputfilter={values.category}
                                            inputfilterColumn={"LessonCategory"}
                                            useInputfilterColumnName={true}
                                            disabled={subCategoryDisabled}
                                        /> */
}

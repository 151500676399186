import { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { ZIndexContext } from "@progress/kendo-react-common";

import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

import { LessonFormValues } from "../../../app/models/lesson";
import MyTextInput from "../../../app/common/form/MyTextInput";
import GraphKendoComboBox from "../../../app/common/form/GraphKendoComboBox";

const placeholder = "Type a few characters to begin search...";

const StyledButton = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: "20px",
    marginTop: "20px",
    "&.MuiButtonBase-root": {
        backgroundColor: "#1976d2",
    },
    "&.MuiButtonBase-root:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
}));

interface Props {
    onClose: (lesson?: LessonFormValues) => void;
    lesson: LessonFormValues;
    open: boolean;
}

export default function ReassignResourceModal(props: Props) {
    const { onClose, lesson, open, ...other } = props;
    const resourceDataCache = useRef<any>([]);

    const handleCancel = () => {
        onClose();
    };

    const handleSend = (values: LessonFormValues) => {
        onClose(values);
    };

    const graphItemSchema = Yup.object({
        displayName: Yup.string().min(1),
    });

    const validationSchema = Yup.object({
        updates: Yup.string().required("Comments are required"),
        resource: graphItemSchema.nullable(true).required("Assigned resource is required"),
    });

    /*
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      sx={{ "& .MuiDialog-paper": { backgroundColor: "rgba(250, 250, 250, 0.7)" } }}
      maxWidth="sm"
      */
    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={open}
            {...other}>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={lesson}
                onSubmit={(values) => handleSend(values)}>
                {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <DialogTitle>Reassign Resource</DialogTitle>
                        <DialogContent dividers>
                            <ZIndexContext.Provider value={10003}>
                                <GraphKendoComboBox
                                    placeholder={placeholder}
                                    name={"resource"}
                                    label={"Assigned Resource"}
                                    dataCaching={resourceDataCache}
                                    enabled={"yes"}
                                />
                            </ZIndexContext.Provider>
                            <MyTextInput
                                placeholder={"Enter your comments"}
                                name="updates"
                                label="Comments"
                                component={"textarea"}
                            />
                        </DialogContent>
                        <DialogActions>
                            <StyledButton autoFocus variant="contained" onClick={() => handleCancel()}>
                                Cancel
                            </StyledButton>
                            <StyledButton variant="contained" type="submit" disabled={isSubmitting || !dirty || !isValid}>
                                Send
                            </StyledButton>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

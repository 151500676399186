import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { CircularProgress, Grid, Paper, Container,Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useStore } from "../../../app/stores/store";
import { LessonFormValues } from "../../../app/models/lesson";
import apiAgent from "../../../app/api/apiAgent";
import { Formik, Field, Form } from "formik";
import { toastDefaults } from "../../../app/models/toastDefaults";
import { toast } from "react-toastify";

const StyledButton = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: "20px",
    marginTop: "20px",
    "&.MuiButtonBase-root": {
        backgroundColor: "#1976d2",
    },
    "&.MuiButtonBase-root:disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
}));

const StyledProgressDiv = styled("div")(() => ({
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
}));

const StyledLabel = styled("label")(() => ({
    fontWeight: 1000,
    paddingTop: "10px",
    paddingBottom: "10px",
}));

export default observer(function LessonFormReadOnly() {
    const { id, type } = useParams();
    const { lessonStore, commonStore, groupStore } = useStore();
    const { loadLesson,saveUpdate } = lessonStore;
    const [lesson, setLesson] = useState<LessonFormValues>(new LessonFormValues());
    const [loading, setLoading] = useState<boolean>(false);

    //todo handle Uncon lesson
    useEffect(() => {
        if (id) {
            setLoading(true);

            if (type === "Conv" || type === "Conv -OBO") {
                loadLesson(id, false,groupStore.isB2BUser)
                    .then((response) => {
                        setLoading(false);
                        let lesson = new LessonFormValues(response);
                        setLesson(lesson);
                    })
                    .catch((error) => {
                        commonStore.setValidationErrors(error);
                        setLoading(false);
                    });
            } else if (type === "Uncon" || type === "Uncon -OBO") {
                loadLesson(id, true,groupStore.isB2BUser)
                    .then((response) => {
                        setLoading(false);
                        let lesson = new LessonFormValues(response);
                        setLesson(lesson);
                    })
                    .catch((error) => {
                        commonStore.setValidationErrors(error);
                        setLoading(false);
                    });
            } else {
                loadLesson(id, groupStore.isUnconUser,groupStore.isB2BUser)
                    .then((response) => {
                        setLoading(false);
                        let lesson = new LessonFormValues(response);
                        setLesson(lesson);
                    })
                    .catch((error) => {
                        commonStore.setValidationErrors(error);
                        setLoading(false);
                    });
            }
        }
        return () => {
            apiAgent.Cancel.cancelRequest();
        };
    }, [id, type, groupStore.isUnconUser, loadLesson, commonStore,groupStore.isB2BUser]);

    function handleFormSubmit(lesson: LessonFormValues, operation: string) {
        save(lesson);
        
        const element = document.getElementById('sendLessonToWellView') as HTMLInputElement | null;
        const elementButton = document.getElementById('rowRight') as HTMLInputElement | null;
        element?.setAttribute('disabled', '');
        elementButton?.setAttribute("style","display:none");
    }

    const save = (lesson: LessonFormValues) => {
        saveUpdate(lesson)
            .then((response) => {
                if (response) {
                    let len = Object.keys(response).length;
                    if (len === 0) {
                        toast.success("Lesson successfully saved", toastDefaults);
                    } else {
                        toast.error(`Lesson save failed. Error = ${response}`, toastDefaults);
                    }
                } else {
                    toast.error("Lesson save failed", toastDefaults);
                }
            })
            .catch((errors: any) => commonStore.setValidationErrors(errors)); //TODO FIX doesn't cause refresh no error displayed
    };

    if (loading) {
        return (
            <StyledProgressDiv>
                <CircularProgress />
            </StyledProgressDiv>
        );
    } else {
        return (
            <Formik
            enableReinitialize
            initialValues={lesson}
            onSubmit={(values) => handleFormSubmit(values, "submit")}>
             {({ handleSubmit, values, isValid, isSubmitting, dirty }) => (
                    <Form onSubmit={handleSubmit} autoComplete="off">
            <Container maxWidth="lg">
                <Paper sx={{ padding: "50px", margin: "40px" }}>
                    <Grid container spacing={1}>
                       <Grid item xs={12}>
                            <StyledLabel>Is this lesson linked to a Well?</StyledLabel>
                            <div>{lesson.isLessonLinkedToWell}</div>
                        </Grid>

                        { lesson.isLessonLinkedToWell === "yes" && (
                            <Grid item xs={12}>
                                <StyledLabel>Well</StyledLabel>
                                <div>{lesson.well?.Name}</div>
                            </Grid>
                        )}
                        {groupStore.isUnconUser && (
                            <>
                                <Grid item xs={12}>
                                    <StyledLabel>Basin</StyledLabel>
                                    <div>{lesson.basin}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <StyledLabel>Area</StyledLabel>
                                    <div>{lesson.area?.Name}</div>
                                </Grid>                                
                            </>
                        )}
                         <Grid item xs={12}>
                            <StyledLabel>Stewarding Team</StyledLabel>
                            <div>{lesson.stewardingTeam?.Name}</div>
                        </Grid>
                         <Grid item xs={12}>
                            <StyledLabel>Company</StyledLabel>
                            <div>{lesson.company?.Name}</div>
                        </Grid> 
                        { lesson.isLessonLinkedToWell === "yes" && ( 
                        <Grid item xs={12}>
                            <StyledLabel>Phase</StyledLabel>
                            <div>{lesson.phase?.Name}</div>
                        </Grid>
                        )}
                        <Grid item xs={12}>
                            <StyledLabel>Category</StyledLabel>
                            <div>{lesson.category?.Name}</div>
                        </Grid>

                        
                        { !groupStore.isUnconUser && (
                            <Grid item xs={12}>
                                <StyledLabel>Sub-Category</StyledLabel>
                                <div>{lesson.subCategory?.Name}</div>
                            </Grid>
                        )}
                       
                        {
                            lesson.isOBO && (
                                <>
                                <Grid item xs={12}>
                                    <StyledLabel>WJI Contact</StyledLabel>
                                    <div>{lesson.wjiContact?.displayName}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <StyledLabel>Asset</StyledLabel>
                                    <div>{lesson.asset?.Name}</div>
                                </Grid>
                                </>
                            )
                        }
                        <Grid item xs={12}>
                            <StyledLabel>Observations</StyledLabel>
                            <div>{lesson.observations}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledLabel>Recommended Actions</StyledLabel>
                            <div>{lesson.actions}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledLabel>Attachment(s)</StyledLabel>     
                                { lesson.attachments!== null && lesson.attachments!== undefined && lesson.attachments.map((attachment,i) =>{
                                    if(attachment.attachmentScanPassed === true && attachment.attachmentUri !== null) {
                                            return (
                                        <>
                                        <a href={attachment.attachmentUri}  className="em-c-text-link ">{attachment.attachmentFileName}</a>
                                        <br/>
                                        </>
                                        )
                                    }
                                    if(attachment.attachmentScanPassed === false && attachment.attachmentFileName !== null){
                                        return (                                                    
                                            <div className="em-c-alert em-c-alert--warning" role="alert">                                                   
                                                <div className="em-c-alert__body">
                                                    File ({attachment.attachmentFileName }) upload failed. Malicious file detected.                                                        
                                                </div>                                                  
                                            </div>
                                        )
                                    }
                                })}
                        </Grid>
                        <Grid item xs={12}>
                            <StyledLabel>Is an Action Required?</StyledLabel>
                            <div>{lesson.actionRequired}</div>
                        </Grid>
                        {lesson.actionRequired === "yes" && (
                            <>
                                <Grid item xs={12}>
                                    <StyledLabel>Owner(s)</StyledLabel>
                                    <div>{lesson.ownersDisplay}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <StyledLabel>Assigned Resource</StyledLabel>
                                    <div>{lesson.resource?.displayName}</div>
                                </Grid>
                            </>
                        )}
                        {lesson.updates && (
                            <Grid item xs={12}>
                                <StyledLabel>Lesson Updates</StyledLabel>
                                <div>{lesson.updates}</div>
                            </Grid>
                        )}
                        {lesson.closure && (
                            <Grid item xs={12}>
                                <StyledLabel>Closure Comments</StyledLabel>
                                <div>{lesson.closure}</div>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container sx={{ paddingTop: "10px" }}>
                            <div className="row">
                            
                                <label style={{ paddingRight: "15px" }}>Send Lesson to WellView</label>
                                <div role="group" className="row">
                                    <label style={{ paddingRight: "10px" }}>
                                    {(() => {     
                                        if(lesson.isB2B !== true &&  lesson.sendLessonToWellView === true && lesson.status?.toLowerCase() === "closed"){                                   
                                            return (
                                                
                                                <Field type='checkbox' name='sendLessonToWellView' id="sendLessonToWellView" checked='checked' disabled /> 
                                                )
                                        }
                                        else if(lesson.isB2B !== true && lesson.sendLessonToWellView !== true && lesson.status?.toLowerCase() === "closed")    {                                  
                                            return (
                                                    <Field type="checkbox" name="sendLessonToWellView" id="sendLessonToWellView" disabled={false} />
                                                ) 
                                        }
                                    })()}
                                    </label>
                                </div>
                            </div>                                    
                        </Grid>
                    </Grid>
                    {(() => {     
                    if(lesson.isB2B !== true && lesson.sendLessonToWellView !== true && lesson.status?.toLowerCase() === "closed"){
                        return(
                        <Grid item xs={12} sx={{}}>
                        <div className="rowRight" id="rowRight">
                            <StyledButton
                                variant="contained"
                                onClick={() => handleFormSubmit(values, "submit")}>
                                Save
                            </StyledButton>
                            </div>
                            </Grid>  
                        )
                        }  
                         })()}    
                    </Paper>
                        </Container>
                        </Form>
                    )}
            </Formik>
        
        );
    
    }
});

// eslint-disable-next-line
/* <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Job" />
   </Grid> */
// eslint-disable-next-line
/* <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Rig" />
   </Grid> */
// eslint-disable-next-line
/* <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Type" />
   </Grid>
   <Grid container item xs={16}>
       <TextField disabled fullWidth  label="Category" />
   </Grid>
   <Grid container item xs={16}>
       <TextField disabled fullWidth  label="SubCategory" />
   </Grid> */
// eslint-disable-next-line
/* <TextField disabled fullWidth  label="Approver"  /> */
// eslint-disable-next-line
/* <TextField disabled fullWidth  label="Owner"  /> */
// eslint-disable-next-line
/* <Grid container item xs={16}>
    <TextField disabled fullWidth  label="Title" />
  </Grid> */

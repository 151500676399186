import axios, { AxiosError, AxiosResponse, Canceler } from "axios";
import { toast } from "react-toastify";
import { toastDefaults } from "../models/toastDefaults";
import { protectedResources } from "../../authConfig";
import { msalInstance } from "../../index";


//DEV only
// const sleep = (delay: number) => {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// };

const instance = axios.create({
    baseURL: process.env.REACT_APP_ODATA_URL,
});

//Add a Bearer token to every request
instance.interceptors.request.use(
    async (config) => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in.");
        }

        var silentRequest = {
            account: account,
            scopes: protectedResources.apiLessons.scopes,
        };

        if (config.headers) {
            const response = await msalInstance.acquireTokenSilent(silentRequest).catch((error) => {
                console.log(error);
                throw new axios.Cancel(`Operation canceled because of ${error}`);
            });
    
            if (response?.accessToken) {
                config.headers.Authorization = `Bearer ${response?.accessToken}`;
            }
        }
        else {
            throw Error("No config.headers");
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

//handle errors
instance.interceptors.response.use(
    async (response) => {
        return response;
    },
    (error: AxiosError) => {

        if (axios.isCancel(error)) {
            console.error(error);
            //toast.error(error.message, toastDefaults);
        } else if (!error.response) {
            console.error(error);
            toast.error(error.message, toastDefaults);
        } else {
            // eslint-disable-next-line
            const { data, status, config, headers } = error.response!;
            switch (status) {
                case 400:
                    console.log(error);
                    console.log(data);
                    toast.error(error.message, toastDefaults);
                    break;
                case 401:
                    console.log(error);
                    console.log(data);
                    if (headers["www-authenticate"]?.startsWith('Bearer error="invalid_token"')) {
                        toast.error('Bearer error="invalid_token"', toastDefaults);
                    } else {
                        toast.error(error.message, toastDefaults);
                    }
                    break;
                case 403:
                    console.log(error);
                    console.log(data);
                    if (headers["www-authenticate"]) {
                        toast.error('Bearer error="invalid_token"', toastDefaults);
                    } else {
                        toast.error(error.message, toastDefaults);
                    }
                    break;
                case 404:
                    console.log(error);
                    console.log(data);
                    toast.error(error.message, toastDefaults);
                    break;
                case 500:
                    console.log(Object.keys(headers));
                    var auth = headers["www-authenticate"];
                    if (auth) {
                        var url = auth.substr(auth.indexOf("=") + 1);
                        window.open(url);
                        toast.error('Bearer error="invalid_token"', toastDefaults);
                    } else {
                        toast.error(error.message, toastDefaults);
                    }
                    console.log(error);
                    console.log(data);
                    break;
                default:
                    console.log(error);
                    toast.error(error.message, toastDefaults);
            }
        }

        return Promise.reject(error);
    }
);
//Add a cancel to every request
let CancelRequest: Canceler;
const Cancel = { cancelRequest: () => CancelRequest() };

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

//generic requests
const requests = {
    get: <T>(url: string) =>
        instance
            .get<T>(url, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    post: <T>(url: string, body: {}) =>
        instance
            .post<T>(url, body, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    put: <T>(url: string, body: {}) =>
        instance
            .put<T>(url, body, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
    del: <T>(url: string) =>
        instance
            .delete<T>(url, {
                cancelToken: new axios.CancelToken(function executor(c) {
                    CancelRequest = c;
                }),
            })
            .then(responseBody),
};

const Lessons = {
    list: (url: string) => requests.get<any>(`${url}`),
};
const B2B = {
    groups: (url: string) => requests.get<any>(`${url}`),
};

const Wellview = {
    list: (url: string) => requests.get<any>(`${url}`),
};

//for export
const odataAgent = {
    Lessons,
    B2B,
    Wellview,
    Cancel,
};

export default odataAgent;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import { AccountInfo } from "@azure/msal-browser";

import MyInbox from "../../features/lessons/dashboards/MyInbox";
import AllLessons from "../../features/lessons/dashboards/AllLessons";
import AllMyDetailedLessons from "../../features/lessons/dashboards/AllMyDetailedLessons";
import CreateLessonForm from "../../features/lessons/form/CreateLessonForm";
import LessonFormReadOnly from "../../features/lessons/form/LessonFormReadOnly";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import { RouteGuard } from "./RouteGuard";
import Layout from "./layout";
import AboutLessonsLearned from "../../features/lessons/form/AboutLessonsLearned";
import ReviewLessonForm from "../../features/lessons/form/ReviewLessonForm";
import CloseLessonForm from "../../features/lessons/form/CloseLessonForm";
import graphAgent from "../api/graphAgent";
import { useStore } from "../stores/store";
import { msalInstance } from "../..";
import { unconGroups } from "../../authConfig";
import { AdGroup } from "../models/AdGroup";
import measuresAgent from "../api/measuresAgent";
import odataAgent from "../api/odataAgent";

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = () => {
    const { groupStore } = useStore();

    const handleNextPage = React.useCallback(
        async (nextPage: any) => {
            let response = await graphAgent.Groups.nextPage(nextPage);
            response.value.forEach((v: any) => {
                groupStore.addUserGroup(v.id);
            });

            if (response["@odata.nextLink"]) {
                await handleNextPage(response["@odata.nextLink"]);
            }
        },
        [groupStore]
    );

    const getUsersGroups = React.useCallback(async () => {
        groupStore.gettingUnconUserGroups = true;

        const currentAccount: AccountInfo | null = msalInstance.getActiveAccount();
        if (currentAccount) {
            let response = await graphAgent.Groups.firstPage();
            let length = response.value.length;
            for (let i = 0; i < length; i++) {
                let v = response.value[i];
                if (!v) continue;

                groupStore.addUserGroup(v.id);
            }
            if (response["@odata.nextLink"]) {
                await handleNextPage(response["@odata.nextLink"]);
            }

            // figure out if user is uncon
            let intersection = unconGroups.filter((group: AdGroup) => groupStore.findUserGroup(group.guid));
            if (intersection.length > 0) {
                groupStore.userUnconGroups = intersection;
                groupStore.isUnconUser = true;
            }

            var baseUrl: string;
            if (groupStore.isUnconUser) {
                baseUrl = "UnconB2BCompany";
            } else {
                baseUrl = "B2BCompany";
            }

            let json = await odataAgent.B2B.groups(baseUrl);

            if (json && json.message) {
                console.log(json.message);
            }

            if (json && json.value) {
                const data: Array<AdGroup> = [];
                json.value.forEach((item: any, index: number) => {
                    let element = item;
    
                    const id = element["ObjectId"];
                    const name = element["Name"];
                    const shortName = element["ShortName"];
                    const b2bGroup: AdGroup = {
                        guid: id,
                        name: name,
                        shortName: shortName,
                    };
                    data.push(b2bGroup);
                });
    
                groupStore.b2bGroups = data;
                intersection = groupStore.b2bGroups.filter((group: AdGroup) => groupStore.findUserGroup(group.guid));
                if (intersection.length > 0) {
                    groupStore.userB2BGroups = intersection;
                    groupStore.isB2BUser = true;
                }
                } 

            let body = [
                {
                    ...measuresAgent.MeasuresEvent,
                    UserId: currentAccount?.username,
                    StartTime: new Date().toISOString(),
                    Feature: "Login",
                    BaseUrl: "Login",
                },
            ];

            measuresAgent.Send.post(body);
        }

        groupStore.gettingUnconUserGroups = false;

    }, [groupStore, handleNextPage]);

    React.useEffect(() => {
        getUsersGroups();
    }, [getUsersGroups, groupStore.isUnconUser]);

    return (
        <>
            <ToastContainer position="bottom-right" newestOnTop={true} />
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RouteGuard>
                                    <MyInbox />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path="/AllLessons"
                            element={
                                <RouteGuard>
                                    <AllLessons />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path="/detailedlessons"
                            element={
                                <RouteGuard>
                                    <AllMyDetailedLessons />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path="/lesson/:id/:type"
                            element={
                                <RouteGuard>
                                    <LessonFormReadOnly />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path={"/createLesson"}
                            element={
                                <RouteGuard>
                                    <CreateLessonForm />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path={"/editLesson/:id"}
                            element={
                                <RouteGuard>
                                    <CreateLessonForm />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path={"/reviewLesson/:id/:type"}
                            element={
                                <RouteGuard>
                                    <ReviewLessonForm />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path={"/closeLesson/:id/:type"}
                            element={
                                <RouteGuard>
                                    <CloseLessonForm />
                                </RouteGuard>
                            }
                        />
                        <Route
                            path="/About"
                            element={
                                <RouteGuard>
                                    <AboutLessonsLearned />
                                </RouteGuard>
                            }
                        />
                        {/* <Route
                            path="/admin"
                            element={
                                <RouteGuard groups={[securityGroups.GroupAdmin]}>
                                    <GetSaveWellviewPicklists />
                                </RouteGuard>
                            }
                        /> */}
                        <Route path="/server-error" element={<ServerError />} />
                        <Route element={<NotFound />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </>
    );
};

export default observer(App);

export class DiagnosticsResponse implements DiagnosticsResponse {
    constructor(init?: DiagnosticsResponse) {
        Object.assign(this, init);
    }
}

export interface DiagnosticsResponse {
    environmentName: string;
    timeUTC?: Date;
    appVersion: string;
    serverName: string;
    authenticationType: string;
    authenticatedUserName: string;
}

/*
{"environmentName":"local","timeUTC":"3/22/2022 1:09:25 PM","appVersion":"1.0.0"}
*/